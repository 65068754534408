import React from "react";

const Input = ({ value, type = "text", placeholder, onChange }) => {
	return (
		<div className="form-group">
			<input
				className="form-control"
				type={type}
				value={value}
				placeholder={placeholder}
				onChange={onChange}
			/>
		</div>
	);
};

export default Input;
