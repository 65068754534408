import React from "react";
import axios from "axios";
import { useUser } from "../contexts/userContext";
import ProgressBar from "react-bootstrap/ProgressBar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Table from "react-bootstrap/Table";
import { SpinnerComponent } from "react-element-spinner";
import DateRangePicker from "@wojtekmaj/react-daterange-picker";
import "@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css";

const Progress = () => {
  const [progress, setProgress] = React.useState({
    percent: 0,
    touched: 0,
    total: 100,
  });
  const [userInformation, setUserInformation] = React.useState([]);
  const [dateRange, setDateRange] = React.useState([new Date(), new Date()]);
  const [selectUserId, setSelectUserId] = React.useState("");
  const [userStats, setUserStats] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const { userToken } = useUser();

  const from = dateRange[0];
  const to = dateRange[1];

  function formatDate(date) {
    let d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [year, month, day].join("-");
  }

  React.useEffect(() => {
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/items/summary`,
      headers: { "x-auth-token": userToken },
      data: {
        action: "COUNT_ITEM",
      },
    }).then((res) => {
      const { touched, total } = res.data;
      const percent = ((touched / total) * 100).toFixed(2);
      setProgress({
        touched,
        total,
        percent,
      });
    });

    axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/users/getAllBasicUserInformation`,
      headers: { "x-auth-token": userToken },
    }).then((res) => {
      setUserInformation(res.data);
    });
  }, [userToken]);

  React.useEffect(() => {
    if (selectUserId === "") return;
    setLoading(true);

    const dateFrom = new Date(formatDate(from));
    let dateTo = new Date(formatDate(to));
    dateTo = new Date(dateTo.setHours(23));
    dateTo = dateTo.setMinutes(59);

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/items/summary`,
      headers: { "x-auth-token": userToken },
      data: {
        action: "GET_ITEM_FROM_USER_AND_DATE",
        userId: selectUserId,
        from: dateFrom,
        to: dateTo,
      },
    }).then((res) => {
      setLoading(false);
      setUserStats(res.data);
    });
  }, [userToken, selectUserId, from, to]);

  return (
    <div>
      <h4>{`Item progress (${progress.touched}/${progress.total})`}</h4>
      <ProgressBar now={progress.percent} label={`${progress.percent}%`} />
      <hr />
      <Row>
        <Col md={2}>
          <ListGroup>
            {userInformation.map((information) => {
              return (
                <ListGroup.Item
                  key={information._id}
                  action
                  onClick={() => !loading && setSelectUserId(information._id)}
                  className={information._id === selectUserId ? "active" : ""}
                >
                  {information.name}
                </ListGroup.Item>
              );
            })}
          </ListGroup>
        </Col>
        <Col md={10}>
          <div className="position-relative">
            <DateRangePicker
              className="mb-1"
              onChange={setDateRange}
              value={dateRange}
              clearIcon={null}
              maxDate={new Date()}
            />
            <hr />
            <div className="mb-3">Number item: {userStats.length}</div>
            <Table className="list-user-items" striped bordered hover>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>Db Name</th>
                  <th>Name</th>
                </tr>
              </thead>
              <tbody>
                {userStats.map((userStat, index) => {
                  return (
                    <tr key={userStat.id}>
                      <td>{index + 1}</td>
                      <td>
                        <a
                          href={`/item/${userStat.id}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {userStat.id}
                        </a>
                      </td>
                      <td>{userStat.dbName}</td>
                      <td>{userStat.identifiedDisplayName}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
            <SpinnerComponent
              loading={loading}
              position="centered"
              message="Loading..."
            ></SpinnerComponent>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Progress;
