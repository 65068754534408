import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _ from "lodash";
import SkillDetail from "./SkillDetail";

import { useGeneral } from "../../contexts/generalContext";
import { useSkill, emptySkill } from "../../contexts/skillContext";

const SkillTabs = () => {
  const { views, tabActive, generalDispatch } = useGeneral();
  const { selectSkill } = useSkill();
  const { skills } = views;
  const activeItem = skills[tabActive.skills];

  function setKey(id) {
    const skill = _.find(skills, (skill) => skill.id === id);
    if (skill) selectSkill(skill);
  }

  function closeTab(skill) {
    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "delete",
        viewCat: "skills",
        value: [emptySkill],
      },
    });
  }

  return activeItem.id === -1 ? null : (
    <Tabs
      activeKey={activeItem.id}
      onSelect={(k) => setKey(Number(k))}
      className="mb-3"
    >
      {skills.map((skill) => {
        return (
          <Tab
            key={`skill-${skill.id}`}
            eventKey={skill.id}
            title={skill.dbName}
          >
            <SkillDetail skill={skill} closeTab={closeTab} />
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default SkillTabs;
