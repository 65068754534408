import React, { useReducer, useCallback } from "react";
import _ from "lodash";
import axios from "axios";
import { useDebounce } from "use-debounce";
import showToast from "../utils/showToast";
import { useUser } from "../contexts/userContext";
import { useGeneral } from "../contexts/generalContext";
import removeAccents from "../utils/removeAccents";

const emptySkill = {
  id: -1,
  dbName: "",
  skillName: "",
  description: [""],
  maxLv: 1,
  needSkillList: [],
  seperateLv: false,
  attackRange: [],
  spAmount: [],
  loading: false,
};

const initSkillPreview = {
  id: -1,
  dbName: "",
  description: ["Loading..."],
  maxLv: 1,

  server: "",
  loading: false,
  notFound: false,
};

const SkillContext = React.createContext();

const skillMainReducer = (state, action) => {
  let servers = [];
  let server = "";

  switch (action.type) {
    case "SET_SKILL_KEYWORD":
      return { ...state, skillKeyword: action.payload };
    case "SKILL_LIST_LOADING":
      return { ...state, skillLoading: true };
    case "SET_SKILL_RESULTS":
      return { ...state, skillResults: action.payload };
    case "FETCH_SKILLS_SUCCESS":
      return {
        ...state,
        skills: action.payload,
        skillResults: action.payload,
        skillLoading: false,
      };
    case "FETCH_SKILL_PREVIEW":
      servers = state.skillPreviewContainer;
      server = action.payload.server;

      return {
        ...state,
        skillPreviewContainer: {
          ...servers,
          [server]: {
            ...servers[server],
            ...action.payload,
            loading: true,
          },
        },
      };
    case "FETCH_SKILL_PREVIEW_SUCCESS":
      servers = state.skillPreviewContainer;
      server = action.payload.server;

      return {
        ...state,
        skillPreviewContainer: {
          ...servers,
          [server]: {
            ...servers[server],
            ...action.payload.data,
            loading: false,
            notFound: false,
          },
        },
      };
    case "FETCH_SKILL_PREVIEW_NOT_FOUND":
      servers = state.skillPreviewContainer;
      server = action.payload.server;
      return {
        ...state,
        skillPreviewContainer: {
          ...servers,
          [server]: {
            ...servers[server],
            ...action.payload.data,
            loading: false,
            notFound: true,
          },
        },
      };
    case "SET_HIDE_SKILL_LIST":
      return { ...state, hideSkillList: action.payload };
    default:
      return state;
  }
};

const SkillProvider = (props) => {
  const [skillMain, skillDispatch] = useReducer(skillMainReducer, {
    skills: [],
    skillResults: [],
    skillLoading: false,
    hideSkillList: false,
    skillKeyword: "",
    skillPreviewContainer: {
      iRO: initSkillPreview,
      kRO: initSkillPreview,
    },
  });

  const { userToken } = useUser();
  const { generalDispatch, views, tabActive } = useGeneral();
  const {
    skills,
    skillResults,
    skillLoading,
    skillKeyword,
    hideSkillList,
    skillPreviewContainer,
  } = skillMain;
  const [skillKeywordDebounce] = useDebounce(skillKeyword, 300);
  const activeItem = views.skills[tabActive.skills];

  function selectSkill(skill) {
    if (typeof skill === "undefined")
      return showToast("error", "Skill is undefined!");

    let skillFull = null;

    // set state to view
    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "active",
        viewCat: "skills",
        value: {
          ...emptySkill,
          ...skill,
          description: activeItem["description"], // For better visual
          loading: true,
        },
      },
    });

    // Fetch vRO data for this skill
    axios({
      url: `${process.env.REACT_APP_API}/skills/${skill.id}`,
      method: "GET",
      headers: { "x-auth-token": userToken },
    }).then((res) => {
      skillFull = {
        ...emptySkill,
        ...skill,
        ...res.data,
      };
      // for skill without description (NPC skill)
      if (res.data.description.length === 0) {
        skillFull = {
          ...skillFull,
          description: [""],
        };
      }

      // set state to view
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "active",
          viewCat: "skills",
          value: {
            ...emptySkill,
            ...skillFull,
            loading: false,
          },
        },
      });

      // skill preview
      skillDispatch({
        type: "FETCH_SKILL_PREVIEW_SUCCESS",
        payload: {
          server: "vRO",
          data: {
            ...skillFull,
            id: skill.id,
            notFound: false,
          },
        },
      });
    });
  }

  function searchSkills(keyword) {
    let filtered = [];
    let results = [];

    filtered = [...skills];

    if (keyword !== "") {
      const normalizeKeyword = removeAccents(keyword.toString().trim());

      // Search by Id
      results = filtered.filter((skill) => {
        return skill.id.toString().includes(normalizeKeyword);
      });

      // Search by name
      const skillsSearchByName = filtered.filter((skill) => {
        const normalizeThisSkillName = removeAccents(
          skill.skillName
        ).toLowerCase();
        return normalizeThisSkillName.includes(normalizeKeyword.toLowerCase());
      });

      // Search by iRO name
      const skillsSearchByiROName = filtered.filter((skill) => {
        if (typeof skill.skillNameIRO === "undefined") return "";
        const normalizeThisSkillName = removeAccents(
          skill.skillNameIRO
        ).toLowerCase();
        return normalizeThisSkillName.includes(normalizeKeyword.toLowerCase());
      });

      // Search by dbName
      const skillsSearchByDBName = filtered.filter((skill) => {
        const normalizeThisSkillName = removeAccents(
          skill.dbName
        ).toLowerCase();
        return normalizeThisSkillName.includes(normalizeKeyword.toLowerCase());
      });

      // Combine results
      _.forEach(skillsSearchByName, (skill) => {
        if (!_.some(results, ["id", skill.id])) {
          results = [...results, skill];
        }
      });
      _.forEach(skillsSearchByiROName, (skill) => {
        if (!_.some(results, ["id", skill.id])) {
          results = [...results, skill];
        }
      });
      _.forEach(skillsSearchByDBName, (skill) => {
        if (!_.some(results, ["id", skill.id])) {
          results = [...results, skill];
        }
      });
    } else {
      results = [...filtered];
    }
    return results;
  }

  const fetchSkillAndSet = useCallback(
    async (server, skillId) => {
      skillDispatch({
        type: "FETCH_SKILL_PREVIEW",
        payload: { server, id: skillId },
      });

      try {
        const res = await axios.get(
          `${process.env.REACT_APP_API}/skills/${skillId}?server=${server}`
        );
        const { id, dbName, skillName, description, maxLv } = res.data;

        skillDispatch({
          type: "FETCH_SKILL_PREVIEW_SUCCESS",
          payload: {
            server,
            data: {
              id,
              skillImage: `https://divine-pride.net/image/skill/${id}.png`,
              dbName: dbName ? dbName : "",
              skillName: skillName ? skillName : "",
              description: description ? description : [""],
              maxLv: maxLv ? maxLv : 1,
            },
          },
        });
      } catch (e) {
        if (e.response.status === 404) {
          skillDispatch({
            type: "FETCH_SKILL_PREVIEW_NOT_FOUND",
            payload: {
              server,
              data: {
                ...initSkillPreview,
                skillId,
                skillName: `Not found on ${server}`,
                dbName: `Not found on ${server}`,
                description: [`Not found on ${server}`],
              },
            },
          });
        }
      }
    },
    [skillDispatch]
  );

  const value = {
    skills,
    skillResults,
    skillLoading,
    hideSkillList,
    skillKeyword,
    skillKeywordDebounce,
    skillPreviewContainer,
    selectSkill,
    searchSkills,
    skillDispatch,
    fetchSkillAndSet,
  };

  return <SkillContext.Provider value={value} {...props} />;
};

function useSkill() {
  const context = React.useContext(SkillContext);
  if (!context) {
    throw new Error(`useContext must be used within skillProvider.`);
  }
  return context;
}

export { SkillProvider, useSkill, emptySkill, initSkillPreview };
