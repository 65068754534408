import React from "react";

function TextArea({ value, rows, readOnly = false, ...rest }) {
  return (
    <textarea
      readOnly={readOnly}
      value={value}
      rows={rows}
      className="form-control"
      {...rest}
    ></textarea>
  );
}

export default TextArea;
