import React, { useLayoutEffect, useEffect } from "react";
import { useDebounce } from "use-debounce";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";

import ItemTabs from "./ItemTabs";
import ItemHistories from "../common/ItemHistories";
import ItemNavigation from "../common/ItemNavigation";
import OffCanvasTable from "../common/OffCanvasTable";
import List from "../common/List";

import { useGeneral } from "../../contexts/generalContext";
import { useItem } from "../../contexts/itemContext";
import { useUser } from "../../contexts/userContext";

const Item = () => {
  const { generalDispatch, views, globalTags, getGlobalTags, tabActive } =
    useGeneral();
  const { userProfile } = useUser();
  const {
    items,
    itemResults,
    showOffItemContainCanvas,
    monstersDropsCanvas,
    hideItemList,
    itemListLoading,

    selectItem,
    handleNewItem,
    handleExportItemInfo,
    handleDeleteItem,

    itemKeyword,
    filters,
    replaceItemDataFrom,
    itemDispatch,
    fetchAllItems,
    searchItems,
  } = useItem();

  const [keywordDebounce] = useDebounce(itemKeyword, 300);
  const activeItem = views.items[tabActive.items];

  const listFilters = [
    {
      title: `Type`,
      label: "Type",
      options: typeOptions,
      onChange: (e) =>
        itemDispatch({
          type: "SET_ITEM_FILTERS",
          payload: { typeId: e.target.value },
        }),
      currentOptionValue: filters.typeId,
    },
    {
      title: `Tag`,
      label: "Tag",
      options: tagOptions,
      onChange: (e) =>
        itemDispatch({
          type: "SET_ITEM_FILTERS",
          payload: { tagId: e.target.value },
        }),
      currentOptionValue: filters.tagId,
    },
  ];

  const columns = [
    {
      label: "ID",
      dataKey: "itemId",
      width: 80,
    },
    {
      label: "Name",
      dataKey: "identifiedDisplayName",
      width: 220,
    },
  ];

  let buttons = [
    {
      label: "New",
      variant: "primary",
      onClick: () => handleNewItem(),
    },
    {
      label: "Iteminfo",
      variant: "info",
      onClick: () => handleExportItemInfo(),
    },
  ];

  if (userProfile.isAdmin) {
    buttons = [
      ...buttons,
      {
        label: "Delete",
        variant: "danger",
        onClick: () => handleDeleteItem(activeItem),
        isAdmin: true,
      },
    ];
  }

  useLayoutEffect(() => {
    generalDispatch({ type: "SET_VIEW_NAME", payload: "items" });
    getGlobalTags();
  }, [generalDispatch, getGlobalTags]);

  // Search
  useEffect(() => {
    if (items.length === 0) return;
    const results = searchItems(keywordDebounce);
    itemDispatch({ type: "SET_ITEM_RESULTS", results });
    // eslint-disable-next-line
  }, [keywordDebounce, filters.typeId, filters.tagId]);

  // Fetch items
  useEffect(() => {
    if (items.length === 0 && !itemListLoading) fetchAllItems();
    // eslint-disable-next-line
  }, []);

  // After fetch items
  useEffect(() => {
    if (itemListLoading) return;
    if (items.length > 0) {
      const regexItemId = /\/items\/([0-9]*)/g;
      const matches = window.location.href.match(regexItemId);
      if (matches) {
        const id = matches[0].replace("/items/", "");
        if (id > 0) {
          itemDispatch({ type: "SET_ITEM_KEYWORD", payload: id });
          const item = _.find(items, (_item) => _item.itemId === Number(id));
          if (item) selectItem(item);
        }
      }
    }
    // eslint-disable-next-line
  }, [itemListLoading]);

  function renderItemContain() {
    if (!activeItem) return null;
    if (!showOffItemContainCanvas) return null;
    let internalTypeArray = [];

    if (activeItem.latestPackageItem) {
      activeItem.latestPackageItem.forEach((c) => {
        if (internalTypeArray.indexOf(c.internalType) === -1)
          internalTypeArray = [...internalTypeArray, c.internalType];
      });
    }

    return (
      <>
        {activeItem.latestPackageItem && activeItem.latestPackageItem.length ? (
          <>
            <Table bordered hover size="sm">
              <thead>
                <tr>
                  <th>ID</th>
                  <th>DB Name</th>
                  <th>Count</th>
                  <th>Chance</th>
                  <th>Rate (%)</th>
                </tr>
              </thead>
              <tbody>
                {internalTypeArray.map((internalType, index) => {
                  const totalChance = activeItem.latestPackageItem.reduce(
                    (t, item) => {
                      if (item.internalType === internalType)
                        return t + item.chance;
                      return t;
                    },
                    0
                  );

                  const totalPercent = activeItem.latestPackageItem.reduce(
                    (t, item) => {
                      if (item.internalType === internalType)
                        return t + (item.chance / totalChance) * 100;
                      return t;
                    },
                    0
                  );

                  const str1 = activeItem.latestPackageItem.map(
                    (item, index) => {
                      if (item.internalType === internalType) {
                        const percent =
                          item.internalType === "must"
                            ? 100
                            : (item.chance / totalChance) * 100;

                        return (
                          <tr key={`${internalType}-${index}`}>
                            <td>{item.itemId}</td>
                            <td>{item.dbName}</td>
                            <td>{item.count}</td>
                            <td>{item.chance}</td>
                            <td>{percent.toFixed(2)}</td>
                          </tr>
                        );
                      }
                      return null;
                    }
                  );

                  const str2 = (
                    <tr className="table-secondary">
                      <td colSpan="3">{internalType}</td>
                      <td>{totalChance}</td>
                      <td>{`${totalPercent.toFixed(0)}%`}</td>
                    </tr>
                  );

                  return (
                    <React.Fragment key={internalType}>
                      {str1}
                      {str2}
                      {index < internalTypeArray.length - 1 ? (
                        <tr>
                          <td colSpan="5"></td>
                        </tr>
                      ) : null}
                    </React.Fragment>
                  );
                })}
              </tbody>
            </Table>
          </>
        ) : null}
      </>
    );
  }

  function renderMonstersDrop() {
    if (!activeItem || !activeItem.drops || activeItem.drops.length === 0)
      return null;

    return (
      <Table striped hover size="sm">
        <thead>
          <tr>
            <th>ID</th>
            <th>Monster</th>
            <th>Rate (%)</th>
          </tr>
        </thead>
        <tbody>
          {activeItem.drops.map((drop, index) => {
            return (
              <tr key={index}>
                <td>{drop.id}</td>
                <td>
                  {drop.isMvPDrop ? (
                    <span className="badge bg-danger me-1">MVP!</span>
                  ) : null}
                  <span>{drop.name ? drop.name : drop.dbName}</span>
                </td>
                <td>{drop.chance / 100}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    );
  }

  function getItemTypeNameById(typeId) {
    switch (typeId) {
      case 0:
        return "Heal";
      case 1:
        return "Armor";
      case 2:
        return "ArmorTB";
      case 3:
        return "ArmorTM";
      case 4:
        return "ArmorMB";
      case 5:
        return "ArmorTMB";
      case 6:
        return "Weapon";
      case 7:
        return "Bow";
      case 8:
        return "Gun";
      case 9:
        return "Both-hand";
      case 10:
        return "Ammo";
      case 11:
        return "Arrow";
      case 12:
        return "Throw Weapon";
      case 13:
        return "Cannon Ball";
      case 14:
        return "Card";
      case 15:
        return "Event";
      case 16:
        return "Cash Point Item";
      case 17:
        return "Special";
      default:
        return "All";
    }
  }

  function typeOptions() {
    let options = [{ optionValue: "-1", optionName: "All Types" }];
    for (let i = 0; i < 18; i++) {
      options = [
        ...options,
        { optionValue: `${i}`, optionName: getItemTypeNameById(i) },
      ];
    }
    return options;
  }

  function tagOptions() {
    let options = [{ optionValue: "-1", optionName: "none" }];
    globalTags.forEach((tag) => {
      options = [
        ...options,
        { optionValue: `${tag.id}`, optionName: `${tag.name}` },
      ];
    });
    return options;
  }

  function addItemToTabs({ id, name }) {
    // itemId -> id
    const item = _.find(items, (item) => item.id === id);

    // show tab if item existed on tabs
    const itemTabIndex = _.findIndex(views.items, (item) => item.id === id);

    if (itemTabIndex === -1) {
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "add",
          viewCat: "items",
          value: item,
        },
      });
    }

    selectItem(item);
  }

  return (
    <>
      <Row>
        <Col
          md={3}
          lg={2}
          className={`col-table-list-item mb-3 mb-md-0 ${
            hideItemList ? "d-none" : ""
          }`}
        >
          <List
            loading={itemListLoading}
            results={itemResults}
            keyword={itemKeyword}
            setKeyword={(e) =>
              itemDispatch({
                type: "SET_ITEM_KEYWORD",
                payload: e.target.value,
              })
            }
            selectItem={selectItem}
            columns={columns}
            buttons={buttons}
            filterTitle="Item Filters"
            filters={listFilters}
            onClearFilters={() => itemDispatch({ type: "CLEAR_ITEM_FILTERS" })}
            addToTabs={addItemToTabs}
            tabUniqueField="dbName"
          />
          <ItemHistories
            replaceDataFrom={replaceItemDataFrom}
            itemId={activeItem.itemId}
          />
        </Col>
        <Col md={hideItemList ? 12 : 9} lg={hideItemList ? 12 : 10}>
          <ItemTabs />
        </Col>
      </Row>

      {activeItem ? (
        <>
          <OffCanvasTable
            show={showOffItemContainCanvas}
            onHide={() =>
              itemDispatch({ type: "HANDLE_ITEM_CONTAIN", payload: false })
            }
            title={`${activeItem.itemId} - ${activeItem.dbName}`}
            count={
              activeItem.latestPackageItem
                ? activeItem.latestPackageItem.length
                : 0
            }
            render={renderItemContain}
          />

          <OffCanvasTable
            show={monstersDropsCanvas.showOff}
            onHide={() => itemDispatch({ type: "HIDE_MONSTERS_DROP" })}
            title={`Monsters drop [${activeItem.identifiedDisplayName}]`}
            count={activeItem.drops ? activeItem.drops.length : 0}
            loading={monstersDropsCanvas.state === "loading"}
            render={renderMonstersDrop}
          />

          <ItemNavigation
            itemId={activeItem.itemId}
            name={activeItem.identifiedDisplayName}
            isHide={hideItemList}
            setHide={() =>
              itemDispatch({ type: "HANDLE_ITEM_LIST", isHide: !hideItemList })
            }
            list={itemResults}
            select={selectItem}
          />
        </>
      ) : null}
    </>
  );
};

export default Item;
