import React, { useReducer } from "react";
import _ from "lodash";
import axios from "axios";
import { useDebounce } from "use-debounce";
import showToast from "../utils/showToast";
import { useUser } from "../contexts/userContext";
import { useGeneral } from "../contexts/generalContext";
import removeAccents from "../utils/removeAccents";

const emptyMap = {
  dbName: "",
  name: "",
  monsters: [],
  mapSizeX: 0,
  mapSizeY: 0,
  date: null,
  saving: false,
};

const MapContext = React.createContext();

const mapMainReducer = (state, action) => {
  let indexThisMap = -1;
  let indexThisMap2 = -1;
  let copyMaps = [];
  let copyMapResults = [];
  switch (action.type) {
    case "SET_MAP_KEYWORD":
      return { ...state, mapKeyword: action.payload };
    case "MAP_LIST_LOADING":
      return { ...state, mapLoading: true };
    case "SET_MAP_RESULTS":
      return { ...state, mapResults: action.payload };
    case "UPDATE_MAP_TYPE":
      indexThisMap = _.findIndex(
        state.maps,
        (map) => map.dbName === action.payload
      );
      copyMaps = [...state.maps];
      copyMaps[indexThisMap]["saving"] = true;
      return { ...state, maps: copyMaps };
    case "UPDATE_MAP_TYPE_FAILED":
      indexThisMap = _.findIndex(
        state.maps,
        (map) => map.dbName === action.payload.dbName
      );
      copyMaps = [...state.maps];
      copyMaps[indexThisMap] = {
        ...copyMaps[indexThisMap],
        saving: false,
      };

      indexThisMap2 = _.findIndex(
        state.mapResults,
        (map) => map.dbName === action.payload.dbName
      );
      copyMapResults = [...state.mapResults];
      copyMapResults[indexThisMap2] = {
        ...copyMapResults[indexThisMap2],
        saving: false,
      };

      return { ...state, maps: [...copyMaps], mapResults: [...copyMapResults] };
    case "SET_MAP_TO_LIST":
      // maps
      indexThisMap = _.findIndex(
        state.maps,
        (map) => map.dbName === action.payload.dbName
      );
      copyMaps = [...state.maps];
      copyMaps[indexThisMap] = { ...action.payload };

      // mapResults
      indexThisMap2 = _.findIndex(
        state.mapResults,
        (map) => map.dbName === action.payload.dbName
      );
      copyMapResults = [...state.mapResults];
      copyMapResults[indexThisMap2] = { ...action.payload };

      return { ...state, maps: [...copyMaps], mapResults: [...copyMapResults] };
    case "FETCH_MAPS_SUCCESS":
      return {
        ...state,
        maps: action.payload,
        mapResults: action.payload,
        mapLoading: false,
      };
    case "SET_HIDE_MAP_LIST":
      return { ...state, hideMapList: action.payload };
    default:
      return state;
  }
};

const MapProvider = (props) => {
  const [mapMain, mapDispatch] = useReducer(mapMainReducer, {
    maps: [],
    mapResults: [],
    mapLoading: false,
    hideMapList: false,
    mapKeyword: "",
  });

  const { userToken } = useUser();
  const { views, generalDispatch, tabActive } = useGeneral();
  const { maps, mapResults, mapLoading, mapKeyword, hideMapList } = mapMain;
  const [mapKeywordDebounce] = useDebounce(mapKeyword, 300);
  const activeItem = views.maps[tabActive.maps];

  function selectMap(map) {
    if (typeof map === "undefined")
      return showToast("error", "Map is undefined!");

    let mapFull = null;

    // set state to view
    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "active",
        viewCat: "maps",
        viewField: "dbName",
        value: {
          ...activeItem,
          dbName: map.dbName,
          name: map.name,
          loading: true,
        },
      },
    });

    // Fetch vRO data for this map
    axios({
      url: `${process.env.REACT_APP_API}/maps/${map.dbName}`,
      method: "GET",
      headers: { "x-auth-token": userToken },
    }).then((res) => {
      mapFull = {
        ...emptyMap,
        ...map,
        ...res.data,
      };

      // sort monsters if any
      const monsters = res.data.monsters;
      if (monsters.length) {
        mapFull.monsters = _.sortBy(monsters, (monsterInfo) => {
          return monsterInfo.monster.dbName;
        });
      }

      // set state to view
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "active",
          viewCat: "maps",
          viewField: "dbName",
          value: mapFull,
        },
      });

      // map preview
      mapDispatch({
        type: "FETCH_MAP_PREVIEW_SUCCESS",
        payload: {
          server: "vRO",
          data: {
            ...mapFull,
            dbName: map.dbName,
            notFound: false,
          },
        },
      });
    });
  }

  function searchMaps(keyword) {
    let filtered = [];
    let results = [];

    filtered = [...maps];

    if (keyword !== "") {
      const normalizeKeyword = removeAccents(keyword.toString().trim());

      // Search by name
      const mapsSearchByName = filtered.filter((map) => {
        const normalizeThisMapName = removeAccents(map.name).toLowerCase();
        return normalizeThisMapName.includes(normalizeKeyword.toLowerCase());
      });

      // Search by dbName
      const mapsSearchByDBName = filtered.filter((map) => {
        const normalizeThisMapName = removeAccents(map.dbName).toLowerCase();
        return normalizeThisMapName.includes(normalizeKeyword.toLowerCase());
      });

      // Combine results
      _.forEach(mapsSearchByName, (map) => {
        if (!_.some(results, ["dbName", map.dbName])) {
          results = [...results, map];
        }
      });
      _.forEach(mapsSearchByDBName, (map) => {
        if (!_.some(results, ["dbName", map.dbName])) {
          results = [...results, map];
        }
      });
    } else {
      results = [...filtered];
    }
    return results;
  }

  const value = {
    maps,
    mapResults,
    mapLoading,
    hideMapList,
    mapKeyword,
    mapKeywordDebounce,
    selectMap,
    searchMaps,
    mapDispatch,
  };

  return <MapContext.Provider value={value} {...props} />;
};

function useMap() {
  const context = React.useContext(MapContext);
  if (!context) {
    throw new Error(`useContext must be used within mapProvider.`);
  }
  return context;
}

export { MapProvider, useMap, emptyMap };
