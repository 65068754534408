import React from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useGeneral } from "../../contexts/generalContext";
import showToast from "../../utils/showToast";

const ColorButtons = () => {
  const { general } = useGeneral();
  const { activedElementID } = general;
  const [showModalNavigation, setShowModalNavigation] = React.useState(false);
  const [navigation, setNavigation] = React.useState({
    label: "",
    map: "",
    x: 0,
    y: 0,
  });

  function handleChangeNavigation(field, value) {
    let navigationCopy = { ...navigation };
    navigationCopy[field] = value;
    setNavigation(navigationCopy);
  }

  function handleClose() {
    setShowModalNavigation(false);
  }

  function getSelectedValueInTextarea() {
    const textarea = document.getElementById(activedElementID);

    if (!textarea) {
      return showToast("warning", "Focus text box or select text first!");
    }

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const sel = textarea.value.substring(start, end);
    textarea.focus();
    return sel;
  }

  function insertColor(color) {
    const sel = getSelectedValueInTextarea();
    document.execCommand("insertText", false, `^${color}${sel}^000000`);
  }

  function addText(text) {
    getSelectedValueInTextarea();
    document.execCommand("insertText", false, text);
  }

  function insertNavigation() {
    getSelectedValueInTextarea();
    document.execCommand(
      "insertText",
      false,
      `<NAVI>^4D4DFF[${navigation.label}]^000000<INFO>${navigation.map},${navigation.x},${navigation.y},0,100,0,0</INFO></NAVI>`
    );
    setShowModalNavigation(false);
  }

  function handleModalNavigation() {
    setShowModalNavigation(true);
  }

  return (
    <>
      <div className="color-buttons">
        <Button
          size="sm"
          type="button"
          variant="danger"
          onClick={() => insertColor("FF0000")}
        >
          Fire
        </Button>
        <Button
          size="sm"
          type="button"
          variant="primary"
          onClick={() => insertColor("0000BB")}
        >
          Water
        </Button>
        <Button
          size="sm"
          type="button"
          onClick={() => insertColor("CC5500")}
          className="earth-color"
        >
          Earth
        </Button>
        <Button
          size="sm"
          type="button"
          onClick={() => insertColor("33CC00")}
          className="wind-color"
        >
          Wind
        </Button>
        <Button
          size="sm"
          type="button"
          onClick={() => insertColor("663399")}
          className="poison-color"
        >
          Poison
        </Button>
        <Button
          size="sm"
          type="button"
          onClick={() => insertColor("009900")}
          className="skill-color"
        >
          Skill
        </Button>
        <Button
          size="sm"
          type="button"
          onClick={() => insertColor("663399")}
          className="effect-color"
        >
          Effect
        </Button>
        <Button
          size="sm"
          type="button"
          onClick={() => insertColor("0000FF")}
          className="item-color"
        >
          Item
        </Button>
        <Button
          size="sm"
          type="button"
          onClick={() => insertColor("990099")}
          className="equipment-color"
        >
          EQ
        </Button>
        <Button
          size="sm"
          type="button"
          onClick={() => insertColor("777777")}
          className="neutral-color"
        >
          Neutral/Holy/Undead/Ghost
        </Button>

        <Button size="sm" type="button" onClick={handleModalNavigation}>
          Navigation
        </Button>

        <Button
          size="sm"
          type="button"
          onClick={() => addText("________________________")}
        >
          Line
        </Button>
        <Button
          size="sm"
          type="button"
          variant="success"
          onClick={() =>
            addText(
              `^800080Set Bonus^000000\n^008080Item A^000000\n^008080Item B^000000`
            )
          }
        >
          Set Bonus
        </Button>
      </div>

      <Modal show={showModalNavigation} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Modal heading</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Label className="col-form-label">Label</Form.Label>
              <Form.Control
                type="text"
                placeholder="Label"
                size="sm"
                value={navigation && navigation.label}
                onChange={(e) =>
                  handleChangeNavigation("label", e.target.value)
                }
              />
            </Col>
            <Col>
              <Form.Label className="col-form-label">Map</Form.Label>
              <Form.Control
                type="text"
                placeholder="prontera, morocc, payon, ..."
                size="sm"
                value={navigation && navigation.map}
                onChange={(e) => handleChangeNavigation("map", e.target.value)}
              />
            </Col>
            <Col>
              <Form.Label className="col-form-label">X</Form.Label>
              <Form.Control
                type="number"
                placeholder="X"
                size="sm"
                value={navigation && navigation.x}
                onChange={(e) => handleChangeNavigation("x", e.target.value)}
              />
            </Col>
            <Col>
              <Form.Label className="col-form-label">Y</Form.Label>
              <Form.Control
                type="number"
                placeholder="Y"
                size="sm"
                value={navigation && navigation.y}
                onChange={(e) => handleChangeNavigation("y", e.target.value)}
              />
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={insertNavigation}>
            Insert
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ColorButtons;
