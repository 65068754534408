import React from "react";
import { useLocation, Redirect } from "react-router-dom";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import { useUser } from "../contexts/userContext";

import LoginForm from "./LoginForm";

const Login = () => {
  const { userToken, setUserToken } = useUser();
  const location = useLocation();

  if (!userToken) {
    return (
      <GoogleReCaptchaProvider reCaptchaKey="6Ld217wZAAAAAGYKU42Pueo2kYiar5m4O2VhJkGf">
        <LoginForm setUserToken={setUserToken} />
      </GoogleReCaptchaProvider>
    );
  } else {
    const redirectPath = location.state?.from?.pathname || "/";
    return <Redirect to={redirectPath} />;
  }
};

export default Login;
