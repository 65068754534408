import React from "react";
import _ from "lodash";
import Swal from "sweetalert2";
import { useLocalStorageState } from "../utils/useLocalStorageState";

import { toast } from "react-toastify";
import Input from "../components/common/Input";

const initialRecord = {
  itemId: "",
  dbName: "",
  size: "",
};

const RateCalculate = (props) => {
  const [records, setRecords] = useLocalStorageState("records", []);
  const [record, setRecord] = React.useState(() => initialRecord);
  const [editRecord, setEditRecord] = React.useState();
  const [insertIndex, setInsertIndex] = React.useState(-1);
  const [footerVariable, setFooterVariable] = React.useState({
    totalPercent: 0,
    totalPercentage: 0,
  });

  const { itemId, dbName, size } = record;

  // Update total value
  React.useEffect(() => {
    let totalPercentage = 0;
    let totalPercent = 0;

    _.forEach(records, (record) => {
      totalPercentage += Number(record.size);
    });

    const recordsCopy = records;
    _.forEach(recordsCopy, (record, i) => {
      const percent = (Number(record.size) / totalPercentage) * 100;
      recordsCopy[i].rate = percent;
      totalPercent += percent;
    });
    setRecords(recordsCopy);

    setFooterVariable({ totalPercent, totalPercentage });
  }, [records, setRecords]);

  function handleSubmit(e) {
    e.preventDefault();
    if (
      (record.itemId.trim() === "" && record.dbName.trim() === "") ||
      record.size === "" ||
      record.size < 1
    )
      return;

    // itemId existed
    const existed = _.findIndex(records, (r) => {
      return r.itemId === record.itemId;
    });
    if (existed !== -1) {
      return toast.warning(`Item with ID ${record.itemId} already exists!`, {
        position: "bottom-right",
        autoClose: 2000,
      });
    }

    // Duplicate
    let recordsCopy = [...records];
    let insertAtIndex = recordsCopy.length;
    if (insertIndex > -1) {
      insertAtIndex = insertIndex;
    }

    recordsCopy.splice(insertAtIndex, 0, record);

    // Add new record
    setRecords(recordsCopy);
    setRecord(initialRecord);

    toast.success(`Added ${record.dbName} successfully!`, {
      position: "bottom-right",
      autoClose: 2000,
    });
  }

  function handleChange(field, value) {
    let recordCopy = { ...record };
    recordCopy[`${field}`] = value;
    setRecord(recordCopy);
  }

  function handleUpdateChange(field, value) {
    let recordCopy = { ...editRecord };
    recordCopy.data[`${field}`] = value;
    setEditRecord(recordCopy);
  }

  function handleRemove(record) {
    Swal.fire({
      title: `Do you want to delete ${record.dbName}?`,
      text: "You won't be able to revert this!",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let recordsCopy = [...records];
        const newRecords = recordsCopy.filter((r) => {
          return r.itemId !== record.itemId;
        });
        setRecords(newRecords);
        toast.success(`Deleted ${record.dbName} successfully!`, {
          position: "bottom-right",
          autoClose: 2000,
        });
      }
    });
  }

  function handleSave(index) {
    let recordCopy = { ...records[index] };
    let recordsCopy = [...records];
    if (!Object.is(recordCopy, editRecord.data)) {
      recordsCopy.splice(index, 1, editRecord.data);
      setRecords(recordsCopy);
    }
    setEditRecord(null);
  }

  function RenderUpdateButtons({ index }) {
    return editRecord && index === editRecord.index ? (
      <button
        onClick={() => handleSave(index)}
        className="btn btn-sm btn-light"
      >
        <span role="img" aria-label="Save">
          💾
        </span>
      </button>
    ) : (
      <button
        onClick={() => setEditRecord({ index, data: records[index] })}
        className="btn btn-sm btn-light"
      >
        <span role="img" aria-label="Edit">
          ✏
        </span>
      </button>
    );
  }

  return (
    <div className="row">
      <div className="col-4">
        <form onSubmit={handleSubmit}>
          <div className="row">
            <div className="col-sm-3">
              <Input
                value={itemId}
                type="number"
                placeholder="Item ID"
                onChange={(e) => handleChange("itemId", e.target.value)}
              />
            </div>
            <div className="col-sm-6">
              <Input
                value={dbName}
                type="text"
                placeholder="DB Name"
                onChange={(e) => handleChange("dbName", e.target.value)}
              />
            </div>
            <div className="col-sm-3">
              <Input
                value={size}
                type="number"
                placeholder="Size"
                onChange={(e) => handleChange("size", e.target.value)}
              />
            </div>
          </div>
          <div className="row">
            <div className="col-3">
              <Input
                value={insertIndex}
                type="number"
                placeholder="Insert Index"
                onChange={(event) => setInsertIndex(event.target.value)}
              />
            </div>
            <div className="col-3">
              <button type="submit" className="btn btn-primary">
                Add
              </button>
            </div>
          </div>
        </form>
      </div>
      <div className="col-8">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th style={{ width: 60 }}>Num</th>
              <th style={{ width: 100 }}>Item ID</th>
              <th style={{ width: 400 }}>DB Name</th>
              <th style={{ width: 100 }} className="text-right">
                Percent %
              </th>
              <th style={{ width: 100 }}>Percentage</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {records &&
              records.map((record, index) => {
                return (
                  <tr key={index}>
                    <td>{index + 1}</td>
                    <td>
                      {!editRecord || editRecord.index !== index ? (
                        <>{record.itemId}</>
                      ) : (
                        <input
                          className="form-control"
                          value={editRecord.data.itemId}
                          onChange={(e) =>
                            handleUpdateChange("itemId", e.target.value)
                          }
                        />
                      )}
                    </td>
                    <td>
                      {!editRecord || editRecord.index !== index ? (
                        <>{record.dbName}</>
                      ) : (
                        <input
                          className="form-control"
                          value={editRecord.data.dbName}
                          onChange={(e) =>
                            handleUpdateChange("dbName", e.target.value)
                          }
                        />
                      )}
                    </td>
                    <td className="text-right">
                      {typeof record.rate !== "undefined" &&
                        `${record.rate.toFixed(2)}%`}
                    </td>
                    <td>
                      {!editRecord || editRecord.index !== index ? (
                        <>{record.size}</>
                      ) : (
                        <input
                          className="form-control"
                          value={editRecord.data.size}
                          onChange={(e) =>
                            handleUpdateChange("size", e.target.value)
                          }
                        />
                      )}
                    </td>
                    <td>
                      <RenderUpdateButtons index={index} />
                      <button
                        onClick={() => handleRemove(record)}
                        className="btn btn-sm btn-light"
                      >
                        <span role="img" aria-label="Remove">
                          ❌
                        </span>
                      </button>
                    </td>
                  </tr>
                );
              })}
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td className="text-right">
                {footerVariable.totalPercent.toFixed(3)}%
              </td>
              <td>{footerVariable.totalPercentage}</td>
              <td></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RateCalculate;
