import React, { useLayoutEffect, useEffect } from "react";
import axios from "axios";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import MapTabs from "./MapTabs";
import Swal from "sweetalert2";
import List from "../common/List";
import ItemNavigation from "../common/ItemNavigation";
import { useUser } from "../../contexts/userContext";
import { useGeneral } from "../../contexts/generalContext";
import { useMap, emptyMap } from "../../contexts/mapContext";

const Map = () => {
  const { views, generalDispatch, tabActive } = useGeneral();
  const { userToken, setUserToken } = useUser();
  const activeItem = views.maps[tabActive.maps];

  const {
    maps,
    hideMapList,
    mapLoading,
    mapResults,
    mapKeyword,
    selectMap,
    mapDispatch,
    searchMaps,
    mapKeywordDebounce,
  } = useMap();

  const columns = [
    {
      label: "dbName",
      dataKey: "dbName",
      width: 120,
    },
    {
      label: "Name",
      dataKey: "name",
      width: 200,
    },
  ];

  const buttons = [];

  useLayoutEffect(() => {
    generalDispatch({ type: "SET_VIEW_NAME", payload: "maps" });
  }, [generalDispatch]);

  // Search
  useEffect(() => {
    if (maps.length === 0) return;
    const results = searchMaps(mapKeywordDebounce);
    mapDispatch({ type: "SET_MAP_RESULTS", payload: results });
    // eslint-disable-next-line
  }, [mapKeywordDebounce]);

  // Fetch maps
  useEffect(() => {
    if (maps.length === 0 && !mapLoading) {
      mapDispatch({ type: "MAP_LIST_LOADING", isLoading: true });
      generalDispatch({
        type: "SET_VIEWS",
        payload: { viewType: "init", viewCat: "maps", value: [emptyMap] },
      });

      // List of maps
      axios({
        url: `${process.env.REACT_APP_API}/maps`,
        method: "GET",
        headers: { "x-auth-token": userToken },
      })
        .then((res) => {
          mapDispatch({ type: "FETCH_MAPS_SUCCESS", payload: res.data });
        })
        .catch((error) => {
          if (
            typeof error.response !== "undefined" &&
            error.response.status === 401
          ) {
            Swal.fire({
              icon: "error",
              title: error.response.statusText,
              text: error.response.data,
              confirmButtonText: `Login`,
            }).then(() => {
              setUserToken("");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: error.name,
              text: error.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function addMapToTabs({ dbName }) {
    const map = _.find(maps, (map) => map.dbName === dbName);

    // show tab if quest existed on tabs
    const mapTabIndex = _.findIndex(views.maps, (map) => map.dbName === dbName);

    if (mapTabIndex === -1) {
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "add",
          viewCat: "maps",
          viewField: "dbName",
          value: map,
        },
      });
    }

    selectMap(map);
  }

  return (
    <>
      <Row>
        <Col
          md={3}
          lg={2}
          className={`col-table-list-item mb-3 mb-md-0 ${
            hideMapList ? "d-none" : ""
          }`}
        >
          <List
            loading={mapLoading}
            results={mapResults}
            keyword={mapKeyword}
            setKeyword={(e) =>
              mapDispatch({
                type: "SET_MAP_KEYWORD",
                payload: e.target.value,
              })
            }
            selectItem={selectMap}
            columns={columns}
            buttons={buttons}
            addToTabs={addMapToTabs}
            tabUniqueField="dbName"
          />
        </Col>
        <Col md={hideMapList ? 12 : 9} lg={hideMapList ? 12 : 10}>
          <MapTabs />
        </Col>
      </Row>

      {activeItem ? (
        <ItemNavigation
          id={activeItem.dbName}
          isHide={hideMapList}
          setHide={() =>
            mapDispatch({
              type: "SET_HIDE_MAP_LIST",
              payload: !hideMapList,
            })
          }
          list={mapResults}
          select={selectMap}
          field="dbName"
        />
      ) : null}
    </>
  );
};

export default Map;
