import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "react-bootstrap/Button";
import Spinner from "react-bootstrap/Spinner";
import _ from "lodash";
import MonsterDetail from "./MonsterDetail";

import { useGeneral } from "../../contexts/generalContext";
import { useMonster, emptyMonster } from "../../contexts/monsterContext";

const MonsterTabs = () => {
  const { views, tabActive, generalDispatch } = useGeneral();
  const { selectMonster } = useMonster();
  const { monsters } = views;
  const activeItem = monsters[tabActive.monsters];

  function setKey(id) {
    const monster = _.find(monsters, (monster) => monster.id === id);
    if (monster) selectMonster(monster);
  }

  function closeTab(monster) {
    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "delete",
        viewCat: "monsters",
        value: [emptyMonster],
      },
    });
  }

  return activeItem.id === -1 ? null : (
    <Tabs activeKey={activeItem.id} onSelect={(k) => setKey(Number(k))}>
      {monsters.map((monster) => {
        return (
          <Tab
            key={`monster-${monster.id}`}
            eventKey={monster.id}
            title={monster.dbName}
          >
            {monster.loading ? (
              <div className="spinner-center text-center">
                <Spinner animation="border" role="status"></Spinner>
                <span className="d-block ms-2">Loading...</span>
              </div>
            ) : (
              <div className="position-relative">
                <Button
                  onClick={() => closeTab(monster)}
                  variant="outline-danger"
                  className="btn-close-tab"
                  title="Close Tab"
                >
                  <span role="img" aria-label="Close Tab">
                    ❌
                  </span>
                </Button>
                <MonsterDetail monster={monster} />
              </div>
            )}
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default MonsterTabs;
