import React, { useCallback, useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { useLocalStorageState } from "../../utils/useLocalStorageState";
import { useDropzone } from "react-dropzone";
import wordsCount from "words-count";
import iconv from "iconv-lite";
import { trimLines, getLineType } from "../../utils/dialogUtils";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";

const initFile = {
  fileName: "",
  fileSize: 0,
  numberOfLines: 0,
  objects: [],
  numberOfWords: 0,
  duplicateObjects: [],
  numberWordDuplicates: 0,
  encoding: "cp1258",
};

function Count() {
  const [files, setFiles] = useState([]);
  const [file, setFile] = useState(initFile);
  const [showModal, setShowModal] = useState(false);
  const [fileShow, setFileShow] = useState(initFile);
  const [encoding, setEncoding] = useLocalStorageState(
    "countEncoding",
    "cp1258"
  );
  const [hideZero, setHideZero] = useLocalStorageState("countHideZero", true);
  const [checkDuplicate, setCheckDuplicate] = useLocalStorageState(
    "countCheckDuplicates",
    false
  );

  const countTypes = useMemo(() => {
    return [
      "dialog",
      "dialog_NPC",
      "string_menu",
      "string_to_variable",
      "dialog_talk",
      "broadcast",
    ];
  }, []);

  const countWord = useCallback(
    (object, encoding) => {
      let regex = "";
      let count = 0;

      if (countTypes.indexOf(object.type) !== -1) {
        let m;
        regex = /(".*?")/gm;
        while ((m = regex.exec(object.line)) !== null) {
          if (m.index === regex.lastIndex) regex.lastIndex++;
          // The result can be accessed through the `m`-variable.
          // eslint-disable-next-line no-loop-func
          let c = 0;
          m.forEach((match, groupIndex) => {
            if (encoding === "cp1258") {
              c = match
                .replaceAll(`"`, ``)
                .replaceAll(`-`, ``)
                .trim()
                .split(/\s+/).length;
            } else c = wordsCount(match.replaceAll(`"`, ``).trim());
          });
          count += c;
        }
      }

      if (object.type === "dialog_talk") count -= 1;
      return count;
    },
    [countTypes]
  );

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");
        reader.onload = () => {
          let { result } = reader;
          let enc = encoding === "" ? "cp1258" : encoding;
          if (enc === "cp1258") {
            const buf = iconv.encode(result, "utf8");
            result = iconv.decode(buf, "utf8");
          }

          const stringArray = result.split("\n");
          let objects = [];
          let numberOfWords = 0;
          let numberOfLines = stringArray.length;

          // Remove empty lines
          let lines = [];
          stringArray.forEach((line) => {
            line.trim() !== "" && lines.push(line);
          });

          // Count word
          lines = [...trimLines(lines)];
          lines.forEach((line) => {
            const object = getLineType(line);
            objects = [...objects, object];
            numberOfWords += countWord(object, encoding);
          });

          // Count duplicate words
          let array = [];
          let duplicateObjects = [];
          let numberWordDuplicates = 0;
          objects.forEach((object) => {
            if (countTypes.indexOf(object.type) === -1) return;
            const index = _.findIndex(array, (o) => o.line === object.line);
            if (index === -1)
              return (array = [...array, { ...object, count: 1 }]);
            const currentCount = array[index]["count"];
            array[index] = { ...object, count: currentCount + 1 };
          });
          // Remove object has 1 count
          duplicateObjects = [...array.filter((d) => d.count > 1)];
          duplicateObjects.forEach((d) => {
            numberWordDuplicates +=
              (d.count - 1) * countWord(d, fileShow.encoding);
          });

          setFile({
            fileName: file.name,
            fileSize: file.size,
            numberOfLines,
            objects,
            numberOfWords,
            encoding,
            duplicateObjects,
            numberWordDuplicates,
          });
        };
        reader.readAsText(file, encoding ? encoding : "cp1258");
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [countWord, encoding]
  );

  const baseStyle = useMemo(() => {
    return {
      flex: 1,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
      justifyContent: "center",
      borderWidth: 2,
      borderRadius: 2,
      borderColor: "#eeeeee",
      borderStyle: "dashed",
      backgroundColor: "#fafafa",
      color: "#bdbdbd",
      outline: "none",
      transition: "border .24s ease-in-out",
      height: "150px",
    };
  }, []);

  const focusedStyle = useMemo(() => {
    return { borderColor: "#2196f3" };
  }, []);

  const acceptStyle = useMemo(() => {
    return { borderColor: "#00e676" };
  }, []);

  const rejectStyle = useMemo(() => {
    return { borderColor: "#ff1744" };
  }, []);

  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop });

  const style = useMemo(
    () => ({
      ...baseStyle,
      ...(isFocused ? focusedStyle : {}),
      ...(isDragAccept ? acceptStyle : {}),
      ...(isDragReject ? rejectStyle : {}),
    }),
    [
      baseStyle,
      isFocused,
      focusedStyle,
      isDragAccept,
      acceptStyle,
      isDragReject,
      rejectStyle,
    ]
  );

  useEffect(() => {
    if (file.fileName === "" || file.fileSize === 0) return;
    // find and replace if any
    const index = _.findIndex(
      files,
      (f) => f.length === file.length && f.fileName === file.fileName
    );
    let filesCopy = [...files];
    if (index !== -1) filesCopy[index] = { ...file };
    else filesCopy = [...files, file];
    setFiles(filesCopy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [file]);

  function getTrClass(type) {
    if (countTypes.indexOf(type) !== -1) return "text-danger";
    return "text-muted";
  }

  useEffect(() => {
    if (showModal === false) return setFileShow({ ...initFile });
  }, [showModal]);

  function showDetail(file) {
    setFileShow({ ...file });
    setShowModal(true);
  }

  function removeFile(fileIndex) {
    const filesCopy = [...files];
    filesCopy.splice(fileIndex, 1);
    setFiles(filesCopy);
  }

  function getTotalWords() {
    let total = 0;
    files.forEach((file) => {
      total += file.numberOfWords;
    });
    return total.toLocaleString();
  }

  function getTotalWords2() {
    let total = 0;
    let total2 = 0;
    files.forEach((file) => {
      total += file.numberOfWords;
      total2 += file.numberWordDuplicates;
    });
    return (total - total2).toLocaleString();
  }

  return (
    <section className="container">
      <Row>
        <Col>
          <Form.Select
            value={encoding}
            aria-label="Chooose an encoding"
            onChange={(e) => setEncoding(e.target.value)}
          >
            <option value="cp1258">cp1258 (Vietnamese, English)</option>
            <option value="euc-kr">euc-kr (Korean)</option>
            <option value="cp1252">cp1252 (English)</option>
            <option value="Big5">Big5 (Traditional Chinese)</option>
          </Form.Select>

          <div {...getRootProps({ className: "dropzone mb-3", style })}>
            <input {...getInputProps()} />
            <p className="text-center">
              Drag 'n' drop *.sc file here, or click to select file
            </p>
          </div>
        </Col>
        <Col>
          <h6>
            Number files: <code>{files.length}</code>
          </h6>
          <h6>
            Total words: <code>{getTotalWords()}</code>
          </h6>
          {checkDuplicate ? (
            <h6>
              Total words (Removed duplicate word):{" "}
              <code>{getTotalWords2()}</code>
            </h6>
          ) : null}
        </Col>
      </Row>

      <Row className="count-script-list">
        {files.map((file, index) => {
          return (
            <Col key={index} md={3} className="mb-3 position-relative">
              <div className="p-3 border mb-3">
                <h6>
                  File: <code>{file.fileName}</code>
                </h6>
                <h6>
                  Size:{" "}
                  <code>{file.fileSize > 0 ? file.fileSize / 1000 : 0} kb</code>
                </h6>
                <h6>
                  Number of lines: <code>{file.numberOfLines}</code>
                </h6>
                <h6>
                  Encoding: <code>{file.encoding}</code>
                </h6>
                <hr />
                <h6>
                  Number of words: <code>{file.numberOfWords}</code>
                </h6>
                {checkDuplicate ? (
                  <>
                    <h6>
                      Duplicate words: <code>{file.numberWordDuplicates}</code>
                    </h6>
                    <h6>
                      Total words:{" "}
                      <code>
                        {file.numberOfWords - file.numberWordDuplicates}
                      </code>
                    </h6>
                  </>
                ) : null}
                <hr />
                <Button
                  variant="outline-primary"
                  onClick={() => showDetail(file)}
                >
                  Details
                </Button>
              </div>
              <Button
                type="button"
                className="btn-close"
                aria-label="Close"
                onClick={() => removeFile(index)}
              ></Button>
            </Col>
          );
        })}
      </Row>

      <Modal
        className="modal-fileShow"
        show={showModal}
        size="xl"
        onHide={() => setShowModal(false)}
      >
        <Modal.Header closeButton>
          <Modal.Title className="w-100">
            <Row class>
              <Col>{fileShow.fileName}</Col>
              <Col xs={2}>
                <Form.Check
                  type="switch"
                  id="check-duplicate-switch"
                  label="Check duplicate"
                  checked={checkDuplicate}
                  onChange={() => setCheckDuplicate(!checkDuplicate)}
                />
                <Form.Check
                  type="switch"
                  id="show-zero-switch"
                  label="Show 0 Word"
                  checked={!hideZero}
                  onChange={() => setHideZero(!hideZero)}
                />
              </Col>
            </Row>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <Row>
            <Col className="ms-3 p-3">
              <code> Number of words: {fileShow.numberOfWords}</code>
              {checkDuplicate ? (
                <code className="d-block">
                  Dupicate words: {fileShow.numberWordDuplicates}
                  <br />
                  Total:{" "}
                  {fileShow.numberOfWords - fileShow.numberWordDuplicates}
                </code>
              ) : null}
            </Col>
          </Row>

          <Tabs defaultActiveKey="content" id="script-tabs" className="mb-3">
            <Tab eventKey="content" title="Content">
              <Table bordered hover responsive size="sm">
                <thead>
                  <tr>
                    <th>#</th>
                    <th>Content</th>
                    <th>Type</th>
                    <th>N.Words</th>
                  </tr>
                </thead>
                <tbody>
                  {fileShow.objects.map((object, index) => {
                    const word = countWord(object, fileShow.encoding);
                    if (hideZero && word === 0) return null;
                    return (
                      <tr className={getTrClass(object.type)} key={index}>
                        <td>{index}</td>
                        <td>{object.line}</td>
                        <td>{object.type}</td>
                        <td>{countWord(object, fileShow.encoding)}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </Tab>
            {checkDuplicate ? (
              <Tab eventKey="duplicate" title="Duplicate">
                <Table bordered hover responsive size="sm">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Content</th>
                      <th>Type</th>
                      <th>N.Words</th>
                      <th width="150px">Duplicate (times)</th>
                    </tr>
                  </thead>
                  <tbody>
                    {fileShow.duplicateObjects &&
                      fileShow.duplicateObjects.map((object, index) => {
                        return (
                          <tr className={getTrClass(object.type)} key={index}>
                            <td>{index}</td>
                            <td>{object.line}</td>
                            <td>{object.type}</td>
                            <td>{countWord(object, fileShow.encoding)}</td>
                            <td>{object.count}</td>
                          </tr>
                        );
                      })}
                  </tbody>
                </Table>
              </Tab>
            ) : null}
          </Tabs>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </section>
  );
}

export default Count;
