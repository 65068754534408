import React from "react";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";

import ProtectedRoute from "./components/ProtectedRoute";
import NavbarApp from "./components/common/NavbarApp";
import Home from "./components/Home";
import Login from "./components/Login";
import Logout from "./components/Logout";
import Admin from "./Admin";
import Item from "./components/item/Item";
import NotFound from "./components/NotFound";

import { ItemProvider } from "./contexts/itemContext";
import { MonsterProvider } from "./contexts/monsterContext";
import { GeneralProvider } from "./contexts/generalContext";
import { UserProvider } from "./contexts/userContext";

import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-virtualized/styles.css";
import "./App.css";

function App() {
  return (
    <BrowserRouter>
      <UserProvider>
        <GeneralProvider>
          <NavbarApp />
          <ItemProvider>
            <MonsterProvider>
              <div className="container-fluid pt-3">
                <Switch>
                  <Route path="/login" component={Login} />
                  <Route path="/logout" component={Logout} />
                  <ProtectedRoute path="/admin" component={Admin} />
                  <Route path="/home" component={Home} />
                  <Route path="/item" component={Item} />
                  <Route path="/404" component={NotFound} />
                  <Route exact path="/" component={Home} />
                  <Redirect from="*" to="/404" />
                </Switch>
              </div>
            </MonsterProvider>
          </ItemProvider>
        </GeneralProvider>
      </UserProvider>
      <ToastContainer />
    </BrowserRouter>
  );
}

export default App;
