import React from "react";

export function useLocalStorageState(
	key,
	defaultValue = "",
	{ serialize = JSON.stringify, deserialize = JSON.parse } = {}
) {
	const [state, setState] = React.useState(() => {
		const valueInLocalStorage = window.localStorage.getItem(key);
		if (valueInLocalStorage) {
			try {
				return deserialize(valueInLocalStorage);
			} catch (error) {
				window.localStorage.removeItem(key);
			}
		}
		return typeof defaultValue === "function" ? defaultValue() : defaultValue;
	});

	const prevKeyRef = React.useRef(key);

	// Check the example at src/examples/local-state-key-change.js to visualize a key change
	React.useEffect(() => {
		const prevKey = prevKeyRef.current;
		if (prevKey !== key) {
			window.localStorage.removeItem(prevKey);
		}
		prevKeyRef.current = key;
		window.localStorage.setItem(key, serialize(state));
	}, [key, state, serialize]);

	return [state, setState];
}
