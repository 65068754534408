import React from "react";
import { Redirect } from "react-router";
import { useUser } from "../contexts/userContext";

const Logout = () => {
  const { userToken, logout } = useUser();

  React.useEffect(() => {
    logout();
    // eslint-disable-next-line
  }, []);

  return !userToken ? (
    <Redirect
      to={{
        pathname: "/",
      }}
    />
  ) : null;
};

export default Logout;
