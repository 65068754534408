import React from "react";
import human from "human-time";
import Button from "react-bootstrap/Button";
import _ from "lodash";
import { useUser } from "../../contexts/userContext";
import { useGeneral } from "../../contexts/generalContext";

const ItemHistory = ({ replaceDataFrom, itemId }) => {
  const { userProfile } = useUser();
  const { general, removeHistory } = useGeneral();
  const { viewName, histories } = general;

  let itemHistories = [];
  if (!viewName) return null;
  if (histories[viewName] && histories[viewName][itemId])
    itemHistories = histories[viewName][itemId];

  function replaceDataFromHistory(itemHistory) {
    const item = _.omit(itemHistory, ["date", "_id", "user"]);
    replaceDataFrom(item);
  }

  return itemHistories.length ? (
    <div className="block-item-histories d-none d-md-block mt-3">
      <h6>Histories</h6>
      <ul className="list-group list-item-history">
        {itemHistories.map((history) => {
          return (
            <li
              key={history._id}
              className="list-group-item list-group-item-action flex-column align-items-start"
            >
              <div className="d-flex w-100 justify-content-between">
                <small>
                  <u>
                    {typeof history.user !== "undefined"
                      ? `${history.user.name}`
                      : `Original`}
                  </u>
                  {typeof history.user !== "undefined" ? " edited" : " version"}
                </small>
              </div>
              <small className="text-muted">
                {`${new Date(history.date).toLocaleDateString()} • ${human(
                  new Date(history.date)
                )}`}
              </small>

              <div className="w-history-buttons">
                <Button
                  title="Revert to this version"
                  className="btn-apply-history"
                  variant="default"
                  onClick={() => replaceDataFromHistory(history)}
                >
                  ↩
                </Button>
                {userProfile.isAdmin ? (
                  <Button
                    className="btn-remove-history"
                    variant="default"
                    onClick={() => removeHistory(viewName, history)}
                  >
                    <span role="img" aria-label="Remove">
                      ❌
                    </span>
                  </Button>
                ) : null}
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  ) : null;
};

export default ItemHistory;
