import React from "react";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import Textarea from "../common/TextArea";
import { SpinnerComponent } from "react-element-spinner";
import { useQuest } from "../../contexts/questContext";
import { copyText, renderROHTML } from "../../utils/textUtils";

const QuestPreview = ({ questData }) => {
  const { handleChangeQuestValue, replaceQuestDataFrom } = useQuest();

  let { server, loading, name, description, shortDescription, notFound } =
    questData;

  function handleReplaceData() {
    replaceQuestDataFrom({
      name,
      description: JSON.stringify(description),
      shortDescription: JSON.stringify(shortDescription),
    });
  }

  function renderButtonNavigation() {
    const regexNavi = /<INFO>(.*?)<\/INFO>/gm;
    const navi = regexNavi.exec(description);
    if (navi) {
      const naviInfo = navi[1].split(",");
      return (
        <Button
          variant="outline-success"
          size="sm"
          onClick={() =>
            copyText(`/mm ${naviInfo[0]}.gat ${naviInfo[1]} ${naviInfo[2]}`)
          }
        >
          /mm {naviInfo[0]}.gat {naviInfo[1]} {naviInfo[2]}
        </Button>
      );
    }
    return null;
  }

  return (
    <>
      <div className="position-relative">
        <Form.Group>
          <FloatingLabel controlId="floatingInputGrid" label="Name">
            <FormControl
              readOnly={server !== "vRO"}
              variant="light"
              type="text"
              placeholder="Name"
              aria-label="Name"
              onChange={(e) => handleChangeQuestValue("name", e.target.value)}
              value={name ? name : ""}
            />
          </FloatingLabel>
        </Form.Group>

        <Form.Group>
          <Form.Label className="col-form-label">Short Description</Form.Label>
          <Form.Control
            readOnly={server !== "vRO"}
            type="text"
            placeholder="Short Description"
            size="sm"
            onChange={(e) =>
              handleChangeQuestValue("shortDescription", e.target.value)
            }
            value={shortDescription ? shortDescription : ""}
          />
        </Form.Group>

        <Form.Group className="form-group-preview-description">
          {server !== "vRO" ? (
            <Tabs className="mt-3" defaultActiveKey="description">
              <Tab eventKey="description" title="Description">
                <p>{description && renderROHTML(description)}</p>
              </Tab>
              <Tab eventKey="raw" title="Raw">
                <Textarea
                  readOnly
                  rows="5"
                  value={description ? description : ""}
                  className="form-control mb-3"
                />
              </Tab>
            </Tabs>
          ) : (
            <>
              <Form.Label className="col-form-label">Description</Form.Label>
              <p>{description && renderROHTML(description)}</p>
            </>
          )}
        </Form.Group>

        {description && description.includes("<NAVI>")
          ? renderButtonNavigation()
          : null}

        {server !== "vRO" && !notFound ? (
          <div className="text-right">
            <Button
              onClick={handleReplaceData}
              variant="outline-secondary"
              size="sm"
            >
              {`Relace data from ${server}`}
            </Button>
          </div>
        ) : null}

        <SpinnerComponent
          loading={server !== "vRO" && loading}
          position="centered"
          message="Loading quests..."
          backgroundColor="#cccccc"
        ></SpinnerComponent>
      </div>
    </>
  );
};

export default QuestPreview;
