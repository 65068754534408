import React from "react";
import Form from "react-bootstrap/Form";
// import Col from "react-bootstrap/Col";
import { SpinnerComponent } from "react-element-spinner";
import { Column, Table } from "react-virtualized";
import { useScript } from "../../contexts/scriptContext";
import "react-virtualized/styles.css";

const ScriptList = () => {
  const {
    viewScript,
    scriptKeyword,
    scriptLoading,
    scriptResults,
    selectScript,
    setScriptKeyword,
  } = useScript();

  function rowStyleSelected(script) {
    if (typeof script === "undefined" || !viewScript) return;
    if (viewScript.id === script.id) {
      return { background: "#cccccc" };
    }
  }

  return (
    <div className="w-table-list-item">
      <Form.Control
        size="sm"
        type="text"
        placeholder="Quest ID or File name..."
        value={scriptKeyword}
        onChange={(e) => setScriptKeyword(e.target.value)}
      />
      <Table
        className="table-list-item mb-3"
        width={270}
        height={400}
        headerHeight={20}
        rowHeight={30}
        rowCount={scriptResults.length}
        onRowClick={({ index }) => selectScript(scriptResults[index])}
        rowGetter={({ index }) => scriptResults[index]}
        rowStyle={({ index }) => rowStyleSelected(scriptResults[index])}
      >
        <Column label="ID" dataKey="id" width={80} />
        <Column label="Name" dataKey="fileName" width={220} />
      </Table>
      <SpinnerComponent
        loading={scriptLoading}
        position="centered"
        message="Loading scripts..."
      ></SpinnerComponent>
    </div>
  );
};

export default ScriptList;
