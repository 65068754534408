import React, { useState, useCallback, useEffect } from "react";
import axios from "axios";
import * as yup from "yup";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import showToast from "../utils/showToast";

const LoginForm = ({ setUserToken }) => {
  const [captchaToken, setCaptchaToken] = useState("");
  const [user, setUser] = useState({
    email: "",
    password: "",
  });
  const [errors, setErrors] = useState({
    email: "",
    password: "",
  });

  const { executeRecaptcha } = useGoogleReCaptcha();

  const validateSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    password: yup
      .string()
      .required()
      .min(4),
  });

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) return;
    const captchaToken = await executeRecaptcha("loginForm");
    setCaptchaToken(captchaToken);
    console.log(`Captcha token refreshed.`);
  }, [executeRecaptcha]);

  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  function handleChangeUser(field, value) {
    let userCopy = { ...user };
    userCopy[field] = value;
    setUser(userCopy);
  }

  function handleValidate(field) {
    validateSchema
      .validateAt(field, user)
      .then(() => {
        const errorsCopy = { ...errors };
        errorsCopy[field] = "";
        setErrors(errorsCopy);
      })
      .catch((err) => {
        const errorsCopy = { ...errors };
        errorsCopy[field] = err.errors[0];
        setErrors(errorsCopy);
      });
  }

  async function handleLogin(e) {
    e.preventDefault();

    // Validate all fields
    let errorsCopy = { ...errors };
    await validateSchema.validateAt("email", user).catch((err) => {
      errorsCopy.email = err.errors[0];
    });
    await validateSchema.validateAt("password", user).catch((err) => {
      errorsCopy.password = err.errors[0];
    });
    setErrors(errorsCopy);

    const valid = await validateSchema.isValid(user);
    if (!valid) return;

    // All passes
    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/auth/`,
      data: {
        ...user,
        captchaToken,
      },
    })
      .then((res) => {
        setUserToken(res.data);
      })
      .catch((err) => {
        if (typeof err.response === "undefined") {
          showToast("error", err.message);
        } else {
          const { status, data } = err.response;
          if (status === 400) showToast("error", data);
        }

        handleReCaptchaVerify();
      });
  }

  return (
    <Container>
      <Row className="justify-content-md-center pt-5">
        <Col xs lg="4">
          <Form
            id="loginForm"
            noValidate
            validated={null}
            onSubmit={handleLogin}
          >
            <FloatingLabel
              controlId="floatingInput"
              label="Email address"
              className="mb-3"
            >
              <Form.Control
                type="email"
                placeholder="name@example.com"
                value={user.email}
                onChange={(e) => handleChangeUser("email", e.target.value)}
                isInvalid={errors.email !== ""}
                onBlur={() => handleValidate("email")}
              />

              <Form.Control.Feedback type="invalid">
                {errors.email}
              </Form.Control.Feedback>
            </FloatingLabel>

            <FloatingLabel
              className="mb-3"
              controlId="floatingPassword"
              label="Password"
            >
              <Form.Control
                type="password"
                placeholder="Password"
                value={user.password}
                onChange={(e) => handleChangeUser("password", e.target.value)}
                isInvalid={errors.password !== ""}
                onBlur={() => handleValidate("password")}
              />
              <Form.Control.Feedback type="invalid">
                {errors.password}
              </Form.Control.Feedback>
            </FloatingLabel>

            <Button variant="primary" type="submit">
              Login
            </Button>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LoginForm;
