import React from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import Spinner from "react-bootstrap/Spinner";

const OffCanvasTable = ({
  show,
  onHide,
  title,
  count,
  render,
  loading = false,
  placement = "start",
}) => {
  return (
    <>
      <Offcanvas show={show} onHide={onHide} placement={placement}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{title}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          {loading ? <Spinner animation="border" /> : null}
          <h6>Found {count}!</h6>
          {render()}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
};

export default OffCanvasTable;
