import React, { useState, useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import axios from "axios";
import _ from "lodash";
import { useGeneral } from "../../contexts/generalContext";
import { useItem, initItemPreview } from "../../contexts/itemContext";
import ItemPreview from "../../components/item/ItemPreview";
import TextArea from "../common/TextArea";
import ColorButtons from "../common/ColorButtons";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-lua";
import "prismjs/themes/prism.css";
import { useLocalStorageState } from "../../utils/useLocalStorageState";

const ItemPreviewContainer = ({ itemId }) => {
  const { generalDispatch, views, tabActive } = useGeneral();
  const {
    handleChangeValue,
    itemDispatch,
    itemPreviewContainer,
    selectItem,
    isItemChanged,
  } = useItem();
  const activeItem = views.items[tabActive.items];

  const [currentServerTab, setCurrentServerTab] = useLocalStorageState(
    "RODefaultServerTab",
    "iRO"
  );
  const [luaCode, setLuaCode] = useState("");

  const vRO = {
    ...initItemPreview,
    ...activeItem,
    identifiedDescriptionName: activeItem.identifiedDescriptionName
      ? JSON.parse(activeItem.identifiedDescriptionName)
      : "",
    server: "vRO",
  };

  function printDescription(descriptionStringify) {
    if (!descriptionStringify) return;
    const descriptionNameLines = JSON.parse(descriptionStringify).split("\n");

    // Trim end
    let length = descriptionNameLines.length - 1;
    for (let i = length; i > 1; i--) {
      if (descriptionNameLines[i].trim() === "") {
        descriptionNameLines.length--;
      } else {
        break;
      }
    }

    let descriptionName = "";
    _.forEach(descriptionNameLines, (line, index) => {
      if (line.trim() === "") line = "^ffffff_^000000";
      descriptionName += `[=[${line}]=],`;
      if (index < descriptionNameLines.length - 1) descriptionName += "\n\t\t";
    });

    return descriptionName;
  }

  function fetchDataAndSet(server) {
    itemDispatch({ type: "FETCH_ITEM_PREVIEW", payload: { server, itemId } });

    return axios
      .get(
        `https://divine-pride.net/api/database/Item/${itemId}?apiKey=${process.env.REACT_APP_API_KEY_DIVINE_PRIDE}&server=${server}`
      )
      .then((res) => {
        const {
          description,
          name,
          unidName,
          resName,
          unidResName,
          aegisName,
          slots,
          attack: atk,
          matk,
          defense: def,
          weight,
          requiredLevel,
          classNum,
        } = res.data;

        itemDispatch({
          type: "FETCH_ITEM_PREVIEW_SUCCESS",
          payload: {
            [server]: {
              itemId,
              loading: false,
              notFound: false,
              itemCollectionImage: `https://divine-pride.net/img/items/collection/${server}/${itemId}`,
              identifiedDescriptionName: description ? description : "",
              identifiedDisplayName: name ? name : "",
              identifiedResourceName: resName ? resName : "",
              unidentifiedDisplayName: unidName ? unidName : "",
              unidentifiedResourceName: unidResName ? unidResName : "",
              dbName: aegisName ? aegisName : "",
              slots: slots ? slots : 0,
              atk: atk ? atk : 0,
              matk: matk ? matk : 0,
              def: def ? def : 0,
              mdef: 0,
              weight: weight ? weight : 0,
              requiredLevel: requiredLevel ? requiredLevel : 1,
              classNum: classNum ? classNum : 0,
            },
          },
        });
      })
      .catch((e) => {
        if (e.response.status === 404) {
          itemDispatch({
            type: "FETCH_ITEM_PREVIEW_NOT_FOUND",
            payload: {
              [server]: {
                ...initItemPreview,
                notFound: true,
                itemId,
                dbName: `Not found on ${server}`,
                identifiedDescriptionName: `Not found on ${server}`,
                identifiedDisplayName: `Not found on ${server}`,
                identifiedResourceName: `Not found on ${server}`,
                unidentifiedDisplayName: `Not found on ${server}`,
                unidentifiedResourceName: `Not found on ${server}`,
              },
            },
          });
        }
      });
  }

  useEffect(() => {
    const isSameId =
      itemPreviewContainer[currentServerTab]["itemId"] === itemId;
    if (
      isSameId ||
      (isSameId && itemPreviewContainer[currentServerTab]["notFound"])
    )
      return;

    fetchDataAndSet(currentServerTab);
    // eslint-disable-next-line
  }, [currentServerTab, itemId]);

  useEffect(() => {
    if (activeItem.identifiedDescriptionName) {
      const identifiedDescriptionName = printDescription(
        activeItem.identifiedDescriptionName
      );

      const unidentifiedDescriptionName = printDescription(
        activeItem.unidentifiedDescriptionName
      );

      const code = `[${activeItem.itemId}] =
{
\tunidentifiedDisplayName = [=[${activeItem.unidentifiedDisplayName}]=],
\tunidentifiedResourceName = [=[${activeItem.unidentifiedResourceName}]=],
\tunidentifiedDescriptionName =
\t{
\t\t${unidentifiedDescriptionName}
\t},
\tidentifiedDisplayName = [=[${activeItem.identifiedDisplayName}]=],
\tidentifiedResourceName = [=[${activeItem.identifiedResourceName}]=],
\tidentifiedDescriptionName =
\t{
\t\t${identifiedDescriptionName}
\t},
\tslotCount = ${activeItem.slots},
\tClassNum = ${activeItem.classNum},
\tcostume = false,
},
`;
      setLuaCode(code);
    }
    // eslint-disable-next-line
  }, [vRO.identifiedDescriptionName, vRO.slots, vRO.classNum]);

  return (
    <Row>
      <Col className="mb-3 mb-sm-0">
        <div className="col-item-preview">
          <h5>Data from other servers</h5>
          <Tabs
            defaultActiveKey={currentServerTab}
            id=""
            onSelect={setCurrentServerTab}
          >
            {Object.keys(itemPreviewContainer).map((server) => {
              if (server === "vRO") return null;
              return (
                <Tab key={server} eventKey={server} title={server}>
                  <ItemPreview
                    itemData={{ ...itemPreviewContainer[server], server }}
                  />
                </Tab>
              );
            })}
          </Tabs>
          {itemId > 0 ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="external-link"
              href={`https://divine-pride.net/database/item/${itemId}`}
            >
              Open
            </a>
          ) : null}
        </div>
      </Col>

      <Col className="col-preview-vRO">
        <div className="col-item-preview">
          <h5>Data from vRO</h5>
          <div className="mt-5">
            <ItemPreview itemData={vRO} />
          </div>
        </div>
      </Col>

      <Col>
        <div className="mb-3">
          <Tabs
            className="nav-tabs-item-desc"
            defaultActiveKey="identified"
            transition={false}
          >
            <Tab eventKey="identified" title="Identified">
              <Form.Group>
                <TextArea
                  onFocus={() =>
                    generalDispatch({
                      type: "SET_ACTIVED_ELEMENT_ID",
                      payload: `identified-description-name-${itemId}`,
                    })
                  }
                  id={`identified-description-name-${itemId}`}
                  rows="16"
                  value={
                    activeItem && activeItem.identifiedDescriptionName
                      ? JSON.parse(activeItem.identifiedDescriptionName)
                      : ""
                  }
                  onChange={(e) =>
                    handleChangeValue(
                      "identifiedDescriptionName",
                      e.target.value
                    )
                  }
                />
              </Form.Group>
            </Tab>
            <Tab eventKey="unidentified" title="Unidentified">
              <Form.Group>
                <TextArea
                  onFocus={() =>
                    generalDispatch({
                      type: "SET_ACTIVED_ELEMENT_ID",
                      payload: "unidentified-description-name",
                    })
                  }
                  id="unidentified-description-name"
                  rows="16"
                  value={
                    activeItem && activeItem.unidentifiedDescriptionName
                      ? JSON.parse(activeItem.unidentifiedDescriptionName)
                      : ""
                  }
                  onChange={(e) =>
                    handleChangeValue(
                      "unidentifiedDescriptionName",
                      e.target.value
                    )
                  }
                />
              </Form.Group>
            </Tab>
            <Tab eventKey="lua" title="Lua">
              <Editor
                className="lua-parser"
                readOnly={true}
                value={luaCode}
                highlight={() => highlight(luaCode, languages.lua, "lua")}
                padding={10}
                style={{
                  fontFamily: '"Fira code", "Fira Mono", monospace',
                  fontSize: 11,
                }}
              />
            </Tab>
          </Tabs>
        </div>

        <ColorButtons />

        <hr />

        {isItemChanged(itemId) ? (
          <Row className="g-2">
            <Col className="d-grid">
              {!activeItem.isNew ? (
                <Button type="submit" variant="success">
                  Save changes
                </Button>
              ) : (
                <Button type="submit" variant="primary">
                  Add New Item {activeItem.itemId}
                </Button>
              )}
            </Col>
            <Col className="d-grid">
              <Button
                onClick={() => selectItem(activeItem, true)}
                variant="dark"
              >
                Discard changes
              </Button>
            </Col>
          </Row>
        ) : null}
      </Col>
    </Row>
  );
};

export default ItemPreviewContainer;
