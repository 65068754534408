import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _ from "lodash";
import MapDetail from "./MapDetail";

import { useGeneral } from "../../contexts/generalContext";
import { useMap, emptyMap } from "../../contexts/mapContext";

const MapTabs = () => {
  const { views, tabActive, generalDispatch } = useGeneral();
  const { selectMap } = useMap();
  const { maps } = views;
  const activeItem = maps[tabActive.maps];

  function setKey(dbName) {
    console.log(dbName);
    const map = _.find(maps, (map) => map.dbName === dbName);
    if (map) selectMap(map);
  }

  function closeTab(map) {
    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "delete",
        viewCat: "maps",
        value: [emptyMap],
      },
    });
  }

  return activeItem.dbName === "" ? null : (
    <Tabs
      activeKey={activeItem.dbName}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      {maps.map((map) => {
        return (
          <Tab
            key={`map-${map.dbName}`}
            eventKey={map.dbName}
            title={map.dbName}
          >
            <MapDetail map={map} closeTab={closeTab} />
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default MapTabs;
