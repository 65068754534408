import React, { useEffect, useRef } from "react";
import { useHistory, useLocation, Link } from "react-router-dom";

import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
// import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import Alert from "react-bootstrap/Alert";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Spinner from "react-bootstrap/Spinner";

import ItemSingleDetail from "./ItemSingleDetail";
import { useItem } from "../../contexts/itemContext";
import showToast from "../../utils/showToast";

const Item = () => {
  const {
    singleItem,
    fetchItem,
    fetchMonsterDropByItemId,
    itemDispatch,
    searchByKeyword,
    itemTypeIdToName,
  } = useItem();

  const {
    itemId,
    searched,
    searchLoading,
    searchKeyword,
    searchKeyTab,
    searchedMessage,
  } = singleItem;

  const searchInputRef = useRef(null);
  const history = useHistory();
  const location = useLocation();

  // Fetch item
  useEffect(() => {
    history.listen((location, action) => {
      if (action === "POP") {
        console.log(location);
      }
    });

    const regexItemId = /\/item\/([0-9]*)/g;
    const matches = window.location.href.match(regexItemId);
    if (matches) {
      const itemId = matches[0].replace("/item/", "");
      if (itemId > 0) getItemWithId(itemId);
    } else {
      searchInputRef.current.focus();
    }

    const queryParams = new URLSearchParams(location.search);
    const keyword = queryParams.get("s");
    if (keyword && keyword.trim().length > 0) {
      itemDispatch({ type: "SINGLE_ITEM_SET_KEYWORD", payload: keyword });
      startSearch(keyword);
    }
    // eslint-disable-next-line
  }, []);

  // Fetch drops
  useEffect(() => {
    if (itemId < 0) return;
    getDropsByItemId(itemId);
    // eslint-disable-next-line
  }, [itemId]);

  async function getItemWithId(itemId) {
    itemDispatch({ type: "SINGLE_FETCH_ITEM" });
    const data = await fetchItem(itemId);
    if (data)
      itemDispatch({ type: "SINGLE_FETCH_ITEM_SUCCESS", payload: data });
  }

  async function getDropsByItemId(itemId) {
    itemDispatch({ type: "SINGLE_FETCH_DROPS" });
    const data = await fetchMonsterDropByItemId(itemId);
    if (data)
      itemDispatch({ type: "SINGLE_FETCH_DROPS_SUCCESS", payload: data });
  }

  async function preSearch(e) {
    e.preventDefault();
    if (searchLoading) return;
    if (searchKeyword.trim().length === 0) {
      searchInputRef.current.focus();
      return showToast("warning", "Điền từ khóa!");
    }

    // set query to url
    history.push(`?s=${searchKeyword}`);

    startSearch(searchKeyword);
  }

  async function startSearch(keyword) {
    itemDispatch({ type: "SINGLE_SEARCH_ITEMS" });
    const result = await searchByKeyword(keyword);
    if (result)
      return itemDispatch({
        type: "SINGLE_SEARCH_ITEMS_SUCCESS",
        payload: result,
      });

    itemDispatch({ type: "SINGLE_SEARCH_ITEMS_FAILED" });
    searchInputRef.current.focus();
  }

  async function itemResultClick({ itemId }) {
    await getItemWithId(itemId);
  }

  function handleChangeItemKeyword(value) {
    itemDispatch({ type: "SINGLE_ITEM_SET_KEYWORD", payload: value });
  }

  function setKeyTab(keyTab) {
    if (keyTab !== searchKeyTab)
      itemDispatch({ type: "SINGLE_ITEM_SET_TAB_RESULT", payload: keyTab });
  }

  function tabTitle(iconUrl, dbName, title) {
    return (
      <div className="d-flex align-items-center text-start w-item-name">
        <div className="w-item-icon me-2">
          <img src={iconUrl.toLowerCase()} alt={`${title} icon`} />
        </div>
        <div>
          <h3>{title}</h3>
          <code>
            {itemId} {dbName}
          </code>
        </div>
      </div>
    );
  }

  return (
    <Container id="single-item-container">
      <Row>
        <Col md={3}>
          <Form onSubmit={preSearch}>
            <InputGroup className="mb-3">
              <Form.Control
                placeholder="Jellopy..."
                aria-label="Item Search"
                aria-describedby="item-search-button"
                value={searchKeyword}
                onChange={(e) => handleChangeItemKeyword(e.target.value)}
                ref={searchInputRef}
              />
              <Button
                type="submit"
                variant="outline-secondary"
                id="item-search-button"
                disabled={searchLoading}
              >
                {searchLoading ? (
                  <Spinner size="sm" animation="border" role="status">
                    <span className="visually-hidden">Searching...</span>
                  </Spinner>
                ) : (
                  "Search"
                )}
              </Button>
            </InputGroup>
          </Form>
        </Col>
      </Row>

      {searchedMessage !== "" ? (
        <Alert variant="secondary" className="d-inline-block">
          {searchedMessage}
        </Alert>
      ) : null}

      {itemId !== -1 || searched.length > 0 ? (
        <Tabs
          className="single-item-tabs"
          activeKey={searchKeyTab}
          onSelect={(k) => setKeyTab(k)}
        >
          {searched.length > 0 ? (
            <Tab eventKey="result" title={`Result (${searched.length})`}>
              <Table>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Type</th>
                  </tr>
                </thead>
                <tbody>
                  {searched.map((item, index) => (
                    <tr key={`${index}-drops-${item.itemId}`}>
                      <td>{item.itemId}</td>
                      <td>
                        <div className="d-flex align-items-center">
                          <div className="w-item-icon me-1">
                            <img
                              src={item.itemIconImage.toLowerCase()}
                              alt=""
                            />
                          </div>
                          <Link
                            to={`/item/${item.itemId}`}
                            onClick={() => itemResultClick(item)}
                          >
                            {item.identifiedDisplayName}{" "}
                            {item.slots > 0 ? `[${item.slots}]` : ""}
                          </Link>
                        </div>
                      </td>
                      <td>{itemTypeIdToName(item.typeId)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Tab>
          ) : null}

          {itemId !== -1 ? (
            <Tab
              eventKey={singleItem.dbName}
              title={tabTitle(
                singleItem.itemIconImage,
                singleItem.dbName,
                singleItem.identifiedDisplayName
              )}
            >
              <ItemSingleDetail item={singleItem} />
            </Tab>
          ) : null}
        </Tabs>
      ) : null}
    </Container>
  );
};

export default Item;
