import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _ from "lodash";
import QuestDetail from "./QuestDetail";

import { useGeneral } from "../../contexts/generalContext";
import { useQuest, emptyQuest } from "../../contexts/questContext";

const QuestTabs = () => {
  const { views, tabActive, generalDispatch, clearHistories } = useGeneral();
  const { selectQuest } = useQuest();
  const { quests } = views;
  const activeItem = quests[tabActive.quests];

  function setKey(id) {
    const quest = _.find(quests, (quest) => quest.id === id);
    if (quest) selectQuest(quest);
  }

  function closeTab(quest) {
    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "delete",
        viewCat: "quests",
        value: [emptyQuest],
      },
    });
    clearHistories("quests", quest.id);
  }

  return activeItem.id === -1 ? null : (
    <Tabs
      activeKey={activeItem.id}
      onSelect={(k) => setKey(Number(k))}
      className="mb-3"
    >
      {quests.map((quest) => {
        return (
          <Tab key={`quest-${quest.id}`} eventKey={quest.id} title={quest.id}>
            <QuestDetail quest={quest} closeTab={closeTab} />
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default QuestTabs;
