import React, { useReducer, useRef } from "react";
import _ from "lodash";
import axios from "axios";
import { useUser } from "../contexts/userContext";
import { useLocalStorageState } from "../utils/useLocalStorageState";
import { useHistory } from "react-router-dom";
import showToast from "../utils/showToast";
import Swal from "sweetalert2";
import exportFile from "../utils/exportFile";
import { useGeneral } from "../contexts/generalContext";
import removeAccents from "../utils/removeAccents";
import noImg from "../images/noimg.jpg";
import { undefinedDisplayNameTranslator, isNumeric } from "../utils/textUtils";

const jobOptions = [
  { value: 0xffffffff, label: "All jobs" },
  { value: 0xfffffffe, label: "All jobs except Novice" },
  { value: 0x00000001, label: "Novice" },
  { value: 0x00000002, label: "Swordsman" },
  { value: 0x00000004, label: "Magician" },
  { value: 0x00000008, label: "Archer" },
  { value: 0x00000010, label: "Acolyte" },
  { value: 0x00000020, label: "Merchant" },
  { value: 0x00000040, label: "Thief" },
  { value: 0x00000080, label: "Knight" },
  { value: 0x00000100, label: "Priest" },
  { value: 0x00000200, label: "Wizard" },
  { value: 0x00000400, label: "Blacksmith" },
  { value: 0x00000800, label: "Hunter" },
  { value: 0x00001000, label: "Assassin" },
  { value: 0x00004000, label: "Crusader" },
  { value: 0x00008000, label: "Monk" },
  { value: 0x00010000, label: "Sage" },
  { value: 0x00020000, label: "Rogue" },
  { value: 0x00040000, label: "Alchemist" },
  { value: 0x00080000, label: "Bard/Dancer" },
  { value: 0x00200000, label: "Taekwon" },
  { value: 0x00400000, label: "Star Gladiator" },
  { value: 0x00800000, label: "Soul Linker" },
  { value: 0x01000000, label: "Gunslinger" },
  { value: 0x02000000, label: "Ninja" },
  { value: 0x20000000, label: "Kagerou/Oboro" },
  { value: 0x40000000, label: "Rebellion" },
  { value: 0x80000000, label: "Summoner" },
];

const locationOptions = [
  { value: 1, label: "Lower" },
  { value: 2, label: "Weapon" },
  { value: 4, label: "Garment" },
  { value: 8, label: "Accessory Right" },
  { value: 16, label: "Armor" },
  { value: 32, label: "Shield" },
  { value: 34, label: "Two-handed Weapon" },
  { value: 64, label: "Footgear" },
  { value: 128, label: "Accessory Left" },
  { value: 136, label: "Accessory (Both)" },
  { value: 256, label: "Upper" },
  { value: 512, label: "Middle" },
  { value: 769, label: "Upper + Middle + Lower" },
  { value: 1024, label: "Costume Upper" },
  { value: 2048, label: "Costume Middle" },
  { value: 3072, label: "Costume [Upper + Middle]" },
  { value: 4096, label: "Costume Lower" },
  { value: 6144, label: "Costume [Middle + Lower]" },
  { value: 7168, label: "Costume [Upper + Middle + Lower]" },
  { value: 8192, label: "Costume Garment" },
  { value: 32768, label: "Ammo" },
  { value: 65536, label: "Shadow Armor" },
  { value: 131072, label: "Shadow Weapon" },
  { value: 262144, label: "Shadow Shield" },
  { value: 524288, label: "Shadow Shoes" },
  { value: 1048576, label: "Shadow Accessory Right (Earring)" },
  { value: 2097152, label: "Shadow Accessory Left (Pendant)" },
];

const emptyItem = {
  id: "",
  itemId: -1,
  dbName: "",
  identifiedDisplayName: "",
  unidentifiedDisplayName: "",
  identifiedResourceName: "",
  unidentifiedResourceName: "",
  identifiedDescriptionName: JSON.stringify(""),
  unidentifiedDescriptionName: JSON.stringify(""),
  itemCollectionImage: noImg,
  typeId: 0,
  def: 0,
  atk: 0,
  matk: 0,
  weight: 0,
  requiredLevel: 0,
  slots: 0,
  job: 0,
  jobs: [],
  attribute: 0,
  location: 0,
  classNum: 0,
  gender: 0,
  refineable: false,
  classGroup: 0,
  excludeFromExport: false,
  tagIds: [],
  tags: [],
};

const emptySingleItem = {
  ...emptyItem,
  drops: [],
  itemLoading: false,
  dropLoading: false,
  searchLoading: false,
  searched: [],
  searchedMessage: "",
  searchKeyword: "",
  searchKeyTab: "result", // default result tab
};

const initItemPreview = {
  id: "",
  itemId: -1,
  server: "",
  loading: false,
  identifiedDescriptionName: "",
  identifiedDisplayName: "",
  identifiedResourceName: "",
  unidentifiedDisplayName: "",
  unidentifiedResourceName: "",
  itemCollectionImage: noImg,
  dbName: "",
  slots: 0,
  atk: 0,
  matk: 0,
  def: 0,
  mdef: 0,
  weight: 0,
  requiredLevel: 1,
  classNum: 0,
  excludeFromExport: false,
  tagIds: [],
  notFound: false,
  tags: [],
};

const ItemContext = React.createContext();

const itemMainReducer = (state, action) => {
  let item = {};
  let copyItems = [];
  let newNotSavedItems = [];
  let newItemResults = [];

  switch (action.type) {
    case "HANDLE_ITEM_CONTAIN":
      return { ...state, showOffItemContainCanvas: action.payload };
    case "FETCH_MONSTERS_DROP":
      return {
        ...state,
        monstersDropsCanvas: {
          state: "loading",
          showOff: true,
        },
      };
    case "FETCH_MONSTERS_DROP_SUCCESS":
      return {
        ...state,
        monstersDropsCanvas: {
          ...state.monstersDropsCanvas, // keep current showOff
          state: action.payload.drops.length === 0 ? "notFound" : "idle",
        },
        items: [...action.payload.items],
      };
    case "FETCH_MONSTERS_DROP_FAILED":
      return { ...state };
    case "HIDE_MONSTERS_DROP":
      return {
        ...state,
        monstersDropsCanvas: {
          state: "idle",
          showOff: false,
        },
      };
    case "ITEM_LIST_LOADING":
      return { ...state, itemListLoading: action.isLoading };
    case "FETCH_ITEMS_SUCCESS":
      return {
        ...state,
        items: action.payload,
        itemResults: action.payload,
        itemListLoading: false,
      };
    case "SET_ITEM_TO_LIST":
      item = action.payload;

      // Remove item not saved
      newNotSavedItems = [
        ...state.notSavedItems.filter(
          (notSavedItem) => notSavedItem.itemId !== item.itemId
        ),
      ];

      // update original list
      const indexThisItem = _.findIndex(
        state.items,
        (oItem) => oItem.itemId === item.itemId
      );
      copyItems = [...state.items];
      copyItems[indexThisItem] = _.omit(item, ["isNew"]);

      // update search results
      const indexThisItemFromSearchResults = _.findIndex(
        state.itemResults,
        (rItem) => rItem.itemId === item.itemId
      );
      newItemResults = [...state.itemResults];
      newItemResults[indexThisItemFromSearchResults] = _.omit(item, ["isNew"]);

      return {
        ...state,
        notSavedItems: newNotSavedItems,
        itemResults: newItemResults,
        items: copyItems,
      };
    case "SET_ITEMS":
      return { ...state, items: action.payload };
    case "SET_ITEM_RESULTS":
      return { ...state, itemResults: action.results };
    case "DELETE_ITEM_SUCCESS":
      copyItems = [
        ...state.items.filter((item) => item.itemId !== action.payload.itemId),
      ];
      newItemResults = [
        ...state.itemResults.filter(
          (item) => item.itemId !== action.payload.itemId
        ),
      ];
      newNotSavedItems = [
        ...state.notSavedItems.filter(
          (notSavedItem) => notSavedItem.itemId !== action.payload.itemId
        ),
      ];
      return {
        ...state,
        itemResults: newItemResults,
        notSavedItems: newNotSavedItems,
        items: copyItems,
      };
    case "HANDLE_ITEM_LIST":
      return { ...state, hideItemList: action.isHide };
    case "SET_ITEM_KEYWORD":
      return { ...state, itemKeyword: action.payload };
    case "PRE_NEW_ITEM":
      return { ...state, ...action.payload };
    case "FETCH_ITEM_PREVIEW":
      const servers = { ...state.itemPreviewContainer };
      return {
        ...state,
        itemPreviewContainer: {
          ...servers,
          [action.payload.server]: {
            ...servers[action.payload.server],
            itemId: action.payload.itemId,
            loading: true,
          },
        },
      };
    case "FETCH_ITEM_PREVIEW_SUCCESS":
      return {
        ...state,
        itemPreviewContainer: {
          ...state.itemPreviewContainer, // servers
          ...action.payload,
        },
      };
    case "FETCH_ITEM_PREVIEW_NOT_FOUND":
      return {
        ...state,
        itemPreviewContainer: {
          ...state.itemPreviewContainer, // servers
          ...action.payload,
        },
      };
    case "SET_NOT_SAVED_ITEMS":
      return {
        ...state,
        notSavedItems: [...action.payload],
      };
    case "REMOVE_NOT_SAVED_ITEM":
      const newNotSavedItems2 = state.notSavedItems.filter(
        (notSavedItem) => notSavedItem.itemId !== action.payload.itemId
      );

      return {
        ...state,
        notSavedItems: newNotSavedItems2,
      };
    case "SET_ITEM_FILTERS":
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case "CLEAR_ITEM_FILTERS":
      return {
        ...state,
        filters: { typeId: "-1", tagId: "-1" },
        itemKeyword: "",
      };
    case "SINGLE_FETCH_ITEM":
      return {
        ...state,
        singleItem: {
          ...state.singleItem,
          emptySingleItem,
          itemLoading: true,
        },
      };
    case "SINGLE_FETCH_ITEM_SUCCESS":
      return {
        ...state,
        singleItem: {
          ...state.singleItem,
          ...action.payload,
          itemLoading: false,
          searchKeyTab: action.payload.dbName,
        },
      };
    case "SINGLE_FETCH_DROPS":
      return {
        ...state,
        singleItem: {
          ...state.singleItem,
          drops: [],
          dropLoading: true,
        },
      };
    case "SINGLE_FETCH_DROPS_SUCCESS":
      return {
        ...state,
        singleItem: {
          ...state.singleItem,
          drops: action.payload,
          dropLoading: false,
        },
      };
    case "SINGLE_SEARCH_ITEMS":
      return {
        ...state,
        singleItem: {
          ...state.singleItem,
          searchLoading: true,
          searchedMessage: "",
        },
      };
    case "SINGLE_SEARCH_ITEMS_SUCCESS":
      return {
        ...state,
        singleItem: {
          ...state.singleItem,
          searchLoading: false,
          searched: action.payload,
          searchedMessage:
            action.payload.length === 0
              ? `Không tìm thấy vật phẩm nào có chứa từ khóa "${state.singleItem.searchKeyword}"`
              : "",
          searchKeyTab: "result",
        },
      };
    case "SINGLE_SEARCH_ITEMS_FAILED":
      return {
        ...state,
        singleItem: {
          ...state.singleItem,
          searchLoading: false,
        },
      };
    case "SINGLE_ITEM_SET_KEYWORD":
      return {
        ...state,
        singleItem: {
          ...state.singleItem,
          searchKeyword: action.payload,
        },
      };
    case "SINGLE_ITEM_SET_TAB_RESULT":
      return {
        ...state,
        singleItem: {
          ...state.singleItem,
          searchKeyTab: action.payload,
        },
      };
    default:
      return state;
  }
};

const ItemProvider = (props) => {
  const [itemMain, itemDispatch] = useReducer(itemMainReducer, {
    items: [],
    itemResults: [],
    itemListLoading: false,
    showOffItemContainCanvas: false,
    monstersDropsCanvas: {
      state: "idle",
      showOff: false,
    },
    hideItemList: false,
    itemKeyword: "",
    filters: {
      typeId: "-1",
      tagId: "-1",
    },
    itemPreviewContainer: {
      iRO: initItemPreview,
      kRO: initItemPreview,
      twRO: initItemPreview,
      jRO: initItemPreview,
      thRO: initItemPreview,
    },
    notSavedItems: [],

    containWillInsert: {
      containType: "random1",
      containId: "",
      containChance: 100,
      containQuantity: 1,
    },
    singleItem: emptySingleItem,
  });

  const {
    items,
    itemResults,
    itemListLoading,
    showOffItemContainCanvas,
    monstersDropsCanvas,
    hideItemList,
    itemKeyword,
    itemPreviewContainer,
    notSavedItems,
    filters,
    containWillInsert,
    singleItem,
  } = itemMain;

  const [resourceNameKorean, setResourceNameKorean] = useLocalStorageState(
    "resourceNameKorean",
    true
  );

  const { userToken, setUserToken } = useUser();
  const { general, generalDispatch, getHistories, clearHistories } =
    useGeneral();
  const history = useHistory();
  const itemFormRef = useRef(null);

  const { views, viewName, tabActive } = general;
  const activeItem = views.items[tabActive.items];

  function isItemChanged(itemId) {
    return Boolean(
      _.findIndex(
        notSavedItems,
        (notSavedItem) => notSavedItem.itemId === itemId
      ) > -1
    );
  }

  function addToNotSaved(item) {
    if (notSavedItems.length === 0) {
      return itemDispatch({
        type: "SET_NOT_SAVED_ITEMS",
        payload: [item],
      });
    }

    const index = _.findIndex(items, (oItem) => oItem.itemId === item.itemId);

    // is update
    if (index > -1) {
      // no changes, take it out of the variable 'notSavedItems'
      if (_.isEqual(items[index], item)) {
        const newNotSavedItems = notSavedItems.filter(
          (notSavedItem) => notSavedItem.itemId !== item.itemId
        );
        itemDispatch({
          type: "SET_NOT_SAVED_ITEMS",
          payload: newNotSavedItems,
        });
      }
      // is change
      else {
        const indexNotSavedItem = _.findIndex(
          notSavedItems,
          (nItem) => nItem.itemId === item.itemId
        );

        // Add new to 'notSavedItems'
        if (indexNotSavedItem === -1) {
          itemDispatch({
            type: "SET_NOT_SAVED_ITEMS",
            payload: [...notSavedItems, item],
          });
        }
        // update existed not saved item in 'notSavedItems'
        else {
          let copyNotSavedItems = [...notSavedItems];
          copyNotSavedItems[indexNotSavedItem] = item;

          itemDispatch({
            type: "SET_NOT_SAVED_ITEMS",
            payload: copyNotSavedItems,
          });
        }
      }
    }
  }

  function handleChangeValue(field, value) {
    let itemCopy = _.cloneDeep(activeItem);
    if (
      field === "identifiedDescriptionName" ||
      field === "unidentifiedDescriptionName"
    ) {
      // Characters not allowed
      if (value === "<" || value === ">") return;

      value = JSON.stringify(value);
    } else if (field === "classGroup") {
      if (value) {
        value = 16;
      } else {
        value = 0;
      }
    } else if (field === "dbName") {
      value = value.replace(" ", "_");
    }

    itemCopy[field] = value;

    // Save to notSavedItems
    addToNotSaved(itemCopy);

    generalDispatch({
      type: "SET_VIEWS",
      payload: { viewType: "active", viewCat: "items", value: itemCopy },
    });
  }

  function handleJobs(selectedJobs) {
    let itemCopy = addToNotSaved(activeItem);

    // check options has all classes case
    const isAllClasses = Boolean(
      selectedJobs.filter((j) => j.value === 0xffffffff).length
    );

    // check options has all classes except Novice
    const isAllClassesExceptNovice = Boolean(
      selectedJobs.filter((j) => j.value === 0xfffffffe).length
    );

    if (isAllClasses) {
      const recentJobs = selectedJobs[selectedJobs.length - 1];
      itemCopy.jobs = [recentJobs];
    } else if (isAllClassesExceptNovice) {
      const recentJobs = selectedJobs[selectedJobs.length - 1];
      itemCopy.jobs = [recentJobs];
    } else {
      itemCopy.jobs = [...selectedJobs];
    }

    let job = 0;
    _.forEach(itemCopy.jobs, (j) => {
      if (j.value === 0xffffffff) return (job = j.value);
      else if (j.value === 0xfffffffe) return (job = j.value);
      job |= Number(j.value);
    });

    itemCopy.job = job;
    generalDispatch({
      type: "SET_VIEWS",
      payload: { viewType: "active", viewCat: "items", value: itemCopy },
    });
    //updateOriginalList("item", itemCopy);
  }

  function handleSubmitItem(e) {
    e.preventDefault();

    // Tạo một đối tượng mới chỉ với các trường cần thiết
    const data = {
      itemId: activeItem.itemId,
      dbName: activeItem.dbName,
      identifiedDisplayName: activeItem.identifiedDisplayName,
      unidentifiedDisplayName: activeItem.unidentifiedDisplayName,
      identifiedResourceName: activeItem.identifiedResourceName,
      unidentifiedResourceName: activeItem.unidentifiedResourceName,
      identifiedDescriptionName: activeItem.identifiedDescriptionName,
      unidentifiedDescriptionName: activeItem.unidentifiedDescriptionName,
      typeId: activeItem.typeId,
      weight: activeItem.weight,
      slots: activeItem.slots,
      classNum: activeItem.classNum,
      excludeFromExport: activeItem.excludeFromExport,
      tagIds: activeItem.tags.map((t) => t.id), // Chỉ lấy id của tags
    };

    axios({
      method: activeItem.isNew ? "post" : "put",
      url: `${process.env.REACT_APP_API}/items/${
        activeItem.isNew ? "" : data.itemId
      }`,
      headers: { "x-auth-token": userToken },
      data,
    })
      .then(({ data: item }) => {
        if (activeItem.isNew) {
          showToast(
            "success",
            `Added a new item id ${item.itemId} successfully!`
          );
        } else {
          showToast("success", `Update ${item.dbName} successfully!`);
        }

        itemDispatch({ type: "SET_ITEM_TO_LIST", payload: item });
        generalDispatch({
          type: "SET_VIEWS",
          payload: {
            viewType: "active",
            viewCat: "items",
            value: item,
          },
        });

        // reload histories
        getHistories(viewName, item);
      })
      .catch((error) => {
        showToast("error", error.response.data);
      });
  }

  function handleSubmitContain(e) {
    e.preventDefault();
    let index = -1;
    const { containId, containChance, containQuantity } = containWillInsert;
    let contain = { ...containWillInsert };

    // Is this a dbName
    if (!isNumeric(containId)) {
      index = _.findIndex(items, (item) => item.dbName === containId);
      if (index === -1) {
        return Swal.fire({
          icon: "error",
          title: `Please try again`,
          text: `Item with dbName "${containId}" was not found!`,
        });
      }

      contain.containId = items[index].itemId;
    } else {
      // Validate
      if (containId === "" || containId < 501) {
        return Swal.fire({
          icon: "error",
          title: `Please try again!`,
          text: `Item ID must be numberic and greater than 501`,
        });
      }

      // Check existing item
      const index2 = _.findIndex(
        items,
        (item) => item.itemId === Number(containId)
      );
      if (index2 === -1) {
        return Swal.fire({
          icon: "error",
          title: `Please try again`,
          text: `Item with id "${containId}" was not found!`,
        });
      }
    }

    // Validate
    if (containChance === "" || containChance < 1) {
      return Swal.fire({
        icon: "error",
        title: `Please try again!`,
        text: `Chance must be numberic and greater than 0`,
      });
    }

    if (containQuantity === "" || containQuantity < 1) {
      return Swal.fire({
        icon: "error",
        title: `Please try again!`,
        text: `Quantity must be numberic and greater than 0`,
      });
    }

    contain = {
      itemId: activeItem.itemId,
      ...contain,
    };

    axios({
      method: "post",
      url: `${process.env.REACT_APP_API}/items/item-contain`,
      headers: { "x-auth-token": userToken },
      data: contain,
    })
      .then(({ data }) => {
        showToast(
          "success",
          `Insert "${containId}" successfully!`,
          "bottom-left"
        );
        generalDispatch({
          type: "SET_VIEWS",
          payload: { viewType: "active", viewCat: "items", value: data },
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error from server",
          text: error.response.data,
        });
      });
  }

  function handleEmptyContain() {
    Swal.fire({
      title: `Remove item contain in ${activeItem.dbName}?`,
      showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        axios({
          method: "delete",
          url: `${process.env.REACT_APP_API}/items/item-contain/${activeItem.itemId}`,
          headers: { "x-auth-token": userToken },
        })
          .then(({ data }) => {
            showToast(
              "success",
              `Contain of ${activeItem.dbName} removed!`,
              "bottom-left"
            );
            generalDispatch({
              type: "SET_VIEWS",
              payload: { viewType: "active", viewCat: "items", value: data },
            });
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Error from server",
              text: error.response.data,
            });
          });
      }
    });
  }

  function handleNewItem() {
    Swal.fire({
      title: "Item ID?",
      input: "number",
      showCancelButton: true,
      confirmButtonText: "Add",
      preConfirm: (itemId) => {
        if (itemId < 1) {
          showToast("warning", `Item ID is not valid.`);
          Swal.close();
        }
        const index = _.findIndex(items, (i) => {
          return i.itemId === Number(itemId);
        });
        if (index >= 0) {
          showToast("warning", `Item with id ${itemId} already exists.`);
          Swal.close();
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const itemId = Number(result.value);
        const tempItem = {
          ...emptyItem,
          itemId,
          identifiedDisplayName: `New Item ${itemId}`,
          unidentifiedDisplayName: `New Item ${itemId}`,
          dbName: `New_Item_${itemId}`,
          isNew: true,
          excludeFromExport: false,
          unidentifiedDescriptionName: JSON.stringify(
            `Vật phẩm chưa được giám định, bạn cần dùng ^0000FFMagnifier (Kính lúp)^000000 để nhận dạng.`
          ),
        };
        itemDispatch({
          type: "PRE_NEW_ITEM",
          payload: {
            items: [tempItem, ...items],
            itemKeyword: tempItem.itemId,
          },
        });

        selectItem(tempItem);
        clearHistories("items", itemId);
      }
    });
  }

  function handleExportItemInfo() {
    exportFile("items", userToken);
  }

  function selectItem(item, removeNotSaved = false) {
    if (typeof item === "undefined")
      return showToast("error", "Item is undefined!");

    let itemFull = null;
    let indexNotSavedItem = -1;

    if (removeNotSaved) {
      itemDispatch({ type: "REMOVE_NOT_SAVED_ITEM", payload: item });
    } else {
      indexNotSavedItem = _.findIndex(
        notSavedItems,
        (notSavedItem) => notSavedItem.itemId === item.itemId
      );
    }

    if (indexNotSavedItem > -1) {
      // set state to view
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "active",
          viewCat: "items",
          value: { ...notSavedItems[indexNotSavedItem] },
        },
      });

      // get item histories
      getHistories(viewName, item);
    } else {
      // set state to view
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "active",
          viewCat: "items",
          value: {
            ...emptyItem,
            ...item,
            loading: true,
          },
        },
      });

      // Fetch vRO data for this item
      if (!item.isNew) {
        axios({
          url: `${process.env.REACT_APP_API}/items/${item.itemId}`,
          method: "GET",
          headers: { "x-auth-token": userToken },
        }).then((res) => {
          itemFull = {
            ...item,
            ...res.data,
            loading: false,
          };

          // set state to view
          generalDispatch({
            type: "SET_VIEWS",
            payload: {
              viewType: "active",
              viewCat: "items",
              value: itemFull,
            },
          });

          // item preview
          itemDispatch({
            type: "FETCH_ITEM_PREVIEW_SUCCESS",
            payload: {
              vRO: {
                ...itemFull,
                itemId: item.itemId,
                notFound: false,
                identifiedDescriptionName: JSON.parse(
                  itemFull.identifiedDescriptionName
                ),
                unidentifiedDescriptionName: JSON.parse(
                  itemFull.unidentifiedDescriptionName
                ),
              },
            },
          });

          itemDispatch({ type: "SET_ITEM_TO_LIST", payload: itemFull });

          // get item histories
          getHistories(viewName, itemFull);
        });
      }
    }

    history.replace({ pathname: `/admin/items/${item.itemId}` });
  }

  function replaceItemDataFrom(itemData) {
    const newItemData = {
      ...activeItem,
      ...itemData,
      identifiedDisplayName: itemData.identifiedDisplayName.replace(
        /\s*\[\d\]/g,
        ""
      ),
      unidentifiedDisplayName: undefinedDisplayNameTranslator(
        itemData.unidentifiedDisplayName
      ),
    };
    generalDispatch({
      type: "SET_VIEWS",
      payload: { viewType: "active", viewCat: "items", value: newItemData },
    });
    addToNotSaved(newItemData);
  }

  function handleDeleteItem(item) {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const itemId = item.itemId;

        if (item.isNew) {
          const newItems = items.filter((item) => item.itemId !== itemId);

          itemDispatch({
            type: "PRE_NEW_ITEM",
            payload: {
              items: newItems,
              newItemResults: newItems,
              itemKeyword: "",
            },
          });

          return generalDispatch({
            type: "SET_VIEWS",
            payload: {
              viewType: "delete",
              viewCat: "items",
              value: item,
            },
          });
        }

        axios({
          method: "DELETE",
          url: `${process.env.REACT_APP_API}/items/${itemId}`,
          headers: { "x-auth-token": userToken },
        }).then((res) => {
          showToast("success", `Deleted ${viewName} ${itemId} successfully!`);
          itemDispatch({ type: "DELETE_ITEM_SUCCESS", payload: item });
          generalDispatch({
            type: "SET_VIEWS",
            payload: {
              viewType: "delete",
              viewCat: "items",
              value: [item],
            },
          });
          clearHistories("items", itemId);
        });
      }
    });
  }

  function fetchAllItems() {
    itemDispatch({ type: "ITEM_LIST_LOADING", isLoading: true });
    generalDispatch({
      type: "SET_VIEWS",
      payload: { viewType: "init", viewCat: "items", value: [emptyItem] },
    });

    // List of items
    axios({
      url: `${process.env.REACT_APP_API}/items`,
      method: "GET",
      headers: { "x-auth-token": userToken },
    })
      .then((res) => {
        itemDispatch({ type: "FETCH_ITEMS_SUCCESS", payload: res.data });
      })
      .catch((error) => {
        if (
          typeof error.response !== "undefined" &&
          error.response.status === 401
        ) {
          Swal.fire({
            icon: "error",
            title: error.response.statusText,
            text: error.response.data,
            confirmButtonText: `Login`,
          }).then(() => {
            setUserToken("");
          });
        } else {
          Swal.fire({
            icon: "error",
            title: error.name,
            text: error.message,
          });
        }
      });
  }

  async function fetchItem(itemId) {
    try {
      const response = await axios({
        url: `${process.env.REACT_APP_API}/items/${itemId}`,
        method: "GET",
      });
      const { data } = response;
      return data;
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async function fetchMonsterDropByItemId(itemId) {
    const response = await axios({
      method: "get",
      url: `${process.env.REACT_APP_API}/monsters/drops/${itemId}`,
    });

    const { data } = response;
    return data;
  }

  function fetchItemMonstersDrops() {
    const item = { ...activeItem };
    itemDispatch({ type: "FETCH_MONSTERS_DROP" });

    if (typeof item["drops"] === "undefined") {
      axios({
        method: "get",
        url: `${process.env.REACT_APP_API}/monsters/drops/${item.id}`,
      })
        .then(({ data: drops }) => {
          const indexThisItem = _.findIndex(
            items,
            (oItem) => oItem.id === item.id
          );
          let copyItems = [...items];
          copyItems[indexThisItem] = item;

          itemDispatch({
            type: "FETCH_MONSTERS_DROP_SUCCESS",
            payload: { items: copyItems, drops },
          });

          generalDispatch({
            type: "SET_VIEWS",
            payload: {
              viewType: "active",
              viewCat: "items",
              value: { ...item, drops },
            },
          });
        })
        .catch((e) => {
          itemDispatch({
            type: "FETCH_MONSTERS_DROP_FAILED",
          });
        });
    } else {
      itemDispatch({
        type: "FETCH_MONSTERS_DROP_SUCCESS",
        payload: { items, drops: item["drops"] },
      });
    }
  }

  function searchItems(keyword) {
    let results = [...items];

    // Filter by typeId
    if (filters.typeId !== "-1") {
      results = items.filter((item) => item.typeId === Number(filters.typeId));
    }

    // Filter by tagId
    if (filters.tagId !== "-1") {
      results = results.filter((item) => {
        return item.tagIds.some((tagId) => {
          return tagId === filters.tagId;
        });
      });
    }

    if (keyword !== "") {
      const normalizeKeyword = removeAccents(
        keyword.toString().trim().toLowerCase()
      );

      // Search by id, identifiedDisplayName and dbName
      const searchFields = ["itemId", "identifiedDisplayName", "dbName"];

      let searchResults = searchFields.reduce((acc, field) => {
        const filteredItems = results.filter((item) => {
          const normalizedItemField = removeAccents(
            item[field]?.toString() || ""
          ).toLowerCase();
          return normalizedItemField.includes(normalizeKeyword);
        });
        return [...acc, ...filteredItems];
      }, []);

      // Remove duplicate results
      results = [...new Set(searchResults)];
    }
    return results;
  }

  async function searchByKeyword(keyword) {
    const string = keyword.trim();

    if (string.length < 3)
      return showToast("warning", "Từ khóa phải hơn 2 kí tự.");

    let conditions = {
      keyword: string,
    };

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API}/items/search/`,
        {
          params: conditions,
        }
      );
      return response.data;
    } catch (error) {
      if (error.response.status === 400)
        return showToast("error", error.response.data);
    }
  }

  function isEquipment(typeId) {
    return typeId >= 1 && typeId <= 9;
  }

  function isAmmo(typeId) {
    return typeId >= 10 && typeId <= 13;
  }

  function isArmor(typeId) {
    return typeId >= 1 && typeId <= 5;
  }

  function isWeapon(typeId) {
    return typeId >= 6 && typeId <= 9;
  }

  function itemTypeHasLocation(typeId) {
    return isEquipment(typeId) || typeId === 14;
  }

  function itemLocationToName(location, typeId) {
    let label = "None";
    if (location === 0 && typeId === 14) return "Weapon";
    if (location === 0 && typeId === 1) return "Pet";
    locationOptions.forEach((loc) => {
      if (loc.value === location) return (label = loc.label);
    });
    return label;
  }

  function itemTypeIdToName(typeId) {
    switch (Number(typeId)) {
      case 0:
        return "Heal";
      case 1:
      case 2:
      case 3:
      case 4:
      case 5:
        return "Armor";
      case 6:
        return "Weapon";
      case 7:
        return "Bow";
      case 8:
        return "Gun";
      case 9:
        return "Both-hand";
      case 10:
        return "Ammo";
      case 11:
        return "Arrow";
      case 12:
        return "Throw Weapon";
      case 13:
        return "Cannon Ball";
      case 14:
        return "Card";
      case 15:
        return "Etc.";
      case 16:
        return "Cash Point Item";
      case 17:
        return "Special";
      default:
        return "Unknown";
    }
  }

  const value = {
    itemMain,
    itemDispatch,

    items,
    itemResults,
    itemListLoading,
    showOffItemContainCanvas,
    monstersDropsCanvas,
    hideItemList,
    itemKeyword,
    itemPreviewContainer,
    notSavedItems,
    filters,
    containWillInsert,

    jobOptions,
    locationOptions,
    resourceNameKorean,
    setResourceNameKorean,

    singleItem,

    handleChangeValue,
    handleJobs,
    handleSubmitItem,
    handleNewItem,
    handleExportItemInfo,
    handleSubmitContain,
    handleEmptyContain,

    selectItem,
    replaceItemDataFrom,
    handleDeleteItem,
    fetchAllItems,
    fetchItem,
    fetchItemMonstersDrops,
    fetchMonsterDropByItemId,
    searchItems,
    searchByKeyword,
    isItemChanged,
    isEquipment,
    isArmor,
    isWeapon,
    isAmmo,
    itemTypeHasLocation,
    itemLocationToName,
    itemTypeIdToName,
    itemFormRef,
  };

  return <ItemContext.Provider value={value} {...props} />;
};

function useItem() {
  const context = React.useContext(ItemContext);
  if (!context) {
    throw new Error(`useContext must be used within ItemProvider.`);
  }
  return context;
}

export {
  ItemProvider,
  useItem,
  emptyItem,
  initItemPreview,
  jobOptions,
  locationOptions,
};
