import React from "react";
import { toast } from "react-toastify";

export function copy(text) {
	navigator.clipboard.writeText(text).then(() =>
		toast.success("Đã copy!", {
			position: "bottom-right",
			autoClose: 2000,
		})
	);
}

function CopyButton({ text }) {
	const handleCopy = React.useCallback(() => {
		if (!text.length) return;
		copy(text);
	}, [text]);

	return (
		<button className="btn btn-primary px-5" onClick={handleCopy}>
			Copy
		</button>
	);
}

export default CopyButton;
