import { toast } from "react-toastify";

function showToast(type, text, position = "bottom-right") {
  toast[type](text, {
    position,
    autoClose: 3000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
}

export default showToast;
