import _ from "lodash";
import React, { useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useGeneral } from "../../contexts/generalContext";
import { WithContext as ReactTags } from "react-tag-input";

const ModalTag = ({ name, tags, changeTags, show, onHide }) => {
  const { globalTags, getGlobalTags } = useGeneral();

  const KeyCodes = {
    comma: 188,
    enter: 13,
  };

  const delimiters = [KeyCodes.comma, KeyCodes.enter];
  const [modalTags, setModalTags] = React.useState([]);
  const [suggestions, setSuggestions] = React.useState([]);

  const handleDelete = (i) => {
    const newTags = tags.filter((tag, index) => index !== i);
    console.log(newTags);
    changeTags(newTags);
  };

  const handleAddition = (tag) => {
    // check duplicate
    const index = _.findIndex(modalTags, (modalTag) => {
      return modalTag.text === tag.text.toLowerCase();
    });
    if (index !== -1) return;

    const index2 = _.findIndex(globalTags, (globalTag) => {
      return globalTag.name === tag.text.toLowerCase();
    });

    let id = "new";
    if (index2 !== -1) id = globalTags[index2].id;

    const newModalTags = [...tags, { id, name: tag.text.toLowerCase() }];
    changeTags(newModalTags);
  };

  const handleDrag = (tag, currPos, newPos) => {
    const newTags = tags.slice();

    newTags.splice(currPos, 1);
    newTags.splice(newPos, 0, { id: tag.id, name: tag.text });

    // re-render
    changeTags(newTags);
  };

  const handleTagClick = (index) => {
    console.log("The tag at index " + index + " was clicked");
  };

  useEffect(() => {
    let newModalTags = [];
    tags.forEach((tag) => {
      newModalTags = [...newModalTags, { id: tag.id, text: tag.name }];
    });
    setModalTags(newModalTags);
  }, [tags]);

  useEffect(() => {
    getGlobalTags();
  }, [getGlobalTags, show]);

  useEffect(() => {
    const newSuggestions = globalTags.map((globalTag) => {
      return {
        id: globalTag.id,
        text: globalTag.name,
      };
    });
    setSuggestions(newSuggestions);
  }, [globalTags, show]);

  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>
          <code>{name}</code> tags
        </Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <strong>Current tags: </strong>
        <ReactTags
          classNames={{
            tagInputField: "form-control",
          }}
          tags={modalTags}
          suggestions={suggestions}
          delimiters={delimiters}
          handleDelete={handleDelete}
          handleAddition={handleAddition}
          handleDrag={handleDrag}
          handleTagClick={handleTagClick}
          inputFieldPosition="bottom"
          autocomplete
        />
      </Modal.Body>

      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalTag;
