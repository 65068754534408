import React from "react";
import CopyButton from "../common/Copy";
import {
  checkEvent,
  checkIfStatement,
  checkChooseCase,
  checkWhile,
} from "../../utils/checkLine";
import { getLines, trimLines } from "../../utils/dialogUtils";
import { useLocalStorageState } from "../../utils/useLocalStorageState";

function Align() {
  const [textInput, setTextInput] = useLocalStorageState("alignTextInput", "");
  const [textOutput, setTextOutput] = React.useState("");

  const textInputRef = React.useRef();
  const textOutputRef = React.useRef();

  const handleReset = () => {
    setTextInput("");
    textInputRef.current.focus();
  };

  const handleChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleConvert = () => {
    let linesExport = [];
    const lines = getLines(textInput);
    linesExport = trimLines(lines);
    linesExport = checkIfStatement(linesExport);
    linesExport = checkEvent(linesExport);
    linesExport = checkChooseCase(linesExport);
    linesExport = checkWhile(linesExport);

    setTextOutput(linesExport.join("\n"));
  };

  React.useEffect(() => {
    if (textOutput.length > 0) textOutputRef.current.select();
  }, [textOutput]);

  return (
    <div className="row">
      <div className="col-6">
        <h4 className="mb-3">Paste the script below to align</h4>
        <div className="form-group mb-3">
          <textarea
            ref={textInputRef}
            value={textInput}
            onChange={handleChange}
            name="text-input"
            id="text-input"
            rows="20"
            className="form-control"
          />
        </div>
        <div className="row">
          <div className="col">
            <button className="btn btn-secondary me-3" onClick={handleReset}>
              Reset
            </button>
          </div>
          <div className="col text-end">
            <button className="btn btn-primary px-5" onClick={handleConvert}>
              Align
            </button>
          </div>
        </div>
      </div>

      <div className="col-6">
        <h4 className="mb-3">Result</h4>

        <div className="form-group mb-3">
          <textarea
            style={{ background: "#fff" }}
            ref={textOutputRef}
            readOnly
            value={textOutput}
            name="text-output"
            id="text-output"
            rows="20"
            className="form-control"
          ></textarea>
        </div>

        <div className="text-left">
          <CopyButton text={textOutput} />
        </div>
      </div>
    </div>
  );
}

export default Align;
