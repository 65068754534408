import React, { useReducer, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Table from "react-bootstrap/Table";
import FloatingLabel from "react-bootstrap/FloatingLabel";
// import Offcanvas from "react-bootstrap/Offcanvas";
import showToast from "../utils/showToast";
import axios from "axios";
import _ from "lodash";
import { SpinnerComponent } from "react-element-spinner";
import { useUser } from "../contexts/userContext";
import { useGeneral } from "../contexts/generalContext";
import { format } from "date-fns";

const testCaseReducer = (state, action) => {
  switch (action.type) {
    case "SET_TESTCASE_DATA":
      return { ...state, ...action.payload };
    case "GET_ITEM":
      return { ...state, inProgress: true };
    case "GET_ITEM_SUCCESS":
      return {
        ...state,
        items: [...state.items, action.payload],
        inProgress: false,
        value: "",
      };
    case "GET_ITEMS_SUCCESS":
      const value = state.value;
      return {
        ...state,
        items: [...state.items, ...action.payload],
        inProgress: false,
        packageName: value,
        value: "",
      };
    case "GET_ITEM_FAILED":
      return { ...state, inProgress: false };
    case "SET_PACKAGE_NAME":
      return { ...state, packageName: action.payload };
    case "SHOW_CANVAS":
      return { ...state, showCanvas: true };
    default:
      return { ...state };
  }
};

const TestCase = () => {
  const [testCase, testCaseDispatch] = useReducer(testCaseReducer, {
    value: "",
    items: [],
    packageName: "Example",
    persons: ["Kyoshinky", "Teededung", "Tí Quyết"],
    type: "0", // 0: by item id, 1: by package name
    inProgress: false,
  });

  const { globalTags, getGlobalTags } = useGeneral();

  // const [show, setShow] = useState(false);
  // const handleClose = () => setShow(false);
  // const handleShow = () => setShow(true);

  const { items, packageName, persons, inProgress, value, type } = testCase;
  const { userToken } = useUser();

  useEffect(() => {
    if (type !== "2") return;
    if (globalTags.length === 0) getGlobalTags();
  }, [getGlobalTags, globalTags.length, type]);

  function setFormData(field, value) {
    testCaseDispatch({
      type: "SET_TESTCASE_DATA",
      payload: { [`${field}`]: value },
    });
  }

  function removeItemFromList(item) {
    const itemsFiltered = items.filter((o) => o.itemId !== item.itemId);
    setFormData("items", [...itemsFiltered]);
  }

  function onSubmit(e) {
    e.preventDefault();

    if (!value) return showToast("warning", "Value must be provided!");

    if (inProgress)
      return showToast("warning", "The other task is on progress...");

    switch (Number(type)) {
      case 0: // By Item ID
        if (items.some((item) => item.id === Number(value)))
          return showToast("warning", "Item already exists!");

        testCaseDispatch({ type: "GET_ITEM" });

        axios({
          method: "GET",
          url: `${process.env.REACT_APP_API}/items/id/${value}`,
          headers: { "x-auth-token": userToken },
        })
          .then(({ data }) => {
            showToast("success", `1 items has been inserted to the list`);
            testCaseDispatch({ type: "GET_ITEM_SUCCESS", payload: data });
          })
          .catch((error) => {
            showToast("error", `Not found! ${error.message}`);
            testCaseDispatch({ type: "GET_ITEM_FAILED" });
          });
        break;
      case 1: // by Package Name
        testCaseDispatch({ type: "GET_ITEM" });

        axios({
          method: "GET",
          url: `${process.env.REACT_APP_API}/items/package/${value}`,
          headers: { "x-auth-token": userToken },
        })
          .then(({ data: itemsFromDatabase }) => {
            let itemsWillInsert = [];
            itemsFromDatabase.forEach((item) => {
              if (items.some((o) => o.itemId === item.itemId)) return;
              itemsWillInsert = [...itemsWillInsert, item];
            });

            showToast(
              "success",
              `${itemsWillInsert.length} items has been inserted to the list`
            );
            testCaseDispatch({
              type: "GET_ITEMS_SUCCESS",
              payload: itemsWillInsert,
            });
          })
          .catch((error) => {
            showToast("warning", `Not found! ${error.message}`);
            testCaseDispatch({ type: "GET_ITEM_FAILED" });
          });
        break;
      case 2: // by tag name
        testCaseDispatch({ type: "GET_ITEM" });
        axios({
          method: "GET",
          url: `${process.env.REACT_APP_API}/items/tag/${value}`,
          headers: { "x-auth-token": userToken },
        })
          .then(({ data: itemsFromDatabase }) => {
            let itemsWillInsert = [];
            itemsFromDatabase.forEach((item) => {
              if (items.some((o) => o.itemId === item.itemId)) return;
              itemsWillInsert = [...itemsWillInsert, item];
            });

            showToast(
              "success",
              `${itemsWillInsert.length} items has been inserted to the list`
            );
            testCaseDispatch({
              type: "GET_ITEMS_SUCCESS",
              payload: itemsWillInsert,
            });
          })
          .catch((error) => {
            showToast("warning", `Not found! ${error.message}`);
            testCaseDispatch({ type: "GET_ITEM_FAILED" });
          });
        break;
      default:
        break;
    }
  }

  function exportTC() {
    const arrayItemID = _.map(items, "itemId");
    axios({
      url: `${process.env.REACT_APP_API}/items/export-item-tc`,
      method: "POST",
      data: {
        items: arrayItemID,
        packageName: packageName ? packageName : "Example",
        persons,
      },
      headers: { "x-auth-token": userToken },
      responseType: "blob",
    })
      .catch((err) => {
        showToast("error", err.message);
      })
      .then((result) => {
        if (!result?.data) return showToast("error", "Something went wrong!");

        const url = window.URL.createObjectURL(new Blob([result.data]));
        const link = document.createElement("a");
        link.href = url;
        // set name with current date format YYYYMMDD
        link.setAttribute(
          "download",
          `ItemTestCase_${format(new Date(), "yyyyMMdd")}.xlsx`
        );
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }

  function clearItemList() {
    setFormData("items", []);
    testCaseDispatch({ type: "SET_PACKAGE_NAME", payload: "" });
  }

  return (
    <div>
      <h3>Item Test Case</h3>
      <hr />
      <Container fluid>
        <Row>
          <Col md={3}>
            <Form onSubmit={onSubmit} className="position-relative">
              <Form.Group className="mb-3">
                <FloatingLabel
                  controlId="floatingSelect"
                  label="Item Type"
                  className="mb-3"
                >
                  <Form.Select
                    aria-label="Choose a type"
                    value={type}
                    onChange={(e) => setFormData("type", e.target.value)}
                  >
                    <option value="0">by Item ID</option>
                    <option value="1">by Package Name</option>
                    <option value="2">by Tag Name</option>
                  </Form.Select>
                </FloatingLabel>

                {type !== "2" ? (
                  <FloatingLabel
                    controlId="floatingInput"
                    label={type === "0" ? "Give me an item id" : "Item db_name"}
                  >
                    <Form.Control
                      type="text"
                      placeholder="Give me an item id"
                      value={value}
                      onChange={(e) => setFormData("value", e.target.value)}
                    />
                  </FloatingLabel>
                ) : (
                  <>
                    <FloatingLabel
                      controlId="floatingSelectTagName"
                      label="Select a tag name"
                      className="mb-3"
                    >
                      <Form.Select
                        aria-label="Select a tag name"
                        value={value}
                        onChange={(e) => setFormData("value", e.target.value)}
                      >
                        <option value="" disabled>
                          Choose a tag below
                        </option>
                        {globalTags.map((tag) => {
                          return (
                            <option key={tag._id} value={tag.name}>
                              {tag.name}
                            </option>
                          );
                        })}
                      </Form.Select>
                    </FloatingLabel>

                    <div className="text-end">
                      <Button onClick={onSubmit}>Find items</Button>
                    </div>
                  </>
                )}
              </Form.Group>
              <SpinnerComponent
                loading={inProgress}
                position="centered"
                message="Searching..."
              ></SpinnerComponent>
            </Form>
          </Col>
          <Col md={9}>
            <FloatingLabel
              controlId="floatingInput"
              label="Package Name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                value={packageName}
                onChange={(e) =>
                  testCaseDispatch({
                    type: "SET_PACKAGE_NAME",
                    payload: e.target.value,
                  })
                }
                placeholder="Package Name"
              />
            </FloatingLabel>
            <Table responsive>
              <thead>
                <tr>
                  <th>#</th>
                  <th>ID</th>
                  <th>DB Name</th>
                  <th>Display Name</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {items.map((item, index) => (
                  <tr key={item.itemId}>
                    <td>{index + 1}</td>
                    <td>{item.itemId}</td>
                    <td>{item.dbName}</td>
                    <td>{item.identifiedDisplayName}</td>
                    <td>
                      <Button
                        onClick={() => removeItemFromList(item)}
                        size="sm"
                        variant="danger"
                      >
                        Remove
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            {items.length ? (
              <>
                <Button
                  variant="outline-info"
                  className="me-3"
                  onClick={clearItemList}
                >
                  Clear list
                </Button>
                <Button variant="primary" onClick={exportTC}>
                  Export test case file
                </Button>
              </>
            ) : null}
          </Col>
        </Row>
        {/* <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>Settings</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <FloatingLabel label="Package Name">
              <Form.Control
                type="text"
                id="package-name"
                value={packageName}
                onChange={(e) => setFormData("packageName", e.target.value)}
              />
            </FloatingLabel>
          </Offcanvas.Body>
        </Offcanvas> */}
      </Container>
    </div>
  );
};

export default TestCase;
