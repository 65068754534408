import React, { useReducer } from "react";
import _ from "lodash";
import axios from "axios";
import { useDebounce } from "use-debounce";
import showToast from "../utils/showToast";
import Swal from "sweetalert2";
import exportFile from "../utils/exportFile";
import { useUser } from "../contexts/userContext";
import { useGeneral } from "../contexts/generalContext";
import removeAccents from "../utils/removeAccents";

const emptyQuest = {
  id: -1,
  name: "",
  description: "",
  shortDescription: "",
  tags: [],
};

const initQuestPreview = {
  id: -1,
  server: "vRO",
  loading: false,
  name: "",
  shortDescription: "",
  description: "",
  notFound: false,
};

const QuestContext = React.createContext();

const questMainReducer = (state, action) => {
  let servers = {};

  switch (action.type) {
    case "SET_QUESTS":
      return { ...state, quests: action.payload };
    case "SET_QUEST_KEYWORD":
      return { ...state, questKeyword: action.payload };
    case "SET_QUEST_TO_LIST":
      const quest = action.payload;
      const newNotSavedQuests = state.notSavedQuests.filter(
        (notSavedQuest) => notSavedQuest.id !== quest.id
      );

      const indexThisQuest = _.findIndex(
        state.quests,
        (oQuest) => oQuest.id === quest.id
      );
      let copyQuests = [...state.quests];
      copyQuests[indexThisQuest] = quest;

      return {
        ...state,
        notSavedQuests: [...newNotSavedQuests],
        quests: [...copyQuests],
      };
    case "FETCH_QUESTS_SUCCESS":
      return { ...state, quests: action.payload, questLoading: false };
    case "FETCH_QUEST_PREVIEW":
      servers = { ...state.questPreviewContainer };

      return {
        ...state,
        questPreviewContainer: {
          ...servers,
          [action.payload.server]: {
            ...servers[action.payload.server],
            id: action.payload.id,
            loading: true,
          },
        },
      };
    case "FETCH_QUEST_PREVIEW_SUCCESS":
      servers = { ...state.questPreviewContainer };

      return {
        ...state,
        questPreviewContainer: {
          ...servers,
          [action.payload.server]: {
            ...servers[action.payload.server],
            id: action.payload.id,
            ...action.payload.value,
            loading: false,
            notFound: false,
          },
        },
      };
    case "FETCH_QUEST_PREVIEW_NOT_FOUND":
      servers = { ...state.questPreviewContainer };

      return {
        ...state,
        questPreviewContainer: {
          ...servers,
          [action.payload.server]: {
            ...servers[action.payload.server],
            id: action.payload.id,
            ...action.payload.value,
            loading: false,
            notFound: true,
          },
        },
      };
    case "SET_QUEST_LOADING":
      return { ...state, questLoading: action.payload };
    case "SET_QUEST_RESULTS":
      return { ...state, questResults: action.payload };
    case "SET_HIDE_QUEST_LIST":
      return { ...state, hideQuestList: action.payload };
    case "SET_NOT_SAVED_QUESTS":
      return {
        ...state,
        notSavedQuests: [...action.payload],
      };
    case "REMOVE_NOT_SAVED_QUEST":
      const newNotSavedQuests2 = state.notSavedQuests.filter(
        (notSavedQuest) => notSavedQuest.id !== action.payload.id
      );

      return {
        ...state,
        notSavedQuests: newNotSavedQuests2,
      };
    case "SET_QUEST_FILTERS":
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

const QuestProvider = (props) => {
  const [questMain, questDispatch] = useReducer(questMainReducer, {
    quests: [],
    questResults: [],
    questKeyword: "",
    questLoading: false,
    hideQuestList: false,
    notSavedQuests: [],
    filters: {
      tagId: "-1",
    },
    questPreviewContainer: {
      iRO: initQuestPreview,
      kRO: initQuestPreview,
      twRO: initQuestPreview,
    },
  });

  const {
    quests,
    questKeyword,
    notSavedQuests,
    filters,
    questPreviewContainer,
  } = questMain;

  const [questKeywordDebounce] = useDebounce(questKeyword, 300);

  const { userToken } = useUser();
  const { views, viewName, generalDispatch, getHistories, tabActive } =
    useGeneral();
  const activeItem = views.quests[tabActive.quests];

  function addToNotSaved(quest) {
    if (notSavedQuests.length === 0) {
      return questDispatch({
        type: "SET_NOT_SAVED_QUESTS",
        payload: [quest],
      });
    }

    // Compare
    const index = _.findIndex(quests, (oQuest) => oQuest.id === quest.id);
    if (index > -1) {
      if (_.isEqual(quests[index], quest)) {
        const newNotSavedQuests = notSavedQuests.filter(
          (notSavedQuest) => notSavedQuest.id !== quest.id
        );
        questDispatch({
          type: "SET_NOT_SAVED_QUESTS",
          payload: newNotSavedQuests,
        });
      } else {
        questDispatch({
          type: "SET_NOT_SAVED_QUESTS",
          payload: [...notSavedQuests, quest],
        });
      }
    }
  }

  function handleChangeQuestValue(field, value) {
    let questCopy = { ...activeItem };

    if (field === "description" || field === "shortDescription") {
      questCopy[field] = JSON.stringify(value);
    } else {
      questCopy[field] = value;
    }

    // Save to notSavedQuests
    addToNotSaved(questCopy);

    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "active",
        viewCat: "quests",
        viewField: "id",
        value: questCopy,
      },
    });
  }

  function handleExportQuest() {
    exportFile("quests", userToken);
  }

  function handleSubmitQuest(e) {
    e.preventDefault();
    let data = _.omit(activeItem, ["_id", "isNew", "__v", "loading"]);
    data.server = "vRO";

    // send only tag _id
    const copyTags = activeItem.tags.map((t) => t._id);
    data.tags = copyTags;

    axios({
      method: activeItem.isNew ? "post" : "put",
      url: `${process.env.REACT_APP_API}/quests/${
        activeItem.isNew ? "" : data.id
      }`,
      headers: { "x-auth-token": userToken },
      data,
    })
      .then(({ data: quest }) => {
        if (activeItem.isNew) {
          showToast(
            "success",
            `Added a new quest id ${quest.id} successfully!`
          );
        } else {
          showToast("success", `Update ${quest.name} successfully!`);
        }

        questDispatch({ type: "SET_QUEST_TO_LIST", payload: quest });
        generalDispatch({
          type: "SET_VIEWS",
          payload: {
            viewType: "active",
            viewCat: "quests",
            viewField: "id",
            value: quest,
          },
        });

        // reload histories
        getHistories(viewName, quest);
      })
      .catch((error) => {
        showToast("error", error.response.data);
      });
  }

  function handleNewQuest() {
    Swal.fire({
      title: "Quest ID?",
      input: "number",
      showCancelButton: true,
      confirmButtonText: "Add",
      preConfirm: (questId) => {
        if (questId < 1) {
          showToast("warning", `Quest ID is not valid.`);
          Swal.close();
        }
        const index = _.findIndex(quests, (i) => {
          return i.id === Number(questId);
        });
        if (index >= 0) {
          showToast("warning", `Quest with id ${questId} already exists.`);
          Swal.close();
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const tempQuest = {
          ...emptyQuest,
          id: Number(result.value),
          isNew: true,
        };
        questDispatch({ type: "SET_QUESTS", payload: [tempQuest, ...quests] });
        generalDispatch({
          type: "SET_VIEWS",
          payload: {
            viewType: "add",
            viewCat: "quests",
            viewField: "id",
            value: tempQuest,
          },
        });
      }
    });
  }

  function selectQuest(quest, removeNotSaved = false) {
    if (typeof quest === "undefined")
      return showToast("error", "Quest is undefined!");

    let questFull = null;
    let indexNotSavedQuest = -1;

    if (removeNotSaved) {
      questDispatch({ type: "REMOVE_NOT_SAVED_QUEST", payload: quest });
    } else {
      indexNotSavedQuest = _.findIndex(
        notSavedQuests,
        (notSavedQuest) => notSavedQuest.id === quest.id
      );
    }

    if (indexNotSavedQuest > -1) {
      // set state to view
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "active",
          viewCat: "quests",
          viewField: "id",
          value: { ...notSavedQuests[indexNotSavedQuest] },
        },
      });

      // get item histories
      getHistories(viewName, quest);
    } else {
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "active",
          viewCat: "quests",
          viewField: "id",
          value: {
            ...emptyQuest,
            ...quest,
            loading: true,
          },
        },
      });

      // Fetch vRO data for this quest
      axios({
        url: `${process.env.REACT_APP_API}/quests/${quest.id}`,
        method: "GET",
        headers: { "x-auth-token": userToken },
      })
        .then((res) => {
          questFull = {
            ...quest,
            ...res.data,
            loading: false,
          };

          // set state to view
          generalDispatch({
            type: "SET_VIEWS",
            payload: {
              viewType: "active",
              viewCat: "quests",
              viewField: "id",
              value: questFull,
            },
          });

          questDispatch({ type: "SET_QUEST_TO_LIST", payload: questFull });
          // get item histories
          getHistories(viewName, questFull);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }

  function searchQuests(keyword) {
    let results = [...quests];

    // Filter by tagId
    if (filters.tagId !== "-1") {
      results = quests.filter((quest) => {
        return quest.tags.some((tag) => {
          return tag._id === filters.tagId;
        });
      });
    }

    if (keyword !== "") {
      const normalizeKeyword = removeAccents(
        keyword.toString().trim().toLowerCase()
      );

      // Search by id, identifiedDisplayName and dbName
      const searchFields = ["id", "name"];

      let searchResults = searchFields.reduce((acc, field) => {
        const filteredQuests = results.filter((quest) => {
          const normalizedItemField = removeAccents(
            quest[field]?.toString() || ""
          ).toLowerCase();
          return normalizedItemField.includes(normalizeKeyword);
        });
        return [...acc, ...filteredQuests];
      }, []);

      // Remove duplicate results
      results = [...new Set(searchResults)];
    }
    return results;
  }

  function replaceQuestDataFrom(questData) {
    const newQuestData = {
      ...activeItem,
      ...questData,
    };
    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "active",
        viewCat: "quests",
        viewField: "id",
        value: newQuestData,
      },
    });
    addToNotSaved(newQuestData);
  }

  function getQuestInfoById(id, field) {
    const index = _.findIndex(
      quests,
      (quest) => Number(quest.id) === Number(id)
    );
    if (index !== -1) return quests[index][field];
    return "";
  }

  const value = {
    questMain,
    questDispatch,
    questKeywordDebounce,
    questKeyword,
    notSavedQuests,
    filters,
    questPreviewContainer,

    selectQuest,
    handleExportQuest,
    handleNewQuest,
    handleSubmitQuest,
    handleChangeQuestValue,
    replaceQuestDataFrom,
    getQuestInfoById,

    searchQuests,
  };

  return <QuestContext.Provider value={value} {...props} />;
};

function useQuest() {
  const context = React.useContext(QuestContext);
  if (!context) {
    throw new Error(`useContext must be used within QuestProvider.`);
  }
  return context;
}

export { QuestProvider, useQuest, emptyQuest, initQuestPreview };
