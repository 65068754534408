import React, { useEffect } from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import axios from "axios";
import { useGeneral } from "../../contexts/generalContext";
import { useQuest, initQuestPreview } from "../../contexts/questContext";
import { useUser } from "../../contexts/userContext";
import QuestPreview from "../quest/QuestPreview";
import { useLocalStorageState } from "../../utils/useLocalStorageState";

const QuestPreviewContainer = () => {
  const { tabActive, views } = useGeneral();
  const { questDispatch, questPreviewContainer } = useQuest();

  const { userToken } = useUser();

  const activeItem = views.quests[tabActive.quests];
  const { id, name, description, shortDescription, notFound } = activeItem;

  const [currentServerTab, setCurrentServerTab] = useLocalStorageState(
    "RODefaultServerTab_Quest",
    "iRO"
  );

  const vRO = {
    server: "vRO",
    loading: false,
    name: name ? name : "",
    description: description ? JSON.parse(description) : "",
    shortDescription: shortDescription ? JSON.parse(shortDescription) : "",
    notFound,
  };

  async function fetchDataAndSet(server, fromLocal = true) {
    questDispatch({ type: "FETCH_QUEST_PREVIEW", payload: { server, id } });

    let axiosOptions = {
      url: "",
      method: "GET",
    };

    if (fromLocal) {
      axiosOptions.url = `${process.env.REACT_APP_API}/quests/${id}?server=${server}`;
      axiosOptions.headers = { "x-auth-token": userToken };
    } else {
      axiosOptions.url = `https://divine-pride.net/api/database/Quest/${id}?apiKey=${process.env.REACT_APP_API_KEY_DIVINE_PRIDE}&server=${server}`;
    }

    try {
      const res = await axios(axiosOptions);
      let object = {};

      if (fromLocal) {
        object.name = res.data.name;
        object.description = JSON.parse(res.data.description);
        object.shortDescription = JSON.parse(res.data.shortDescription);
      } else {
        object.name = res.data.title;
        object.description = res.data.info;
        object.shortDescription = res.data.quickinfo;
      }

      questDispatch({
        type: "FETCH_QUEST_PREVIEW_SUCCESS",
        payload: {
          server,
          id,
          value: { ...object },
        },
      });

      return res;
    } catch (e) {
      if (e.response && e.response.status === 404) {
        questDispatch({
          type: "FETCH_QUEST_PREVIEW_NOT_FOUND",
          payload: {
            server,
            id,
            value: {
              ...initQuestPreview,
              name: `Không tìm thấy trên ${server}`,
              description: `Không tìm thấy trên ${server}`,
              shortDescription: `Không tìm thấy trên ${server}`,
            },
          },
        });
      }
    }
  }

  useEffect(() => {
    const isSameId = questPreviewContainer[currentServerTab]["id"] === id;
    if (
      isSameId ||
      (isSameId && questPreviewContainer[currentServerTab]["notFound"])
    )
      return;

    fetchDataAndSet(currentServerTab, true);
    // eslint-disable-next-line
  }, [currentServerTab, id]);

  return (
    <Row className="g-2">
      <Col className="mb-3 mb-sm-0">
        <div className="col-item-preview">
          <h5>Data from other servers</h5>
          <Tabs
            defaultActiveKey={currentServerTab}
            id=""
            onSelect={setCurrentServerTab}
          >
            {Object.keys(questPreviewContainer).map((server) => {
              if (server === "vRO") return null;
              return (
                <Tab key={server} eventKey={server} title={server}>
                  <QuestPreview
                    questData={{ ...questPreviewContainer[server], server }}
                  />
                </Tab>
              );
            })}
          </Tabs>
          {id > 0 ? (
            <a
              target="_blank"
              rel="noopener noreferrer"
              className="external-link"
              href={`https://divine-pride.net/database/quest/${id}`}
            >
              Open
            </a>
          ) : null}
        </div>
      </Col>

      <Col className="col-preview-vRO">
        <div className="col-item-preview">
          <h5>Data from vRO</h5>
          <div className="mt-5">
            <QuestPreview questData={vRO} />
          </div>
        </div>
      </Col>
    </Row>
  );
};

export default QuestPreviewContainer;
