import React from "react";
import { useDebounce } from "use-debounce";
import showToast from "../utils/showToast";

const ScriptContext = React.createContext();

const ScriptProvider = (props) => {
  const [scripts, setScripts] = React.useState([]);
  const [scriptResults, setScriptResults] = React.useState([]);
  const [scriptKeyword, setScriptKeyword] = React.useState("");
  const [scriptKeywordDebounce] = useDebounce(scriptKeyword, 300);
  const [scriptLoading, setScriptLoading] = React.useState(false);
  const [viewScript, setViewScript] = React.useState();
  const [hideScriptList, setHideScriptList] = React.useState(false);

  function selectScript(script) {
    if (typeof script === "undefined")
      return showToast("error", "Script is undefined!");

    // set state to view
    setViewScript(script);
  }

  const value = {
    scripts,
    setScripts,
    scriptResults,
    setScriptResults,
    scriptKeyword,
    setScriptKeyword,
    scriptKeywordDebounce,
    scriptLoading,
    setScriptLoading,
    viewScript,
    setViewScript,
    hideScriptList,
    setHideScriptList,
    selectScript,
  };

  return <ScriptContext.Provider value={value} {...props} />;
};

function useScript() {
  const context = React.useContext(ScriptContext);
  if (!context) {
    throw new Error(`useContext must be used within ScriptProvider.`);
  }
  return context;
}

export { ScriptProvider, useScript };
