import React, { useState, useMemo } from "react";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Table from "react-bootstrap/Table";
import ListGroup from "react-bootstrap/ListGroup";
import Alert from "react-bootstrap/Alert";
import Accordion from "react-bootstrap/Accordion";
import Spinner from "react-bootstrap/Spinner";

import ItemBox from "./ItemBoxFlex";
import ItemMoveInfoV2 from "./ItemMoveInfoV2";
import ItemDrops from "./ItemDrops";

import { useItem } from "../../contexts/itemContext";
import { numberWithCommas, elementNumToText } from "../../utils/textUtils";

const ItemSingleDetail = ({ item, props }) => {
  const [sort, setSort] = useState({
    by: "chance",
    type: "desc",
  });

  const {
    isEquipment,
    isArmor,
    isWeapon,
    isAmmo,
    itemLocationToName,
    itemTypeIdToName,
    itemTypeHasLocation,
  } = useItem();

  const {
    location,
    typeId,
    itemCollectionImage,
    identifiedDisplayName,
    identifiedDescriptionName,
    itemMoveInfo,
    slots,
    weight,
    price,
    atk,
    matk,
    def,
    mdef,
    requiredLevel,
    indestructible,
    refineable,
    attribute,
    itemLevel,
    drops,
    dropLoading,
  } = item;

  function handleSort(by) {
    setSort({
      by,
      type: sort.type === "asc" ? "desc" : "asc",
    });
  }

  const sortedDrops = useMemo(() => {
    if (drops.length === 0) return [];

    let tempDrops = [];
    if (sort.by === "name") {
      const compareByName = (a, b) => a.name.localeCompare(b.name);
      tempDrops = drops.toSorted(
        sort.type === "asc" ? compareByName : (a, b) => compareByName(b, a)
      );
    } else {
      const compareByNumber = (a, b) => a[sort.by] - b[sort.by];
      tempDrops = drops.toSorted(
        sort.type === "asc" ? compareByNumber : (a, b) => compareByNumber(b, a)
      );
    }

    return tempDrops;
    // eslint-disable-next-line
  }, [sort.by, sort.type]);

  return (
    <div {...props}>
      <Row>
        <Col className="mb-3 mb-md-0" sm={6} md={5} lg={4} xxl={3}>
          <ItemBox
            loading={false}
            itemCollectionImage={itemCollectionImage}
            identifiedDisplayName={identifiedDisplayName}
            slots={slots}
            identifiedDescriptionName={JSON.parse(identifiedDescriptionName)}
          />
        </Col>

        <Col className="mb-3">
          <Accordion defaultActiveKey={["0", "1", "2"]} alwaysOpen>
            <Accordion.Item eventKey="0">
              <Accordion.Header>General info</Accordion.Header>
              <Accordion.Body className="p-2">
                <ListGroup className="mb-2 d-inline-block">
                  <ListGroup.Item>
                    Type: <code>{itemTypeIdToName(typeId)}</code>
                  </ListGroup.Item>
                  {itemTypeHasLocation(typeId) ? (
                    <ListGroup.Item>
                      Location:{" "}
                      <code>{itemLocationToName(location, typeId)}</code>
                    </ListGroup.Item>
                  ) : null}
                  {indestructible ? (
                    <ListGroup.Item>
                      Không bị hư trong giao tranh.
                    </ListGroup.Item>
                  ) : null}
                  {isEquipment(typeId) && !refineable ? (
                    <ListGroup.Item>Không thể cường hóa.</ListGroup.Item>
                  ) : null}
                </ListGroup>

                <Table className="mt-2 w-auto table-bordered">
                  <thead>
                    <tr>
                      {isWeapon(typeId) ? (
                        <>
                          <th>ATK</th>
                          <th>MATK</th>
                        </>
                      ) : null}
                      {isArmor(typeId) ? (
                        <>
                          <th>DEF</th>
                          <th>MDEF</th>
                        </>
                      ) : null}
                      {isEquipment(typeId) ? (
                        <>
                          <th>Lv Req.</th>
                          <th>Slots</th>
                        </>
                      ) : null}
                      <th>Weight</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {isWeapon(typeId) ? (
                        <>
                          <td>{atk}</td>
                          <td>{matk}</td>
                        </>
                      ) : null}
                      {isArmor(typeId) ? (
                        <>
                          <td>{def}</td>
                          <td>{mdef}</td>
                        </>
                      ) : null}
                      {isEquipment(typeId) ? (
                        <>
                          <td>{requiredLevel}</td>
                          <td>{slots}</td>
                        </>
                      ) : null}
                      <td>{weight / 10}</td>
                    </tr>
                  </tbody>
                </Table>

                <Table className="w-auto table-bordered">
                  <thead>
                    <tr>
                      <th>Price</th>
                      {isWeapon(typeId) ? <th>Weapon Lv.</th> : null}
                      {isAmmo(typeId) || isEquipment(typeId) ? (
                        <th>Element</th>
                      ) : null}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>{numberWithCommas(price)}</td>
                      {isWeapon(typeId) ? <td>{itemLevel}</td> : null}
                      {isAmmo(typeId) || isEquipment(typeId) ? (
                        <td>{elementNumToText(attribute)}</td>
                      ) : null}
                    </tr>
                  </tbody>
                </Table>
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="1">
              <Accordion.Header>
                Monster drops ({drops.length})
              </Accordion.Header>
              <Accordion.Body className="p-2">
                {dropLoading ? (
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                ) : drops.length === 0 ? (
                  <Alert className="d-inline-block" variant="secondary">
                    Không có quái vật nào rơi vật phẩm này!
                  </Alert>
                ) : (
                  <ItemDrops
                    drops={sortedDrops}
                    sort={sort}
                    handleSort={handleSort}
                  />
                )}
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="2">
              <Accordion.Header>Move info</Accordion.Header>
              <Accordion.Body className="p-0">
                <ItemMoveInfoV2 itemMoveInfo={itemMoveInfo} />
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Col>
      </Row>
    </div>
  );
};

export default ItemSingleDetail;
