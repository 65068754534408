import _ from "lodash";
import parse from "html-react-parser";
import iconv from "iconv-lite";

function copyText(string) {
  if (string.length === 0) return;
  const el = document.createElement("textarea");
  el.value = string;
  document.body.appendChild(el);
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
}

function renderROHTML(text) {
  if (!text) return text;
  let newText = "";

  // Breakline
  newText = text.split("\n").join("<br>");

  // Color
  const regex = /\^[a-zA-Z-0-9]{6}/gm;
  let colors = _.uniqBy(newText.match(regex));
  _.forEach(colors, (color) => {
    const hexColor = color.replace("^", "#");
    newText = newText.replaceAll(color, `<span style='color: ${hexColor}'>`);
  });

  // Navi
  const regexNavi = /<INFO>(.*?)<\/INFO>/gm;
  newText = newText.replace(regexNavi, "");
  newText = newText.replace("<NAVI>", "<code>");
  newText = newText.replace("</NAVI>", "</code>");
  return parse(newText);
}

function convertKoreanToCp1258(string) {
  const buf = iconv.encode(string, "Windows949");
  const converted = iconv.decode(buf, "win1258");
  return converted;
}

function convertCp1258ToKorean(string) {
  const buf = iconv.encode(string, "win1258");
  const converted = iconv.decode(buf, "Windows949");
  return converted;
}

function isNumeric(str) {
  if (typeof str != "string") return false; // we only process strings!
  return (
    !isNaN(str) && !isNaN(parseFloat(str)) // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
  ); // ...and ensure strings of whitespace fail
}

function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

function monsterRaceNumToText(num) {
  switch (num) {
    case 0:
      return "Formless";
    case 1:
      return "Undead";
    case 2:
      return "Brute";
    case 3:
      return "Plant";
    case 4:
      return "Insect";
    case 5:
      return "Fish";
    case 6:
      return "Demon";
    case 7:
      return "Demi-human";
    case 8:
      return "Angel";
    case 9:
      return "Dragon";
    default:
      return "Unknown";
  }
}

function elementNumToText(num, level = 0) {
  let element = "";
  switch (num) {
    case 0:
      element = "Neutral";
      break;
    case 1:
      element = "Water";
      break;
    case 2:
      element = "Earth";
      break;
    case 3:
      element = "Fire";
      break;
    case 4:
      element = "Wind";
      break;
    case 5:
      element = "Poison";
      break;
    case 6:
      element = "Holy";
      break;
    case 7:
      element = "Dark";
      break;
    case 8:
      element = "Ghost";
      break;
    case 9:
      element = "Undead";
      break;
    default:
      element = "Unknown";
      break;
  }

  if (level > 0) {
    element = `${element} ${level}`;
  }

  return element;
}

function monsterSizeToText(size) {
  switch (size) {
    case 0:
      return "Small";
    case 1:
      return "Medium";
    case 2:
      return "Large";
    default:
      return "Unknown";
  }
}

function undefinedDisplayNameTranslator(unidentifiedDisplayName) {
  let uName = "";
  switch (unidentifiedDisplayName) {
    case "머리띠":
      uName = "Hairband";
      break;
    case "투구":
      uName = "Headgear";
      break;
    case "헬름":
      uName = "Helmet";
      break;
    case "모자":
      uName = "Hat";
      break;
    case "액세서리":
      uName = "Accessory";
      break;
    case "안경":
      uName = "Glasses";
      break;
    case "리본":
      uName = "Ribbon";
      break;
    case "가면":
      uName = "Mask";
      break;
    case "대못박힌 스카프":
      uName = "Mouth Mask";
      break;
    case "활":
      uName = "Bow";
      break;
    case "창":
      uName = "Spear";
      break;
    case "한손창":
      uName = "One-Handed Spear";
      break;
    case "양손창":
      uName = "Two-Handed Spear";
      break;
    case "검":
      uName = "Sword";
      break;
    case "한손검":
      uName = "One-Handed Sword";
      break;
    case "양손검":
      uName = "Two-Handed Sword";
      break;
    case "지팡이":
      uName = "Staff";
      break;
    case "한손 지팡이":
      uName = "One-Handed Staff";
      break;
    case "양손 지팡이":
      uName = "Two-Handed Staff";
      break;
    case "도끼":
      uName = "Axe";
      break;
    case "한손도끼":
      uName = "One-Handed Axe";
      break;
    case "양손도끼":
      uName = "Two-Handed Axe";
      break;
    case "단검":
      uName = "Dagger";
      break;
    case "방패":
      uName = "Shield";
      break;
    case "샷건":
      uName = "Shotgun";
      break;
    case "리볼버":
      uName = "Revolver";
      break;
    case "라이플":
      uName = "Rifle";
      break;
    case "그레네이드런처":
      uName = "Grenade Launcher";
      break;
    case "개틀링건":
      uName = "Gatling Gun";
      break;
    case "악기":
      uName = "Instrument";
      break;
    case "채찍":
      uName = "Whip";
      break;
    case "둔기":
      uName = "Mace";
      break;
    case "책":
      uName = "Book";
      break;
    case "손톱":
      uName = "Knuckle";
      break;
    case "카타르":
      uName = "Katar";
      break;
    case "풍마수리검":
      uName = "Huuma Shuriken";
      break;
    case "갑옷":
    case "시즈 플레이트":
      uName = "Armor";
      break;
    case "시즈 부츠":
      uName = "Shoes";
      break;
    case "걸칠것":
      uName = "Garment";
      break;
    case "웨폰 쉐도우":
      uName = "Shadow Weapon";
      break;
    case "아머 쉐도우":
      uName = "Shadow Armor";
      break;
    case "쉴드 쉐도우":
      uName = "Shadow Shield";
      break;
    case "슈즈 쉐도우":
      uName = "Shadow Shoes";
      break;
    case "펜던트 쉐도우":
      uName = "Shadow Pendant";
      break;
    case "이어링 쉐도우":
      uName = "Shadow Earring";
      break;
    default:
      uName = unidentifiedDisplayName;
      break;
  }
  return uName;
}

export {
  copyText,
  renderROHTML,
  convertKoreanToCp1258,
  convertCp1258ToKorean,
  numberWithCommas,
  monsterRaceNumToText,
  elementNumToText,
  monsterSizeToText,
  undefinedDisplayNameTranslator,
  isNumeric,
};
