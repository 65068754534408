import _ from "lodash";

// ------------------------ check if statement
function checkIfStatement(lines) {
  let tabCount = 0;
  let tabTime = 0;
  let linesExport = [];
  const regexElseIf = /elseif/gm;

  _.forEach(lines, (line) => {
    const isIf = Boolean(line.startsWith("if"));
    const isElse = Boolean(line.startsWith("else ") || line.startsWith("else"));
    const isElseIf = Boolean(regexElseIf.exec(line));
    const isEndIf = Boolean(line.startsWith("endif"));

    if (isIf) {
      tabCount++;
    }

    tabTime = tabCount;
    if (isIf || isElse || isElseIf || isEndIf) {
      tabTime--;
    }

    if (line.length) {
      for (let i = 0; i < tabTime; i++) {
        line = `\t${line}`;
      }
    }

    if (isEndIf) {
      tabCount--;
    }

    linesExport = [...linesExport, line];
  });

  return linesExport;
}

// ------------------------ check Event
function checkEvent(lines) {
  let linesExport = [];
  let tabCount = false;
  _.forEach(lines, (line) => {
    if (
      line.startsWith("OnInit") ||
      line.startsWith("OnClick") ||
      line.startsWith("OnCommand") ||
      line.startsWith("OnTouch") ||
      line.startsWith("OnTimer") ||
      line.startsWith("OnMyMobDead")
    ) {
      tabCount = true;
    }

    let isTab = tabCount;
    if (
      line.startsWith("OnInit") ||
      line.startsWith("OnClick") ||
      line.startsWith("OnCommand") ||
      line.startsWith("OnTouch") ||
      line.startsWith("OnTimer") ||
      line.startsWith("OnMyMobDead") ||
      line.startsWith("npc ") ||
      line.startsWith("MD_Npc ") ||
      line.startsWith("MD_Hiddenwarp ") ||
      line.startsWith("MD_Warp ") ||
      line.startsWith("MD_Putmob ") ||
      line.startsWith("warp ") ||
      line.startsWith("hiddenwarp ") ||
      line.startsWith("putmob") ||
      line.startsWith("arenaguide") ||
      line.startsWith("trader") ||
      line.startsWith("//")
    ) {
      isTab = false;
    }

    if (isTab) {
      if (line.length > 0) line = `\t${line}`;
    }

    linesExport = [...linesExport, line];
  });

  return linesExport;
}

// ------------------------ check Event
function checkChooseCase(lines) {
  let tabCount = 0;
  let linesExport = [];
  const regexChooseMenu = /choose menu/gm;
  const regexCase = /case\s+\d{1,}/gm;
  const regexBreak = /break$/gm;
  const regexEndChoose = /endchoose$/gm;

  _.forEach(lines, (line) => {
    const isChooseMenu = Boolean(regexChooseMenu.exec(line));
    const isCase = Boolean(regexCase.exec(line));
    const isBreak = Boolean(regexBreak.exec(line));
    const isEndChoose = Boolean(regexEndChoose.exec(line));

    if (isChooseMenu || isCase) {
      tabCount++;
    }

    let tabTime = tabCount;
    if (isChooseMenu || isCase || isEndChoose || isBreak) {
      tabTime--;
    }

    for (let i = 0; i < tabTime; i++) {
      line = `\t${line}`;
    }

    if (tabCount && (isEndChoose || isBreak)) {
      tabCount--;
    }

    linesExport = [...linesExport, line];
  });

  return linesExport;
}

// ------------------------ check while
function checkWhile(lines) {
  let tabCount = 0;
  let linesExport = [];
  const regexWhile = /while\(|while\s*\(/gm;
  const regexEndWhile = /endwhile$/gm;

  _.forEach(lines, (line) => {
    const isWhile = Boolean(regexWhile.exec(line) !== null);
    const isEndWhile = Boolean(regexEndWhile.exec(line) !== null);

    if (isWhile) {
      tabCount++;
    }

    let tabTime = tabCount;
    if (isWhile || isEndWhile) {
      tabTime--;
    }

    for (let i = 0; i < tabTime; i++) {
      line = `\t${line}`;
    }

    if (tabCount && isEndWhile) {
      tabCount--;
    }

    linesExport = [...linesExport, line];
  });

  return linesExport;
}

export { checkIfStatement, checkEvent, checkChooseCase, checkWhile };
