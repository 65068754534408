import React from "react";
import { SpinnerComponent } from "react-element-spinner";
import { renderROHTML } from "../../utils/textUtils";

const SkillPreview = ({ data }) => {
  let { id, description, loading } = data;

  function printDescription(lines) {
    if (!lines) return;
    let string = "";
    lines.forEach((line) => {
      string += line + "\n";
    });
    return renderROHTML(string);
  }

  return (
    <div className="position-relative">
      <div className="p-2">
        <div className="mb-1">
          <img
            src={`https://static.divine-pride.net/images/skill/${id}.png`}
            alt=""
          />
        </div>
        {printDescription(description)}
      </div>
      <SpinnerComponent
        loading={loading}
        position="centered"
        message="Loading skill..."
        backgroundColor="#cccccc"
      ></SpinnerComponent>
    </div>
  );
};

export default SkillPreview;
