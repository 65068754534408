import React from "react";
import { Route } from "react-router-dom";

import Items from "./components/item/Items";
import Maps from "./components/map/Maps";
import Monsters from "./components/monster/Monsters";
import Skills from "./components/skill/Skills";
import Quests from "./components/quest/Quests";
import Script from "./components/script/Script";
import RateCalculate from "./components/RateCalculate";
import Align from "./components/script/Align";
import SplitJoin from "./components/script/SplitJoin";
import Count from "./components/script/Count";
import Progress from "./components/Progress";
import Import from "./components/Import";
import TestCase from "./components/TestCase";
import AdminDashboard from "./components/AdminDashboard";

import { QuestProvider } from "./contexts/questContext";
import { SkillProvider } from "./contexts/skillContext";
import { MapProvider } from "./contexts/mapContext";
import { ScriptProvider } from "./contexts/scriptContext";

const Admin = () => {
  return (
    <QuestProvider>
      <SkillProvider>
        <MapProvider>
          <ScriptProvider>
            <Route path="/admin/items" component={Items} />
            <Route path="/admin/maps" component={Maps} />
            <Route path="/admin/monsters" component={Monsters} />
            <Route path="/admin/skills" component={Skills} />
            <Route path="/admin/quests" component={Quests} />
            <Route path="/admin/scripts" component={Script} />
            <Route path="/admin/import" component={Import} />
            <Route path="/admin/test-case" component={TestCase} />
            <Route path="/admin/rate-calculate" component={RateCalculate} />
            <Route path="/admin/count" component={Count} />
            <Route path="/admin/progress" component={Progress} />
            <Route path="/admin/align" component={Align} />
            <Route path="/admin/split-join" component={SplitJoin} />
            <Route exact path="/admin" component={AdminDashboard} />
          </ScriptProvider>
        </MapProvider>
      </SkillProvider>
    </QuestProvider>
  );
};

export default Admin;
