import React, { useReducer } from "react";
import _ from "lodash";
import axios from "axios";
import { useDebounce } from "use-debounce";
import showToast from "../utils/showToast";
// import Swal from "sweetalert2";
import removeAccents from "../utils/removeAccents";
import { useUser } from "../contexts/userContext";
import { useGeneral } from "../contexts/generalContext";

const emptyMonster = {
  id: -1,
  name: "",
  dbName: "",
  level: 0,
  hp: 0,
  sp: 0,
  baseExp: 0,
  jobExp: 0,
  def: 0,
  mdef: 0,
  range: 0,
  spellRange: 0,
  sightRange: 0,
  atk1: 0,
  atk2: 0,
  mStr: 0,
  mAgi: 0,
  mVit: 0,
  mInt: 0,
  mDex: 0,
  mLuk: 0,
  size: 0,
  race: 0,
  class: 0,
  element: 0,
  elementLv: 0,
  speed: 0,
  aDelay: 0,
  dMotion: 0,
  aMotion: 0,
  drops: [],
  mvpDrops: [],
  attr: 0,
  tags: [],
};

const defaultFilters = {
  tagId: "-1",
  race: "-1",
  element: "-1",
  classM: "-1",
  size: "-1",
  lvFrom: 0,
  lvTo: 200,
};

const MonsterContext = React.createContext();

const monsterReducer = (state, action) => {
  let indexThisMonster = -1;
  let indexThisMonster2 = -1;
  let copyMonsters = [];
  let copyMonsterResults = [];

  switch (action.type) {
    case "PRE_SEARCH_MONSTER":
      return { ...state, state: "loading" };
    case "FETCH_MONSTERS":
      return { ...state, monsterLoading: true };
    case "SEARCH_MONSTERS_SUCCESS":
      const monsters = action.payload;
      return {
        ...state,
        state: monsters.length > 0 ? "idle" : "notFound",
        foundMonsters: [...monsters],
        monsterTabActive: "monsterResultsTab",
      };
    case "FETCH_MONSTER_SUCCESS":
      return {
        ...state,
        state: "idle",
        viewMonster: { ...state.viewMonster, ...action.payload },
        monsterTabActive: "monsterDetailTab",
      };
    case "FETCH_SINGLE_MONSTER_SUCCESS":
      return {
        ...state,
        monsterLoading: false,
        viewMonster: { ...state.viewMonster, ...action.payload },
      };
    case "FETCH_MONSTERS_SUCCESS":
      return {
        ...state,
        monsters: action.payload,
        monsterResults: action.payload,
        monsterLoading: false,
      };
    case "SET_MONSTER_TO_LIST":
      indexThisMonster = _.findIndex(
        state.monsters,
        (monster) => monster.dbName === action.payload.dbName
      );
      copyMonsters = [...state.monsters];
      copyMonsters[indexThisMonster] = { ...action.payload };

      // monsterResults
      indexThisMonster2 = _.findIndex(
        state.monsterResults,
        (monster) => monster.dbName === action.payload.dbName
      );
      copyMonsterResults = [...state.monsterResults];
      copyMonsterResults[indexThisMonster2] = { ...action.payload };

      return {
        ...state,
        monsters: [...copyMonsters],
        monsterResults: [...copyMonsterResults],
      };
    case "SET_MONSTER_FILTERS":
      return { ...state, filters: { ...state.filters, ...action.payload } };
    case "CLEAR_MONSTER_FILTERS":
      return { ...state, filters: { ...defaultFilters } };
    case "SET_MONSTER_KEYWORD":
      return { ...state, monsterKeyword: action.payload };
    case "SET_MONSTER_RESULTS":
      return {
        ...state,
        monsterResults: action.payload,
      };
    case "SET_MONSTER_SEARCH":
      return {
        ...state,
        search: {
          ...state.search,
          [action.payload.field]: action.payload.value,
        },
      };
    case "SET_HIDE_MONSTER_LIST":
      return { ...state, hideMonsterList: action.payload };
    case "SET_MONSTER_TAB":
      return {
        ...state,
        monsterTabActive: action.payload,
      };
    default:
      return state;
  }
};

const MonsterProvider = (props) => {
  const [monsterMain, monsterDispatch] = useReducer(monsterReducer, {
    // List page
    monsterLoading: false,
    monsters: [],
    monsterResults: [],
    viewMonster: emptyMonster,
    monsterKeyword: "",
    filters: defaultFilters,
    hideMonsterList: false,

    // Search page
    state: "idle",
    monsterTabActive: "monsterResultsTab",
    foundMonsters: [],
    search: {
      keyword: "",
      race: -1,
      element: -1,
      classM: -1,
      size: -1,
      lvFrom: 0,
      lvTo: 200,
    },
  });

  const {
    monsters,
    viewMonster,
    hideMonsterList,
    monsterLoading,
    monsterKeyword,
    filters,
    monsterResults,

    state,
    search,
    monsterTabActive,
    foundMonsters,
  } = monsterMain;

  const [monsterKeywordDebounce] = useDebounce(monsterKeyword, 300);
  const [filtersDebounce] = useDebounce(filters, 300);

  const { userToken } = useUser();
  const { generalDispatch } = useGeneral();

  function selectMonster(monster) {
    if (typeof monster === "undefined")
      return showToast("error", "Monster is undefined!");

    let monsterFull = null;

    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "active",
        viewCat: "monsters",
        value: {
          ...emptyMonster,
          ...monster,
          loading: true,
        },
      },
    });

    // Fetch data for this monster
    axios({
      url: `${process.env.REACT_APP_API}/monsters/${monster.id}`,
      method: "GET",
      headers: { "x-auth-token": userToken },
    })
      .then((res) => {
        monsterFull = {
          ...monster,
          ...res.data,
          loading: false,
        };

        // set state to view
        generalDispatch({
          type: "SET_VIEWS",
          payload: {
            viewType: "active",
            viewCat: "monsters",
            value: monsterFull,
          },
        });

        monsterDispatch({ type: "SET_MONSTER_TO_LIST", payload: monsterFull });
      })
      .catch((error) => {
        console.log(error.response.data);
        return showToast("error", error.response.data);
      });
  }

  function searchMonsters(keyword) {
    let results = [...monsters];

    // Filters
    const filtersToApply = ['race', 'element', 'size', 'classM'];

    filtersToApply.forEach(filter => {
      if (filters[filter] !== "-1") {
        results = results.filter(item => item[filter === "classM" ? "class" : filter] === Number(filters[filter]));
      }
    });

    // Level from to
    if (
        filters.lvFrom >= 0 &&
        filters.lvTo <= 200
    ) {
      results = results.filter(({ level }) =>
          level >= Number(filters.lvFrom) && level <= Number(filters.lvTo)
      );
    }

    if (keyword !== "") {
      const normalizeKeyword = removeAccents(keyword.toString().trim().toLowerCase());

      // Search by id, name and dbName
      const searchFields = ['id', 'name', 'dbName'];

      let searchResults = searchFields.reduce((acc, field) => {
        const filteredMonsters = results.filter((monster) => {
          const normalizedMonsterField = removeAccents(monster[field]?.toString() || '').toLowerCase();
          return normalizedMonsterField.includes(normalizeKeyword);
        });
        return [...acc, ...filteredMonsters];
      }, []);

      // Remove duplicate results
      results = [...new Set(searchResults)];
    }
    return results;
  }

  function handleClickItem(e, item) {
    e.preventDefault();
    console.log(item);
  }

  const value = {
    monsters,
    viewMonster,
    monsterLoading,
    hideMonsterList,
    monsterKeyword,
    monsterKeywordDebounce,
    filters,
    filtersDebounce,
    monsterResults,

    search,
    state,
    monsterTabActive,
    foundMonsters,

    selectMonster,
    searchMonsters,
    monsterDispatch,
    handleClickItem,
  };

  return <MonsterContext.Provider value={value} {...props} />;
};

function useMonster() {
  const context = React.useContext(MonsterContext);
  if (!context) {
    throw new Error(`useMonster must be used within MonsterProvider.`);
  }
  return context;
}

export { MonsterProvider, useMonster, emptyMonster };
