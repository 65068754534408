import React, { useState, useRef } from "react";
import axios from "axios";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Table from "react-bootstrap/Table";
import Badge from "react-bootstrap/Badge";
import { SpinnerComponent } from "react-element-spinner";
import prettyMilliseconds from "pretty-ms";
import Spinner from "react-bootstrap/Spinner";
import showToast from "../../utils/showToast";

import { useGeneral } from "../../contexts/generalContext";
import { useUser } from "../../contexts/userContext";
import { useMap } from "../../contexts/mapContext";
import {
  copyText,
  numberWithCommas,
  monsterRaceNumToText,
  elementNumToText,
  monsterSizeToText,
} from "../../utils/textUtils";
import { useLocalStorageState } from "../../utils/useLocalStorageState";

const emptyMonsterToMap = {
  id: 0,
  isBoss: false,
  monster: "",
  name: "",
  x: 0,
  y: 0,
  spawn: 0,
  delay: 0,
  delay2: 0,
};

const MapDetail = ({ map, closeTab }) => {
  const { views, generalDispatch, tabActive } = useGeneral();
  const { selectMap, mapDispatch } = useMap();
  const { userToken, userProfile } = useUser();
  const [monsterText, setMonsterText] = useState("");
  const [monsterPending, setMonsterPending] = useLocalStorageState(
    "monsterToMapPending",
    emptyMonsterToMap
  );
  const activeItem = views.maps[tabActive.maps];
  const { _id, dbName, name, monsters, loading, type, saving } = activeItem;

  const searchRef = useRef();

  function getMonsterArea(x, y) {
    if (x === 0 && y === 0) return "Random";
    else return `${x} ${y}`;
  }

  function findMonster(e) {
    e.preventDefault();
    if (monsterText.trim() === "") {
      searchRef.current.focus();
      return showToast("warning", "Monster Id or Name is required!");
    }

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/monsters/search/`,
      headers: { "x-auth-token": userToken },
      data: {
        keyword: monsterText,
        fields: {
          race: -1,
          element: -1,
          size: -1,
          classM: -1,
          lvFrom: 0,
          lvTo: 200,
        },
      },
    })
      .then(({ status, data }) => {
        if (status === 200) {
          if (data.length === 0) {
            return showToast(
              "warning",
              "No monster found. Try another search!"
            );
          }

          const monster = data[0];
          const { _id, name, id } = monster;
          setMonsterPending({
            ...monsterPending,
            monster: _id,
            name: name,
            id,
            x: 0,
            y: 0,
            spawn: 1,
            delay: 0,
            delay2: 0,
            isBoss: false,
          });
        }
      })
      .catch((error) => {
        showToast("error", error);
      });
  }

  function handleChangeMonsterPending(field, value) {
    setMonsterPending({
      ...monsterPending,
      [field]: value,
    });
  }

  function handleChangeMapType(type) {
    mapDispatch({ type: "UPDATE_MAP_TYPE", payload: dbName });
    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "active",
        viewCat: "maps",
        value: {
          ...activeItem,
          saving: true,
        },
      },
    });

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/maps/update-map-type-of-map/`,
      headers: { "x-auth-token": userToken },
      data: {
        dbName,
        type,
      },
    })
      .then(({ status, data }) => {
        if (status === 200) {
          showToast("success", `Map type of ${dbName} has been updated!`);
          mapDispatch({
            type: "SET_MAP_TO_LIST",
            payload: { ...data, saving: false },
          });
          generalDispatch({
            type: "SET_VIEWS",
            payload: {
              viewType: "active",
              viewCat: "maps",
              value: {
                ...activeItem,
                ...data,
                saving: false,
              },
            },
          });
        }
      })
      .catch((e) => {
        mapDispatch({ type: "UPDATE_MAP_TYPE_FAILED", payload: dbName });
        showToast("error", e);
      });
  }

  function clearMonsterPending() {
    setMonsterText("");
    setMonsterPending(emptyMonsterToMap);
    searchRef.current.focus();
  }

  function deleteMonster(id, name) {
    const currentMonsterLength = monsters.length;
    axios({
      method: "DELETE",
      url: `${process.env.REACT_APP_API}/maps/${_id}/${id}`,
      headers: { "x-auth-token": userToken },
    })
      .then(({ data }) => {
        const newMonsterLength = data.monsters.length;
        if (currentMonsterLength === newMonsterLength + 1) {
          selectMap(views.map);
          showToast(
            "success",
            `The monster ${name} has been deleted from map ${dbName}.`
          );
        }
      })
      .catch((e) => {
        showToast("error", e);
      });
  }

  function onInsertMonsterToMap(e) {
    e.preventDefault();
    let { monster, x, y, spawn, delay, delay2, isBoss, name } = monsterPending;
    if (spawn === 0) spawn = 1;
    if (x < 0) x = 0;
    if (y < 0) y = 0;
    if (delay < 0) delay = 0;
    if (delay2 < 0) delay2 = 0;

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/maps/insert-monster-to-map/`,
      headers: { "x-auth-token": userToken },
      data: {
        dbName,
        monster,
        x,
        y,
        spawn,
        delay,
        delay2,
        isBoss,
      },
    })
      .then(({ data }) => {
        if (data.monsters.length) {
          clearMonsterPending();
          selectMap(views.map);
          showToast(
            "success",
            `The monster ${name} has been inserted to map ${dbName}.`
          );
        }
      })
      .catch((e) => {
        showToast("error", e);
      });
  }

  function printMonsterDelay(delay, delay2) {
    if (delay === 0 && delay2 === 0) return 0;
    if (delay > 0 && delay2 === 0) return prettyMilliseconds(delay);
    if (delay > 0 && delay2 > 0)
      return `${prettyMilliseconds(delay)} ~ ${prettyMilliseconds(
        delay + delay2
      )}`;
  }

  return dbName === "" || dbName !== map.dbName ? null : (
    <div className="position-relative pt-5">
      <Button
        onClick={() => closeTab(map)}
        variant="outline-danger"
        className="btn-close-tab"
        title="Close Tab"
      >
        <span role="img" aria-label="Close Tab">
          ❌
        </span>
      </Button>
      <Form.Group>
        <Row className="g-2 mb-3">
          <Col xs={12} sm={3} md={3} lg={2}>
            <Form.Label className="col-form-label">DB Name</Form.Label>
            <InputGroup>
              <FormControl
                readOnly
                type="text"
                size="sm"
                placeholder="DB Name"
                aria-label="DB Name"
                value={dbName}
              />
              <Button
                size="sm"
                title="Copy"
                onClick={() => copyText(dbName)}
                variant="outline-secondary"
              >
                <span role="img" aria-label="Copy">
                  📃
                </span>
              </Button>
            </InputGroup>
          </Col>
          <Col xs={12} md={5} lg={5}>
            <Form.Label className="col-form-label">Name</Form.Label>
            <InputGroup>
              <FormControl
                readOnly
                type="text"
                size="sm"
                placeholder="Name"
                aria-label="Name"
                value={name}
              />
              <Button
                size="sm"
                title="Copy"
                onClick={() => copyText(name)}
                variant="outline-secondary"
              >
                <span role="img" aria-label="Copy">
                  📃
                </span>
              </Button>
            </InputGroup>
          </Col>
          <Col>
            <Form.Label className="col-form-label">Map type</Form.Label>
            <div>
              <Form.Select
                disabled={!userProfile.isAdmin}
                aria-label="Map type"
                size="sm"
                value={type}
                onChange={(e) => handleChangeMapType(e.target.value)}
              >
                <option value="0">Field</option>
                <option value="1">Dungeon</option>
                <option value="2">Instance</option>
                <option value="3">Town</option>
                <option value="4">Other</option>
              </Form.Select>
            </div>
            {saving ? (
              <small className="d-flex align-items-center">
                <Spinner
                  size="sm"
                  animation="border"
                  role="status"
                  className="me-2"
                >
                  <span className="visually-hidden">Loading...</span>
                </Spinner>
                <span>Updating...</span>
              </small>
            ) : null}
          </Col>
        </Row>
      </Form.Group>

      <Form.Label className="col-form-label">Monsters</Form.Label>
      <div className="monsters-in-map position-relative">
        {monsters.length ? (
          <Table striped bordered hover>
            <thead>
              <tr>
                <th>Level</th>
                <th>Name</th>
                <th>Health</th>
                <th>ATK</th>
                <th>MATK</th>
                <th>EXP</th>
                <th>J.EXP</th>
                <th>Race</th>
                <th>Element</th>
                <th>Size</th>
                <th>DEF</th>
                <th>MDEF</th>
                <th>Area</th>
                <th>Amount</th>
                <th>Delay</th>
                <th>Type</th>
                {userProfile.isAdmin ? <th>Action</th> : null}
              </tr>
            </thead>
            <tbody>
              {monsters.map((monsterInfo, index) => {
                return (
                  <tr key={monsterInfo._id}>
                    <td>{monsterInfo.monster.level}</td>
                    <td className="position-relative">
                      {monsterInfo.monster.name}
                      {monsterInfo.isBoss ? (
                        <Badge
                          className="position-absolute end-0 me-1"
                          pill
                          bg="danger"
                        >
                          MvP
                        </Badge>
                      ) : null}
                    </td>
                    <td>{numberWithCommas(monsterInfo.monster.hp)}</td>
                    <td>{numberWithCommas(monsterInfo.monster.atk1)}</td>
                    <td>{numberWithCommas(monsterInfo.monster.atk2)}</td>
                    <td>{numberWithCommas(monsterInfo.monster.baseExp)}</td>
                    <td>{numberWithCommas(monsterInfo.monster.jobExp)}</td>
                    <td>{monsterRaceNumToText(monsterInfo.monster.race)}</td>
                    <td>{elementNumToText(monsterInfo.monster.element)}</td>
                    <td>{monsterSizeToText(monsterInfo.monster.size)}</td>
                    <td>{numberWithCommas(monsterInfo.monster.def)}</td>
                    <td>{numberWithCommas(monsterInfo.monster.mdef)}</td>
                    <td>{getMonsterArea(monsterInfo.x, monsterInfo.y)}</td>
                    <td>{monsterInfo.spawn}</td>
                    <td>
                      {printMonsterDelay(monsterInfo.delay, monsterInfo.delay2)}
                    </td>
                    <td>{monsterInfo.isBoss ? "Boss" : "Normal"}</td>
                    {userProfile.isAdmin ? (
                      <td>
                        <Button
                          size="sm"
                          variant="outline-danger"
                          onClick={() =>
                            deleteMonster(
                              monsterInfo.monster._id,
                              monsterInfo.monster.name
                            )
                          }
                        >
                          <span role="img" aria-label="Delete">
                            ❌
                          </span>
                        </Button>
                      </td>
                    ) : null}
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <p className="pt-1 pb-1">No monsters in this map</p>
        )}

        <SpinnerComponent
          loading={loading}
          position="centered"
          message="Loading..."
          backgroundColor="#cccccc"
        ></SpinnerComponent>
      </div>

      {userProfile.isAdmin ? (
        <>
          <hr />
          <h6>Add monster to map:</h6>
          <Form onSubmit={findMonster}>
            <InputGroup className="mb-3">
              <FloatingLabel
                controlId="floatingPassword"
                label="Monster Id or Name"
              >
                <Form.Control
                  ref={searchRef}
                  type="text"
                  placeholder="Monster Id or Name"
                  value={monsterText}
                  onChange={(e) => setMonsterText(e.target.value)}
                />
              </FloatingLabel>
              <Button type="submit" variant="outline-secondary">
                Find monster
              </Button>
              <Button onClick={clearMonsterPending} variant="outline-secondary">
                Clear
              </Button>
            </InputGroup>
          </Form>
        </>
      ) : null}

      {monsterPending.monster !== "" ? (
        <Form onSubmit={onInsertMonsterToMap}>
          <Row>
            <Col>
              <FloatingLabel controlId="floatingName" label="Name">
                <Form.Control
                  type="text"
                  placeholder="name"
                  value={monsterPending.name}
                  readOnly
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatingX" label="x">
                <Form.Control
                  type="number"
                  placeholder="x"
                  value={monsterPending.x}
                  onChange={(e) =>
                    handleChangeMonsterPending("x", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatingY" label="y">
                <Form.Control
                  type="number"
                  placeholder="y"
                  value={monsterPending.y}
                  onChange={(e) =>
                    handleChangeMonsterPending("y", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatSpawn" label="Spawn">
                <Form.Control
                  type="number"
                  placeholder="Spawn"
                  value={monsterPending.spawn}
                  onChange={(e) =>
                    handleChangeMonsterPending("spawn", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatDelay" label="Delay">
                <Form.Control
                  type="number"
                  placeholder="Delay"
                  value={monsterPending.delay}
                  onChange={(e) =>
                    handleChangeMonsterPending("delay", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col>
              <FloatingLabel controlId="floatDelay2" label="Delay 2">
                <Form.Control
                  type="number"
                  placeholder="Delay 2"
                  value={monsterPending.delay2}
                  onChange={(e) =>
                    handleChangeMonsterPending("delay2", e.target.value)
                  }
                />
              </FloatingLabel>
            </Col>
            <Col className="d-flex align-items-center">
              <Form.Check
                type="switch"
                id="switch-isBoss"
                label="Is boss?"
                checked={monsterPending.isBoss}
                onChange={(e) =>
                  handleChangeMonsterPending("isBoss", e.target.checked)
                }
              />
            </Col>
          </Row>
          <Row>
            <Col xs={12} className="mt-3 mb-2">
              <small>
                Insert <code>{monsterPending.name}</code> to{" "}
                <code>{dbName}</code>
              </small>
            </Col>
            <Col xs={3}>
              <img
                className="d-inline-block border p-3 me-2"
                src={`https://hahiu.com/wp-content/themes/laniakea-ro-wiki/images/monsters/${monsterPending.id}.gif`}
                alt={monsterPending.name}
              />
              <Button type="submit">Submit</Button>
            </Col>
          </Row>
        </Form>
      ) : null}
    </div>
  );
};

export default MapDetail;
