import React from "react";
import { useLocalStorageState } from "../../utils/useLocalStorageState";
import {
  dialogJoin,
  dialogSplit,
  getLines,
  printDialog,
} from "../../utils/dialogUtils";
import TextArea from "../common/TextArea";
import CopyButton, { copy } from "../common/Copy";

function SplitJoin() {
  const [textInput, setTextInput] = useLocalStorageState("splitJoinInput", "");
  const [join, setJoin] = useLocalStorageState("splitJoin", false);
  const [result, setResult] = useLocalStorageState("splitResult", "");
  const [extraRows, setExtraRows] = useLocalStorageState(
    "splitExtraRows",
    null
  );

  const handleChange = (e) => {
    setTextInput(e.target.value);
  };

  const handleConvert = async () => {
    let results = [];
    const lines = getLines(textInput);

    if (join) {
      results = dialogJoin(lines);
      setResult(printDialog(results));
    } else {
      results = dialogSplit(lines);
      setResult(printDialog(results));
    }

    setExtraRows(results);
  };

  return (
    <div className="row">
      <div className="col-5">
        <h4 className="mb-3">Paste the script below to split/join</h4>
        <div className="form-group mb-3">
          <textarea
            value={textInput}
            onChange={handleChange}
            rows="20"
            className="form-control"
          ></textarea>
        </div>
        <div className="row">
          <div className="col-6">
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="customRadioInline1"
                name="customRadioInline"
                className="form-check-input"
                checked={join}
                onChange={() => setJoin(true)}
              />
              <label className="form-check-label" htmlFor="customRadioInline1">
                Join
              </label>
            </div>
            <div className="form-check form-check-inline">
              <input
                type="radio"
                id="customRadioInline2"
                name="customRadioInline"
                className="form-check-input"
                checked={!join}
                onChange={() => setJoin(false)}
              />
              <label className="form-check-label" htmlFor="customRadioInline2">
                Split
              </label>
            </div>
          </div>
          <div className="col-6 text-end">
            <button
              onClick={handleConvert}
              className="btn btn-primary px-3"
              style={{ width: "130px" }}
            >
              {join ? "Join" : "Split"}
            </button>
          </div>
        </div>
      </div>

      <div className="col-7">
        <h4 className="mb-3">Result</h4>

        <div className="form-group mb-3">
          <TextArea
            value={result}
            rows="10"
            readOnly={true}
            style={{ background: "#fff" }}
          />
        </div>
        <div className="text-left">
          <CopyButton text={result} />
        </div>
        {extraRows && (
          <div className="mt-5">
            <h5>
              Separate <code>dialog</code>
            </h5>
            <ul className="list-group">
              {extraRows.map((row, index) => {
                return row.type === "dialog" ? (
                  <li
                    key={index}
                    className="list-group-item"
                    role="button"
                    onClick={() => copy(row.line)}
                  >
                    {row.line}
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
}

export default SplitJoin;
