import React from "react";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import _ from "lodash";
import ItemDetail from "./ItemDetail";

import { useGeneral } from "../../contexts/generalContext";
import { useItem, emptyItem } from "../../contexts/itemContext";

const ItemTabs = () => {
  const { views, tabActive, generalDispatch, clearHistories } = useGeneral();
  const { selectItem } = useItem();
  const { items } = views;
  const activeItem = items[tabActive.items];

  function setKey(dbName) {
    const item = _.find(items, (item) => item.dbName === dbName);
    if (item) selectItem(item);
  }

  function closeTab(item) {
    generalDispatch({
      type: "SET_VIEWS",
      payload: {
        viewType: "delete",
        viewCat: "items",
        value: [emptyItem],
      },
    });
    clearHistories("items", item.itemId);
  }

  return activeItem.itemId === -1 ? null : (
    <Tabs
      activeKey={activeItem.dbName}
      onSelect={(k) => setKey(k)}
      className="mb-3"
    >
      {items.map((item) => {
        return (
          <Tab
            key={`tab-${item.dbName}-${item.itemId}`}
            eventKey={item.dbName}
            title={item.dbName}
          >
            <ItemDetail item={item} closeTab={closeTab} />
          </Tab>
        );
      })}
    </Tabs>
  );
};

export default ItemTabs;
