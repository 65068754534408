import React, { useReducer, useCallback } from "react";
import axios from "axios";
import _ from "lodash";
import { useUser } from "./userContext";
import showToast from "../utils/showToast";
import Swal from "sweetalert2";
import { emptySkill } from "./skillContext";
import { emptyQuest } from "./questContext";
import { emptyItem } from "./itemContext";
import { emptyMap } from "./mapContext";
import { emptyMonster } from "./monsterContext";

const GeneralContext = React.createContext();

const generalReducer = (state, action) => {
  // default
  let viewType = "";
  let viewCat = "items";
  let viewField = "itemId";
  let viewItemCopy = [];
  let indexInViewItemCopy = 0;
  let histories = [];

  switch (action.type) {
    case "SET_VIEW_NAME":
      return { ...state, viewName: action.payload };
    case "SET_VIEWS":
      viewType = action.payload.viewType;
      viewCat = action.payload.viewCat;
      viewField = action.payload.viewField
        ? action.payload.viewField
        : "itemId";

      // check viewCat
      if (typeof state.views[viewCat] === "undefined") {
        showToast("error", `viewCat "${viewCat}" does not exist`);
        return state;
      }

      switch (viewType) {
        case "init":
          return {
            ...state,
            views: { ...state.views, [viewCat]: action.payload.value },
          };
        case "active":
          viewItemCopy = [...state.views[viewCat]];
          viewItemCopy = viewItemCopy.filter((item) => item[viewField] !== -1);

          if (viewItemCopy.length === 0)
            viewItemCopy = [...viewItemCopy, action.payload.value];

          indexInViewItemCopy = _.findIndex(viewItemCopy, (item) => {
            return item[viewField] === action.payload.value[viewField];
          });

          // Not existed in tabs
          if (indexInViewItemCopy === -1)
            indexInViewItemCopy = state.tabActive[viewCat];

          viewItemCopy[indexInViewItemCopy] = action.payload.value;

          return {
            ...state,
            views: {
              ...state.views,
              [viewCat]: viewItemCopy,
            },
            tabActive: { ...state.tabActive, [viewCat]: indexInViewItemCopy },
          };
        case "add":
          viewItemCopy = [...state.views[viewCat]];

          // Remove temp data if any
          viewItemCopy = viewItemCopy.filter((item) => item[viewField] !== -1);

          // Add item to tabs if not existed
          indexInViewItemCopy = _.findIndex(viewItemCopy, (item) => {
            return item[viewField] === action.payload.value[viewField];
          });

          if (indexInViewItemCopy === -1) {
            viewItemCopy = [...viewItemCopy, action.payload.value];
            indexInViewItemCopy = viewItemCopy.length - 1;
          }

          return {
            ...state,
            views: {
              ...state.views,
              [viewCat]: viewItemCopy,
            },
            tabActive: { ...state.tabActive, [viewCat]: indexInViewItemCopy },
          };
        case "delete":
          viewItemCopy = [...state.views[viewCat]];
          indexInViewItemCopy = state.tabActive[viewCat];
          viewItemCopy.splice(indexInViewItemCopy, 1);

          if (viewItemCopy.length === 0) {
            viewItemCopy = action.payload.value;
          } else if (indexInViewItemCopy === viewItemCopy.length) {
            indexInViewItemCopy -= 1;
          }

          return {
            ...state,
            views: {
              ...state.views,
              [viewCat]: viewItemCopy,
            },
            tabActive: { ...state.tabActive, [viewCat]: indexInViewItemCopy },
          };
        default:
          return state;
      }
    case "SET_HISTORIES":
      viewCat = action.payload.viewCat;
      histories = action.payload.value;
      let itemHistoryCopy = [...state.histories[viewCat]];
      itemHistoryCopy[action.payload.itemId] = histories;
      return {
        ...state,
        histories: {
          ...state.histories,
          [viewCat]: itemHistoryCopy,
        },
      };
    case "SET_ACTIVED_ELEMENT_ID":
      return { ...state, activedElementID: action.payload };
    case "GET_GLOBAL_TAGS":
      return { ...state, state: "loading" };
    case "GET_GLOBAL_TAGS_SUCCESS":
      return { ...state, globalTags: [...action.payload], state: "idle" };
    case "SET_SHOW_MODAL_TAGS":
      return { ...state, showModalTag: action.payload };
    default:
      return state;
  }
};

const GeneralProvider = (props) => {
  const [general, generalDispatch] = useReducer(generalReducer, {
    viewName: "",
    views: {
      items: [emptyItem],
      quests: [emptyQuest],
      skills: [emptySkill],
      maps: [emptyMap],
      monsters: [emptyMonster],
    },
    tabActive: {
      items: 0,
      quests: 0,
      skills: 0,
      maps: 0,
      monsters: 0,
    },
    histories: {
      items: [],
      quests: [],
    },
    activedElementID: "",
    globalTags: [],
    showModalTag: false,
    status: "idle",
  });

  const { viewName, views, histories, globalTags, showModalTag, tabActive } =
    general;
  const { userToken } = useUser();

  const getGlobalTags = useCallback(async () => {
    generalDispatch({ type: "GET_GLOBAL_TAGS" });
    const url = `${process.env.REACT_APP_API}/tags`;
    axios({
      method: "GET",
      url,
      headers: { "x-auth-token": userToken },
    })
      .then((res) => {
        generalDispatch({
          type: "GET_GLOBAL_TAGS_SUCCESS",
          payload: res.data,
        });
      })
      .catch((error) => {
        generalDispatch({ type: "GET_GLOBAL_TAGS_FAILED" });
        showToast("error", error);
      });
  }, [generalDispatch, userToken]);

  function controlView(type, fieldValue, list, select, field) {
    let index = _.findIndex(list, (l) => l[`${field}`] === fieldValue);
    if (type === "next") index++;
    else index--;
    if (index < 0) index = 0;
    if (index === list.length) index = list.length - 1;
    select(list[index]);
    const element = document.querySelector(`[aria-rowindex="${index + 1}"]`);
    if (element) element.focus();
  }

  function typeUrl(viewName) {
    switch (viewName) {
      case "items":
        return "item";
      case "quests":
        return "quest";
      default:
        return "item";
    }
  }

  function getHistories(viewName, data) {
    const url = `${process.env.REACT_APP_API}/${typeUrl(viewName)}Histories`;
    const { itemId } = data;
    axios({
      method: "POST",
      url,
      headers: { "x-auth-token": userToken },
      data: {
        itemId,
      },
    })
      .then((res) => {
        generalDispatch({
          type: "SET_HISTORIES",
          payload: { itemId, viewCat: viewName, value: res.data },
        });
      })
      .catch((error) => {
        showToast("error", error);
      });
  }

  function clearHistories(viewCat, itemId) {
    generalDispatch({
      type: "SET_HISTORIES",
      payload: { viewCat, itemId, value: [] },
    });
  }

  function removeHistory(viewName, history) {
    Swal.fire({
      title: "Are you sure?",
      showCancelButton: true,
      confirmButtonText: `Yes`,
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const itemId = history.id;
        axios({
          method: "DELETE",
          url: `${process.env.REACT_APP_API}/${typeUrl(viewName)}Histories/${
            history._id
          }`,
          headers: { "x-auth-token": userToken },
        })
          .then((res) => {
            showToast("success", `Deleted successfully!`);

            const newHistories = histories[viewName][itemId].filter(
              (history) => history._id !== res.data._id
            );

            generalDispatch({
              type: "SET_HISTORIES",
              payload: { viewCat: viewName, itemId, value: newHistories },
            });
          })
          .catch((error) => {
            showToast("error", error.message);
            console.log(error);
          });
      }
      return;
    });
  }

  const value = {
    views,
    viewName,
    general,
    generalDispatch,
    controlView,
    getHistories,
    removeHistory,
    clearHistories,
    globalTags,
    getGlobalTags,
    showModalTag,
    tabActive,
  };

  return <GeneralContext.Provider value={value} {...props} />;
};

function useGeneral() {
  const context = React.useContext(GeneralContext);
  if (!context) {
    throw new Error(`useContext must be used within GeneralProvider.`);
  }
  return context;
}

export { GeneralProvider, useGeneral };
