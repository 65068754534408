import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import ItemBox from "./ItemBox";
import { useItem } from "../../contexts/itemContext";
import { useUser } from "../../contexts/userContext";
import { copyText, convertKoreanToCp1258 } from "../../utils/textUtils";

const ItemPreview = ({ itemData }) => {
  const { userProfile } = useUser();

  const {
    handleChangeValue,
    replaceItemDataFrom,
    resourceNameKorean,
    setResourceNameKorean,
  } = useItem();

  let {
    identifiedDescriptionName,
    identifiedDisplayName,
    identifiedResourceName,
    unidentifiedDisplayName,
    unidentifiedResourceName,
    dbName,
    slots,
    atk,
    matk,
    def,
    mdef,
    weight,
    requiredLevel,
    classNum,
    itemCollectionImage,
    notFound,
    loading,
    server,
  } = itemData;

  function convertEncoding(string) {
    if (server === "vRO") return string;
    if (resourceNameKorean) return string;
    return convertKoreanToCp1258(string);
  }

  function handleReplaceData() {
    replaceItemDataFrom({
      identifiedDisplayName,
      identifiedResourceName: convertEncoding(identifiedResourceName),
      unidentifiedDisplayName,
      unidentifiedResourceName: convertEncoding(unidentifiedResourceName),
      dbName,
    });
  }

  return (
    <>
      <Row>
        <Col>
          <ItemBox
            loading={itemData.isNew ? false : loading}
            itemCollectionImage={itemCollectionImage}
            identifiedDisplayName={identifiedDisplayName}
            identifiedDescriptionName={identifiedDescriptionName}
            slots={slots}
          />
        </Col>
      </Row>
      <div className="footer-form">
        <Row className="g-2">
          <Col>
            <Form.Label className="col-form-label">
              Identified Display Name
            </Form.Label>

            <InputGroup size="sm">
              <FormControl
                readOnly={server !== "vRO"}
                type="text"
                placeholder="Identified Display Name"
                aria-label="Identified Display Name"
                onChange={(e) =>
                  handleChangeValue("identifiedDisplayName", e.target.value)
                }
                value={identifiedDisplayName}
              />
              <Button
                title="Copy"
                onClick={() => copyText(identifiedDisplayName)}
                variant="outline-secondary"
              >
                <span role="img" aria-label="Copy">
                  📃
                </span>
              </Button>
            </InputGroup>
          </Col>

          <Col>
            <Form.Label className="col-form-label">
              Unidentified Display Name
            </Form.Label>
            <Form.Control
              readOnly={server !== "vRO"}
              type="text"
              placeholder="Unidentified Display Name"
              size="sm"
              onChange={(e) =>
                handleChangeValue("unidentifiedDisplayName", e.target.value)
              }
              value={unidentifiedDisplayName}
            />
          </Col>
        </Row>

        <Row className="g-2">
          <Col>
            <Form.Label className="col-form-label">DB Name</Form.Label>
            <InputGroup size="sm">
              <FormControl
                readOnly={server !== "vRO"}
                type="text"
                placeholder="DB Name (EN)"
                aria-label="DB Name (EN)"
                onChange={(e) => handleChangeValue("dbName", e.target.value)}
                value={dbName}
              />
              <Button
                title="Copy"
                onClick={() => copyText(dbName)}
                variant="outline-secondary"
              >
                <span role="img" aria-label="Copy">
                  📃
                </span>
              </Button>
            </InputGroup>
          </Col>
          <Col>
            <Row className="g-2">
              <Col>
                <Form.Label className="col-form-label">Class Num</Form.Label>
                <Form.Control
                  readOnly={server !== "vRO"}
                  type="number"
                  placeholder="View ID"
                  size="sm"
                  value={classNum}
                  onChange={(e) =>
                    handleChangeValue("classNum", Number(e.target.value))
                  }
                />
              </Col>
              <Col>
                <Form.Label className="col-form-label">Req. Level</Form.Label>
                <Form.Control
                  readOnly
                  type="number"
                  placeholder="1"
                  size="sm"
                  value={requiredLevel}
                  onChange={(e) =>
                    handleChangeValue("requiredLevel", e.target.value)
                  }
                />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="g-2">
          <Col>
            <Form.Label className="col-form-label">
              Identified Resource Name
            </Form.Label>

            <InputGroup size="sm">
              <FormControl
                readOnly={server !== "vRO"}
                type="text"
                placeholder="Identified Resource Name"
                aria-label="Identified Resource Name"
                onChange={(e) =>
                  handleChangeValue("identifiedResourceName", e.target.value)
                }
                value={convertEncoding(identifiedResourceName)}
              />
              <Button
                title="Copy"
                onClick={() =>
                  copyText(convertEncoding(identifiedResourceName))
                }
                variant="outline-secondary"
              >
                <span role="img" aria-label="Copy">
                  📃
                </span>
              </Button>
            </InputGroup>
          </Col>
          <Col>
            <Form.Label className="col-form-label">
              Unidentified Resource Name
            </Form.Label>
            <Form.Control
              readOnly={server !== "vRO"}
              type="text"
              placeholder="Unidentified Resource Name"
              size="sm"
              onChange={(e) =>
                handleChangeValue("unidentifiedResourceName", e.target.value)
              }
              value={convertEncoding(unidentifiedResourceName)}
            />
          </Col>
        </Row>
        <Row className="g-2">
          <Col>
            <Form.Label className="col-form-label">ATK</Form.Label>
            <InputGroup size="sm">
              <FormControl
                readOnly
                type="text"
                placeholder="0"
                aria-label="ATK"
                onChange={(e) => handleChangeValue("atk", e.target.value)}
                value={atk}
              />
            </InputGroup>
          </Col>
          <Col>
            <Form.Label className="col-form-label">MATK</Form.Label>
            <InputGroup size="sm">
              <FormControl
                readOnly
                type="text"
                placeholder="0"
                aria-label="MATK"
                onChange={(e) => handleChangeValue("matk", e.target.value)}
                value={matk}
              />
            </InputGroup>
          </Col>

          <Col>
            <Form.Label className="col-form-label">DEF</Form.Label>
            <InputGroup size="sm">
              <FormControl
                readOnly
                type="text"
                placeholder="0"
                aria-label="DEF"
                onChange={(e) => handleChangeValue("def", e.target.value)}
                value={def}
              />
            </InputGroup>
          </Col>

          {server === "vRO" ? (
            <Col>
              <Form.Label className="col-form-label">MDEF</Form.Label>
              <InputGroup size="sm">
                <FormControl
                  readOnly
                  type="text"
                  placeholder="0"
                  aria-label="MDEF"
                  onChange={(e) => handleChangeValue("mdef", e.target.value)}
                  value={mdef}
                />
              </InputGroup>
            </Col>
          ) : null}

          <Col>
            <Form.Label className="col-form-label">Weight</Form.Label>
            <InputGroup size="sm">
              <FormControl
                readOnly={
                  server !== "vRO" || (server !== "vRO" && userProfile.isAdmin)
                }
                type="text"
                placeholder="0"
                aria-label="Weight"
                onChange={(e) =>
                  handleChangeValue("weight", e.target.value * 10)
                }
                value={server !== "vRO" ? weight : weight / 10}
              />
            </InputGroup>
          </Col>

          <Col>
            <Form.Label className="col-form-label">Slots</Form.Label>
            <InputGroup size="sm">
              <FormControl
                readOnly={
                  server !== "vRO" || (server !== "vRO" && userProfile.isAdmin)
                }
                type="text"
                placeholder="0"
                aria-label="Slots"
                onChange={(e) =>
                  handleChangeValue("slots", Number(e.target.value))
                }
                value={slots}
              />
            </InputGroup>
          </Col>
        </Row>

        {server !== "vRO" && !notFound ? (
          <Row className="g-2 mt-2">
            <Col>
              <Form.Check
                id={`switch-resource-name-encoding-${server}`}
                onChange={() => setResourceNameKorean(!resourceNameKorean)}
                checked={resourceNameKorean}
                type="switch"
                label="Resource Name Korean"
              />
            </Col>
            <Col className="text-end">
              <Button
                onClick={handleReplaceData}
                variant="outline-secondary"
                size="sm"
              >
                Replace text from {server}
              </Button>
            </Col>
          </Row>
        ) : null}
      </div>
    </>
  );
};

export default ItemPreview;
