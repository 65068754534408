import React from "react";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { useItem } from "../../contexts/itemContext";

const ItemStats = ({ bStr, bAgi, bVit, bInt, bDex, bLuk }) => {
  const { handleChangeValue } = useItem();

  return (
    <Row className="g-2">
      <Col>
        <Form.Label className="col-form-label">STR</Form.Label>
        <Form.Control
          readOnly
          type="number"
          placeholder="0"
          size="sm"
          value={bStr || 0}
          onChange={(e) => handleChangeValue("bStr", e.target.value)}
        />
      </Col>
      <Col>
        <Form.Label className="col-form-label">AGI</Form.Label>
        <Form.Control
          readOnly
          type="number"
          placeholder="0"
          size="sm"
          value={bAgi || 0}
          onChange={(e) => handleChangeValue("bAgi", e.target.value)}
        />
      </Col>
      <Col>
        <Form.Label className="col-form-label">VIT</Form.Label>
        <Form.Control
          readOnly
          type="number"
          placeholder="0"
          size="sm"
          value={bVit || 0}
          onChange={(e) => handleChangeValue("bVit", e.target.value)}
        />
      </Col>
      <Col>
        <Form.Label className="col-form-label">INT</Form.Label>
        <Form.Control
          readOnly
          type="number"
          placeholder="0"
          size="sm"
          value={bInt || 0}
          onChange={(e) => handleChangeValue("bInt", e.target.value)}
        />
      </Col>
      <Col>
        <Form.Label className="col-form-label">DEX</Form.Label>
        <Form.Control
          readOnly
          type="number"
          placeholder="0"
          size="sm"
          value={bDex || 0}
          onChange={(e) => handleChangeValue("bDex", e.target.value)}
        />
      </Col>
      <Col>
        <Form.Label className="col-form-label">LUK</Form.Label>
        <Form.Control
          readOnly
          type="number"
          placeholder="0"
          size="sm"
          value={bLuk || 0}
          onChange={(e) => handleChangeValue("bLuk", e.target.value)}
        />
      </Col>
    </Row>
  );
};

export default ItemStats;
