import showToast from "../utils/showToast";
import Swal from "sweetalert2";
import axios from "axios";

const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: "btn btn-success",
    cancelButton: "btn btn-secondary",
    denyButton: "btn btn-primary m-2",
  },
  buttonsStyling: false,
});

function downloadFile(type, encoding, token) {
  return axios({
    url: `${process.env.REACT_APP_API}/${type}/export?encoding=${encoding}`,
    method: "POST",
    headers: { "x-auth-token": token },
    responseType: "blob",
  }).catch((err) => {
    swalWithBootstrapButtons.fire("Cancelled", err.message, "error");
  });
}

function exportFile(type, token) {
  let fileName = "";
  let extension = "";

  if (type === "items") {
    fileName = "iteminfo";
    extension = "lua";
  } else if (type === "quests") {
    fileName = "questid2display";
    extension = "txt";
  } else {
    return showToast("error", "Export type is invalid!");
  }

  swalWithBootstrapButtons
    .fire({
      title: "Choose an encoding...",
      showDenyButton: true,
      showCancelButton: true,
      confirmButtonText: `CP-1258`,
      denyButtonText: `UTF-8`,
      showLoaderOnConfirm: true,
      showLoaderOnDeny: true,
      allowOutsideClick: () => !swalWithBootstrapButtons.isLoading(),
      preConfirm: function () {
        return downloadFile(type, "cp1258", token);
      },
      preDeny: function () {
        return downloadFile(type, "utf8", token);
      },
    })
    .then((result) => {
      if (result.isDismissed) return;
      const url = window.URL.createObjectURL(new Blob([result.value.data]));
      const link = document.createElement("a");
      link.href = url;
      if (result.isConfirmed) {
        link.setAttribute("download", `${fileName}_cp1258.${extension}`);
      } else {
        link.setAttribute("download", `${fileName}_new.${extension}`);
      }
      document.body.appendChild(link);
      link.click();
      link.remove();
    });
}

export default exportFile;
