import React, { useState } from "react";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Offcanvas from "react-bootstrap/Offcanvas";
import { SpinnerComponent } from "react-element-spinner";
import { Column, Table } from "react-virtualized";
import { useGeneral } from "../../contexts/generalContext";

const emptyItemTab = {
  id: "",
  name: "",
  dbName: "",
};

let defaultFilter = {
  type: "select",
  title: "Title",
  label: "Label",
  options: [
    { optionValue: "-1", optionName: "Both" },
    { optionValue: "0", optionName: "No" },
    { optionValue: "1", optionName: "Yes" },
  ],
  onChange: (e) => console.log(e.target.value),
  currentOptionValue: "-1",
  colSize: 6,
};

const List = ({
  loading,
  results,
  keyword,
  setKeyword,
  selectItem,
  filterButtonName = "Filters",
  filterTitle = "Filter Title",
  filters = [],
  columns = [],
  buttons = [],
  onClearFilters,
  addToTabs,
  tabUniqueField = "id",
}) => {
  const { views, viewName, tabActive } = useGeneral();
  const [showFilter, setShowFilter] = useState(false);
  const [pendingItemTab, setPendingItemTab] = useState(emptyItemTab);

  let newFilters = [];

  filters.forEach((filter) => {
    newFilters = [
      ...newFilters,
      {
        ...defaultFilter,
        ...filter,
      },
    ];
  });

  function rowStyleSelected(item) {
    if (typeof item === "undefined") return;
    if (
      viewName &&
      ((views[viewName][tabActive[viewName]] &&
        typeof views[viewName][tabActive[viewName]].id !== "undefined" &&
        views[viewName][tabActive[viewName]].id === item.id) ||
        (views[viewName][tabActive[viewName]] &&
          typeof views[viewName][tabActive[viewName]].dbName !== "undefined" &&
          views[viewName][tabActive[viewName]].dbName === item.dbName))
    ) {
      return { background: "#fed5d5" };
    }
  }

  function handleShowItemFilter() {
    if (loading) return;
    setShowFilter(true);
  }

  function renderOptionsFunc(func) {
    const options = func();
    return renderOptions(options);
  }

  function renderOptions(options) {
    if (typeof options !== "object") return null;
    return options.map((option) => (
      <option key={option.optionValue} value={option.optionValue}>
        {option.optionName}
      </option>
    ));
  }

  function handleClick(e, row) {
    e.preventDefault();
    const { id, name, dbName } = row;
    setPendingItemTab({
      id,
      name,
      dbName,
    });

    document.getElementById("list-context-menu").style.top =
      e.pageY - 20 + "px";
    document.getElementById("list-context-menu").style.left =
      e.pageX + 20 + "px";
  }

  function handleAddToTabs() {
    addToTabs(pendingItemTab);
    setPendingItemTab(emptyItemTab);
  }

  function handleRowClick(item) {
    selectItem(item);
    setPendingItemTab(emptyItemTab);
  }

  return (
    <>
      <div className="w-table-list-item">
        <div
          id="list-context-menu"
          className={
            pendingItemTab[tabUniqueField] === "" ? "d-none" : "d-block"
          }
        >
          <span className="me-2">
            Add <code>{pendingItemTab[tabUniqueField]}</code> to tabs
          </span>
          <Button variant="outline-primary" size="sm" onClick={handleAddToTabs}>
            ✔
          </Button>
          <Button
            onClick={() => setPendingItemTab(emptyItemTab)}
            variant="outline-danger"
            size="sm"
          >
            <span role="img" aria-label="Close">
              ❌
            </span>
          </Button>
        </div>

        <Offcanvas
          placement="start"
          show={showFilter}
          onHide={() => setShowFilter(false)}
        >
          <Offcanvas.Header closeButton>
            <Offcanvas.Title>{filterTitle}</Offcanvas.Title>
          </Offcanvas.Header>
          <Offcanvas.Body>
            <Row>
              <Col>
                <FloatingLabel
                  controlId="floatingInputKeyword"
                  label="Keyword"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Keyword"
                    value={keyword}
                    onChange={setKeyword}
                  />
                </FloatingLabel>
              </Col>
            </Row>

            <Row>
              {newFilters.map((filter, index) => (
                <Col xs={filter.colSize} key={index} className="mb-3">
                  <h6>{filter.title}</h6>
                  <FloatingLabel
                    controlId="floatingSelect"
                    label={filter.label}
                    className="mb-2"
                  >
                    {filter.type === "select" ? (
                      <Form.Select
                        aria-label={filter.label}
                        onChange={filter.onChange}
                        value={filter.currentOptionValue}
                      >
                        {typeof filter.options !== "function"
                          ? renderOptions(filter.options)
                          : renderOptionsFunc(filter.options)}
                      </Form.Select>
                    ) : (
                      <Form.Control
                        type={filter.type}
                        placeholder=""
                        value={filter.currentOptionValue}
                        onChange={filter.onChange}
                      />
                    )}
                  </FloatingLabel>
                </Col>
              ))}
            </Row>
            <div className="mb-3">
              Found: <strong>{results.length}</strong>
            </div>
            {typeof onClearFilters === "function" ? (
              <Button size="sm" onClick={onClearFilters}>
                Clear filters
              </Button>
            ) : null}
          </Offcanvas.Body>
        </Offcanvas>

        <InputGroup>
          <Form.Control
            size="sm"
            type="text"
            placeholder="Search..."
            value={keyword}
            onChange={setKeyword}
            variant="outline-secondary"
          />
          {filters.length ? (
            <Button variant="outline-secondary" onClick={handleShowItemFilter}>
              {typeof filterButtonName === "function"
                ? filterButtonName()
                : filterButtonName}
            </Button>
          ) : null}
        </InputGroup>

        <Table
          className="table-list-item"
          width={270}
          height={400}
          headerHeight={20}
          rowHeight={30}
          rowCount={results.length}
          onRowClick={({ index }) => handleRowClick(results[index])}
          onRowRightClick={({ event, index }) =>
            handleClick(event, results[index])
          }
          rowGetter={({ index }) => results[index]}
          rowStyle={({ index }) => rowStyleSelected(results[index])}
        >
          {columns.map((column, index) => (
            <Column
              key={index}
              label={column.label}
              dataKey={column.dataKey}
              width={column.width}
            />
          ))}
        </Table>
        <SpinnerComponent
          loading={loading}
          position="centered"
          message="Loading..."
        ></SpinnerComponent>

        {loading || !buttons.length ? null : (
          <div className="w-list-item-buttons">
            <Row className="g-2 p-2">
              {buttons.map((button, index) => (
                <Col key={index}>
                  <Button
                    className="w-100"
                    size="sm"
                    onClick={button.onClick}
                    variant={button.variant}
                  >
                    {button.label}
                  </Button>
                </Col>
              ))}
            </Row>
          </div>
        )}
      </div>
    </>
  );
};

export default List;
