import React from "react";
import Table from "react-bootstrap/Table";

const ItemDrops = ({ drops, sort, handleSort }) => {
  return (
    <div className="w-table-item-drops">
      <Table className="table-hover mb-0">
        <thead>
          <tr>
            <th>
              <span role="button" onClick={() => handleSort("id")}>
                ID {sort.by === "id" ? (sort.type === "asc" ? "▴" : "▾") : null}
              </span>
            </th>
            <th>
              <span role="button" onClick={() => handleSort("name")}>
                Monster{" "}
                {sort.by === "name" ? (sort.type === "asc" ? "▴" : "▾") : null}
              </span>
            </th>
            <th>
              <span role="button" onClick={() => handleSort("chance")}>
                %{" "}
                {sort.by === "chance"
                  ? sort.type === "asc"
                    ? "▴"
                    : "▾"
                  : null}
              </span>
            </th>
          </tr>
        </thead>
        <tbody>
          {drops.map((drop, index) => {
            return (
              <tr key={`${drop.id}-${index}`}>
                <td>{drop.id}</td>
                <td>
                  {drop.isMvPDrop ? (
                    <span className="badge bg-danger me-1">MVP!</span>
                  ) : null}
                  <span>{drop.name ? drop.name : drop.dbName}</span>
                </td>
                <td>{drop.chance / 100}</td>
              </tr>
            );
          })}
        </tbody>
      </Table>
    </div>
  );
};

export default ItemDrops;
