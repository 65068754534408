import React from "react";
import Table from "react-bootstrap/Table";

import iconDrop from "../../images/icon-drop.svg";
import iconTrade from "../../images/icon-trade.svg";
import iconStorage from "../../images/icon-storage.svg";
import iconCart from "../../images/icon-cart.svg";
import iconSell from "../../images/icon-sell.svg";
import iconMail from "../../images/icon-mail.svg";
import iconAuction from "../../images/icon-auction.svg";
import iconGuildStorage from "../../images/icon-guild-storage.svg";

const ItemMoveInfoV2 = ({ itemMoveInfo }) => {
  const variables = [
    "drop",
    "trade",
    "storage",
    "cart",
    "sell",
    "mail",
    "auction",
    "guildStore",
  ];

  return (
    <div className="table-responsive">
      <Table className="table-itemMoveInfo mb-0" striped bordered hover>
        <thead>
          <tr>
            <th>
              <img src={iconDrop} title="Drop" alt="" />
            </th>
            <th>
              <img src={iconTrade} title="Trade" alt="" />
            </th>
            <th>
              <img src={iconStorage} title="Storage" alt="" />
            </th>
            <th>
              <img src={iconCart} title="Cart" alt="" />
            </th>
            <th>
              <img src={iconSell} title="Sell" alt="" />
            </th>
            <th>
              <img src={iconMail} title="Mail" alt="" />
            </th>
            <th>
              <img src={iconAuction} title="Auction" alt="" />
            </th>
            <th>
              <img src={iconGuildStorage} title="Guild Storage" alt="" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr>
            {variables.map((variable) => {
              if (!itemMoveInfo) {
                return (
                  <td key={variable}>
                    <span role="img" aria-label={variable}>
                      ✅
                    </span>
                  </td>
                );
              }

              return (
                <td key={variable}>
                  {itemMoveInfo[variable] ? (
                    <span role="img" aria-label={variable}>
                      ✅
                    </span>
                  ) : (
                    <span role="img" aria-label={variable}>
                      ❌
                    </span>
                  )}
                </td>
              );
            })}
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default ItemMoveInfoV2;
