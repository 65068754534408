import React from "react";
import Form from "react-bootstrap/Form";
import { useItem } from "../../contexts/itemContext";

const ItemType = ({ typeId }) => {
  const { handleChangeValue } = useItem();

  return (
    <>
      <Form.Label className="col-form-label">Type</Form.Label>
      <Form.Select
        size="sm"
        value={typeId || ""}
        onChange={(e) => handleChangeValue("typeId", e.target.value)}
      >
        <option value="0">Heal</option>
        <option value="1">Armor</option>
        <option value="2">ArmorTB</option>
        <option value="3">ArmorTM</option>
        <option value="4">ArmorMB</option>
        <option value="5">ArmorTMB</option>
        <option value="6">Weapon</option>
        <option value="7">Bow</option>
        <option value="8">Gun</option>
        <option value="9">Both-hand</option>
        <option value="10">Ammo</option>
        <option value="11">Arrow</option>
        <option value="12">Throw Weapon</option>
        <option value="13">Cannon Ball</option>
        <option value="14">Card</option>
        <option value="15">Event</option>
        <option value="16">Cash Point Item</option>
        <option value="17">Special</option>
      </Form.Select>
    </>
  );
};

export default ItemType;
