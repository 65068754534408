import React, { useEffect } from "react";
import axios from "axios";
import _ from "lodash";

import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

import { useGeneral } from "../../contexts/generalContext";
import { useUser } from "../../contexts/userContext";
import { useMonster, emptyMonster } from "../../contexts/monsterContext";
import Swal from "sweetalert2";
// import showToast from "../../utils/showToast";

import List from "../common/List";
import ItemNavigation from "../common/ItemNavigation";
import MonsterTabs from "./MonsterTabs";

import {elementNumToText, monsterRaceNumToText} from "../../utils/textUtils";

const Monster = () => {
  const {
    views,
    generalDispatch,
    globalTags,
    getGlobalTags,
    tabActive,
  } = useGeneral();
  const { userToken, setUserToken } = useUser();
  const activeItem = views.monsters[tabActive.monsters];

  React.useLayoutEffect(() => {
    generalDispatch({ type: "SET_VIEW_NAME", payload: "monsters" });
    getGlobalTags();
  }, [generalDispatch, getGlobalTags]);

  const {
    monsters,
    hideMonsterList,
    monsterLoading,
    monsterKeyword,
    monsterKeywordDebounce,
    filters,
    filtersDebounce,
    monsterResults,

    // functions
    selectMonster,
    searchMonsters,
    monsterDispatch,
  } = useMonster();

  const columns = [
    {
      label: "id",
      dataKey: "id",
      width: 120,
    },
    {
      label: "dbName",
      dataKey: "dbName",
      width: 200,
    },
  ];

  const buttons = [];

  const listFilters = [
    {
      title: "Race",
      label: "Race",
      options: raceOptions,
      onChange: (e) =>
        monsterDispatch({
          type: "SET_MONSTER_FILTERS",
          payload: { race: e.target.value },
        }),
      currentOptionValue: filters.race,
    },
    {
      title: "Element",
      label: "Element",
      options: elementOptions,
      onChange: (e) =>
        monsterDispatch({
          type: "SET_MONSTER_FILTERS",
          payload: { element: e.target.value },
        }),
      currentOptionValue: filters.element,
    },
    {
      title: "Size",
      label: "Size",
      options: sizeOptions,
      onChange: (e) =>
        monsterDispatch({
          type: "SET_MONSTER_FILTERS",
          payload: { size: e.target.value },
        }),
      currentOptionValue: filters.size,
    },
    {
      title: "Class",
      label: "Class",
      options: classOptions,
      onChange: (e) =>
        monsterDispatch({
          type: "SET_MONSTER_FILTERS",
          payload: { classM: e.target.value },
        }),
      currentOptionValue: filters.classM,
    },
    {
      type: "number",
      title: "Level from",
      label: "Level from",
      onChange: (e) =>
        monsterDispatch({
          type: "SET_MONSTER_FILTERS",
          payload: { lvFrom: e.target.value },
        }),
      currentOptionValue: filters.lvFrom,
    },
    {
      type: "number",
      title: "Level to",
      label: "Level to",
      onChange: (e) =>
        monsterDispatch({
          type: "SET_MONSTER_FILTERS",
          payload: { lvTo: e.target.value },
        }),
      currentOptionValue: filters.lvTo,
    },
    // {
    //   title: `Tag`,
    //   label: "Tag",
    //   options: tagOptions,
    //   onChange: (e) =>
    //     monsterDispatch({
    //       type: "SET_MONSTER_FILTERS",
    //       payload: { tagId: e.target.value },
    //     }),
    //   currentOptionValue: filters.tagId,
    //   colSize: 12,
    // },
  ];

  function raceOptions() {
    let options = [{ optionValue: "-1", optionName: "All Races" }];
    for (let i = 0; i <= 9; i++) {
      options = [
        ...options,
        { optionValue: `${i}`, optionName: `${monsterRaceNumToText(i)}` },
      ];
    }
    return options;
  }

  function elementOptions() {
    let options = [{ optionValue: "-1", optionName: "All Elements" }];
    for (let i = 0; i <= 9; i++) {
      options = [
        ...options,
        { optionValue: `${i}`, optionName: `${elementNumToText(i)}` },
      ];
    }
    return options;
  }

  function sizeOptions() {
    return [
      { optionValue: "-1", optionName: "All Sizes" },
      { optionValue: "0", optionName: "Small" },
      { optionValue: "1", optionName: "Medium" },
      { optionValue: "2", optionName: "Large" },
    ];
  }

  function classOptions() {
    return [
      { optionValue: "-1", optionName: "All Classes" },
      { optionValue: "0", optionName: "Normal" },
      { optionValue: "1", optionName: "Boss" },
      { optionValue: "2", optionName: "Guardian" },
    ];
  }

  // function tagOptions() {
  //   let options = [{ optionValue: "-1", optionName: "None" }];
  //   globalTags.forEach((tag) => {
  //     options = [
  //       ...options,
  //       { optionValue: `${tag._id}`, optionName: `${tag.name}` },
  //     ];
  //   });
  //   return options;
  // }

  function getFilterButtonName() {
    let tagName = "";

    if (filters.tagId === "-1") return "All";
    if (filters.tagId !== "-1") {
      const tag = globalTags
        .filter((t) => {
          return t._id === filters.tagId;
        })
        .pop();
      tagName = tag.name;
    }

    return `${tagName}`;
  }

  function addMonsterToTabs({ id, name }) {
    const monster = _.find(monsters, (monster) => monster.id === id);

    // show tab if item existed on tabs
    const monsterTabIndex = _.findIndex(
      views.monsters,
      (monster) => monster.id === id
    );

    if (monsterTabIndex === -1) {
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "add",
          viewCat: "monsters",
          value: monster,
        },
      });
    }

    selectMonster(monster);
  }

  function clearMonsterFilters() {
    monsterDispatch({ type: "CLEAR_MONSTER_FILTERS" });
  }

  // Fetch all monsters
  useEffect(() => {
    if (monsters.length === 0 && !monsterLoading) {
      monsterDispatch({ type: "FETCH_MONSTERS" });
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "init",
          viewCat: "monsters",
          value: [emptyMonster],
        },
      });

      // List
      axios({
        url: `${process.env.REACT_APP_API}/monsters`,
        method: "GET",
        headers: { "x-auth-token": userToken },
      })
        .then((res) => {
          monsterDispatch({
            type: "FETCH_MONSTERS_SUCCESS",
            payload: res.data,
          });
        })
        .catch((error) => {
          if (
            typeof error.response !== "undefined" &&
            error.response.status === 401
          ) {
            Swal.fire({
              icon: "error",
              title: error.response.statusText,
              text: error.response.data,
              confirmButtonText: `Login`,
            }).then(() => {
              setUserToken("");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: error.name,
              text: error.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Search
  useEffect(() => {
    if (monsters.length === 0) return;
    const results = searchMonsters(monsterKeywordDebounce);
    monsterDispatch({ type: "SET_MONSTER_RESULTS", payload: results });
    // eslint-disable-next-line
  }, [monsterKeywordDebounce, JSON.stringify(filtersDebounce)]);

  return (
    <>
      <Row>
        <Col
          md={3}
          lg={2}
          className={`col-table-list-item mb-3 mb-md-0 ${
            hideMonsterList ? "d-none" : ""
          }`}
        >
          <List
            loading={monsterLoading}
            results={monsterResults}
            keyword={monsterKeyword}
            setKeyword={(e) =>
              monsterDispatch({
                type: "SET_MONSTER_KEYWORD",
                payload: e.target.value,
              })
            }
            selectItem={selectMonster}
            columns={columns}
            buttons={buttons}
            filterTitle="Monster Filters"
            filters={listFilters}
            filterButtonName={getFilterButtonName}
            addToTabs={addMonsterToTabs}
            tabUniqueField="dbName"
            onClearFilters={clearMonsterFilters}
          />
        </Col>
        <Col md={hideMonsterList ? 12 : 9} lg={hideMonsterList ? 12 : 10}>
          <MonsterTabs />
        </Col>
      </Row>

      {activeItem ? (
        <ItemNavigation
          id={activeItem.id}
          name={activeItem.name}
          isHide={hideMonsterList}
          setHide={() =>
            monsterDispatch({
              type: "SET_HIDE_MONSTER_LIST",
              payload: !hideMonsterList,
            })
          }
          list={monsterResults}
          select={selectMonster}
        />
      ) : null}
    </>
  );
};

export default Monster;
