import React from "react";
import axios from "axios";
import { useLocalStorageState } from "../utils/useLocalStorageState";

const initUserProfile = {
  id: "",
  email: "",
  isAdmin: false,
};

const UserContext = React.createContext();

const UserProvider = (props) => {
  const [userToken, setUserToken] = useLocalStorageState("roToolsToken", "");
  const [userProfile, setUserProfile] = useLocalStorageState(
    "roToolsUserProfile",
    initUserProfile
  );

  function logout() {
    setUserToken("");
  }

  React.useEffect(() => {
    if (userToken === "") return setUserProfile(initUserProfile);
    if (userProfile.id !== "") return;

    axios({
      url: `${process.env.REACT_APP_API}/users/me/`,
      method: "GET",
      headers: { "x-auth-token": userToken },
    })
      .then((res) => {
        setUserProfile(res.data);
      })
      .catch((err) => {
        setUserToken("");
        console.log(err);
        return false;
      });
  }, [userToken, userProfile.id, setUserProfile, setUserToken]);

  const value = {
    logout,
    userToken,
    setUserToken,
    userProfile,
    setUserProfile,
  };

  return <UserContext.Provider value={value} {...props} />;
};

function useUser() {
  const context = React.useContext(UserContext);
  if (!context) {
    throw new Error(`useUser must be used within UserProvider.`);
  }
  return context;
}

export { UserProvider, useUser };
