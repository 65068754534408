import React, { useCallback } from "react";
import _ from "lodash";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";
import TextArea from "../common/TextArea";
import QuestPreviewContainer from "../quest/QuestPreviewContainer";
import ColorButtons from "../common/ColorButtons";
import { useGeneral } from "../../contexts/generalContext";
import { useQuest } from "../../contexts/questContext";
import { copyText } from "../../utils/textUtils";
import Tags from "../common/Tags";

const QuestDetail = ({ quest, closeTab }) => {
  const { generalDispatch, views, tabActive } = useGeneral();
  const { id, name, description, isNew, tags, date } = quest;

  const activeItem = views.quests[tabActive.quests];
  const { id: activeQuestId } = activeItem;

  const {
    handleSubmitQuest,
    handleChangeQuestValue,
    notSavedQuests,
    selectQuest,
  } = useQuest();

  // No enter on quest description
  function handleKeyPress(event) {
    if (event.charCode === 13) {
      event.preventDefault();
    }
  }

  function isQuestChanged() {
    return Boolean(
      _.findIndex(notSavedQuests, (notSavedQuest) => notSavedQuest.id === id) >
        -1
    );
  }

  const handleChangeTags = useCallback(
    (tags) => {
      handleChangeQuestValue("tags", tags);
    },
    [handleChangeQuestValue]
  );

  return id === -1 || id !== activeQuestId ? null : (
    <Form className="w-row-item" onSubmit={handleSubmitQuest}>
      <Button
        onClick={() => closeTab(quest)}
        variant="outline-danger"
        className="btn-close-tab"
        title="Close Tab"
      >
        <span role="img" aria-label="Close Tab">
          ❌
        </span>
      </Button>
      <strong>
        Last Update:{" "}
        {date ? new Date(date).toLocaleDateString() : "Updating..."}
      </strong>
      <Tags
        tags={tags}
        modalTagName={name}
        handleChangeTags={handleChangeTags}
      />
      <hr />
      <Row>
        <Col className="mb-3 mb-lg-0" lg={7}>
          <Form.Group className="mb-3">
            <Row>
              <Col md={2}>
                <Form.Label className="col-form-label">ID</Form.Label>

                <InputGroup size="sm">
                  <FormControl
                    readOnly
                    type="text"
                    placeholder="Quest ID"
                    aria-label="Quest ID"
                    onChange={(e) =>
                      handleChangeQuestValue("id", e.target.value)
                    }
                    value={id}
                  />
                  <Button
                    title="Copy"
                    onClick={() => copyText(id)}
                    variant="outline-secondary"
                  >
                    <span role="img" aria-label="Copy">
                      📃
                    </span>
                  </Button>
                </InputGroup>
              </Col>
            </Row>
          </Form.Group>

          <QuestPreviewContainer />
        </Col>
        <Col lg={5}>
          <Form.Group className="mb-3">
            <Form.Label className="col-form-label">Description</Form.Label>
            <TextArea
              onFocus={() =>
                generalDispatch({
                  type: "SET_ACTIVED_ELEMENT_ID",
                  payload: "quest-description-control",
                })
              }
              id="quest-description-control"
              placeholder="Description"
              rows="7"
              value={description ? JSON.parse(description) : ""}
              onKeyPress={(e) => handleKeyPress(e)}
              onChange={(e) =>
                handleChangeQuestValue("description", e.target.value)
              }
            />
          </Form.Group>
          <ColorButtons />
          <hr />
          {isQuestChanged() ? (
            <Row className="g-2">
              <Col className="d-grid">
                {!isNew ? (
                  <Button type="submit" variant="success">
                    Save changes
                  </Button>
                ) : (
                  <Button type="submit" variant="primary">
                    Add New Quest {id}
                  </Button>
                )}
              </Col>
              <Col className="d-grid">
                <Button onClick={() => selectQuest(quest, true)} variant="dark">
                  Discard changes
                </Button>
              </Col>
            </Row>
          ) : null}
        </Col>
      </Row>
    </Form>
  );
};

export default QuestDetail;
