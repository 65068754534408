import React from "react";
import axios from "axios";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import QuestTabs from "./QuestTabs";
import ItemHistories from "../common/ItemHistories";
import ItemNavigation from "../common/ItemNavigation";
import List from "../common/List";
import { useGeneral } from "../../contexts/generalContext";
import { useUser } from "../../contexts/userContext";
import { useQuest, emptyQuest } from "../../contexts/questContext";

const Quest = (props) => {
  const {
    generalDispatch,
    views,
    globalTags,
    getGlobalTags,
    tabActive,
  } = useGeneral();
  const { userToken, setUserToken } = useUser();

  const {
    questMain,
    questDispatch,
    questKeyword,
    filters,
    selectQuest,
    handleNewQuest,
    handleExportQuest,
    questKeywordDebounce,
    replaceQuestDataFrom,
    searchQuests,
  } = useQuest();

  const { quests, questResults, questLoading, hideQuestList } = questMain;
  const activeItem = views.quests[tabActive.quests];

  React.useLayoutEffect(() => {
    generalDispatch({ type: "SET_VIEW_NAME", payload: "quests" });
    getGlobalTags();
  }, [generalDispatch, getGlobalTags]);

  // Search quests
  React.useEffect(() => {
    if (quests.length === 0) return;
    const results = searchQuests(questKeywordDebounce);
    questDispatch({ type: "SET_QUEST_RESULTS", payload: results });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [questKeywordDebounce, quests, questDispatch, filters.tagId]);

  // Fetch quests
  React.useEffect(() => {
    if (quests.length === 0 && !questLoading) {
      questDispatch({ type: "SET_QUEST_LOADING", payload: true });
      generalDispatch({
        type: "SET_VIEWS",
        payload: { viewType: "init", viewCat: "quests", value: [emptyQuest] },
      });

      // List of quests
      axios({
        url: `${process.env.REACT_APP_API}/quests`,
        method: "GET",
        headers: { "x-auth-token": userToken },
      })
        .then((res) => {
          questDispatch({ type: "FETCH_QUESTS_SUCCESS", payload: res.data });
          questDispatch({ type: "SET_QUEST_RESULTS", payload: res.data });
        })
        .catch((error) => {
          if (
            typeof error.response !== "undefined" &&
            error.response.status === 401
          ) {
            Swal.fire({
              icon: "error",
              title: error.response.statusText,
              text: error.response.data,
              confirmButtonText: `Login`,
            }).then(() => {
              setUserToken("");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: error.name,
              text: error.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = [
    {
      label: "ID",
      dataKey: "id",
      width: 80,
    },
    {
      label: "Name",
      dataKey: "name",
      width: 220,
    },
  ];

  const buttons = [
    {
      label: "New Quest",
      variant: "primary",
      onClick: () => handleNewQuest(),
    },
    {
      label: "Export Quest",
      variant: "info",
      onClick: () => handleExportQuest(),
    },
  ];

  const listFilters = [
    {
      title: `Tag`,
      label: "Tag",
      options: tagOptions,
      onChange: (e) =>
        questDispatch({
          type: "SET_QUEST_FILTERS",
          payload: { tagId: e.target.value },
        }),
      currentOptionValue: filters.tagId,
    },
  ];

  function tagOptions() {
    let options = [{ optionValue: "-1", optionName: "none" }];
    globalTags.forEach((tag) => {
      options = [
        ...options,
        { optionValue: `${tag._id}`, optionName: `${tag.name}` },
      ];
    });
    return options;
  }

  function addQuestToTabs({ id, name }) {
    const quest = _.find(quests, (quest) => quest.id === id);

    // show tab if quest existed on tabs
    const questTabIndex = _.findIndex(views.quests, (quest) => quest.id === id);

    if (questTabIndex === -1) {
      generalDispatch({
        type: "SET_VIEWS",
        payload: {
          viewType: "add",
          viewCat: "quests",
          value: quest,
        },
      });
    }

    selectQuest(quest);
  }

  return (
    <>
      <Row>
        <Col
          md={3}
          lg={2}
          className={`col-table-list-item mb-3 mb-md-0 ${
            hideQuestList ? "d-none" : ""
          }`}
        >
          <List
            loading={questLoading}
            results={questResults}
            keyword={questKeyword}
            setKeyword={(e) =>
              questDispatch({
                type: "SET_QUEST_KEYWORD",
                payload: e.target.value,
              })
            }
            selectItem={selectQuest}
            columns={columns}
            buttons={buttons}
            filterTitle="Quest Filters"
            filters={listFilters}
            addToTabs={addQuestToTabs}
          />
          <ItemHistories
            replaceDataFrom={replaceQuestDataFrom}
            id={activeItem.id}
          />
        </Col>
        <Col md={hideQuestList ? 12 : 9} lg={hideQuestList ? 12 : 10}>
          <QuestTabs />
        </Col>
      </Row>

      {activeItem ? (
        <ItemNavigation
          id={activeItem.id}
          name={activeItem.name}
          isHide={hideQuestList}
          setHide={() =>
            questDispatch({
              type: "SET_HIDE_QUEST_LIST",
              payload: !hideQuestList,
            })
          }
          list={questResults}
          select={selectQuest}
        />
      ) : null}
    </>
  );
};

export default Quest;
