import React, { useReducer, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import TextArea from "./common/TextArea";
import showToast from "../utils/showToast";
import axios from "axios";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs/components/prism-core";
import "prismjs/components/prism-lua";
import "prismjs/themes/prism.css";
import { SpinnerComponent } from "react-element-spinner";
import { useUser } from "../contexts/userContext";

const exampleQuest = `
1#Title#SG_FEEL#QUE_NOIMAGE#
Description#
Short Description#

300#Title 2#SG_FEEL#QUE_NOIMAGE#
Description 2#
Short Description 2#
`;

const importReducer = (state, action) => {
  switch (action.type) {
    case "SET_IMPORT_DATA":
      return { ...state, ...action.payload };
    case "IMPORTING":
      return {
        ...state,
        importLoading: true,
        result: "",
        error: "",
        allowSubmit: false,
      };
    case "IMPORT_SUCCESS":
      return {
        ...state,
        importLoading: false,
        allowSubmit: true,
        code: "",
        error: "",
        result: action.payload,
      };
    case "IMPORT_FAILED":
      return {
        ...state,
        importLoading: false,
        allowSubmit: true,
        result: "",
        error: action.payload,
      };
    case "SET_EXAMPLE_CODE":
      return { ...state, exampleCode: action.payload };
    default:
      return { ...state };
  }
};

const Import = () => {
  const [mainImport, importDispatch] = useReducer(importReducer, {
    code: "",
    modal: "items",
    database: "1",
    val1: "",
    importLoading: false,
    allowSubmit: true,
    result: "",
    error: "",
    exampleCode: "",
  });

  const {
    code,
    modal,
    database,
    val1,
    allowSubmit,
    importLoading,
    result,
    error,
    exampleCode,
  } = mainImport;

  const { userToken } = useUser();

  useEffect(() => {
    if (result !== "") {
      showToast("success", result);
    } else if (error !== "") {
      showToast("error", error);
    }
  }, [result, error]);

  useEffect(() => {
    switch (database) {
      case "6":
        return importDispatch({
          type: "SET_EXAMPLE_CODE",
          payload: exampleQuest,
        });
      default:
        return importDispatch({ type: "SET_EXAMPLE_CODE", payload: "" });
    }
  }, [database]);

  function setFormData(value) {
    importDispatch({ type: "SET_IMPORT_DATA", payload: { ...value } });
  }

  function onSubmit(e) {
    e.preventDefault();

    if (!allowSubmit)
      return showToast("warning", "The other task is on progress...");

    if (code.trim().length === 0)
      return showToast("warning", "Code should not empty.");

    if (database === "")
      return showToast("warning", "Database type shoud not empty.");

    if (database === "3" && val1 === "")
      return showToast("warning", "Item type should not empty.");

    importDispatch({ type: "IMPORTING" });

    axios({
      method: "POST",
      url: `${process.env.REACT_APP_API}/${modal}/import-by-code`,
      headers: { "x-auth-token": userToken },
      data: {
        database,
        code: JSON.stringify(code),
        val1,
      },
    })
      .then(({ data }) => {
        importDispatch({ type: "IMPORT_SUCCESS", payload: data.message });
      })
      .catch((error) => {
        console.log(error);
        importDispatch({ type: "IMPORT_FAILED", payload: error.message });
      });
  }

  return (
    <div>
      <h3>Import</h3>
      <hr />
      <Container fluid>
        <Form onSubmit={onSubmit}>
          <Row>
            <Col md={3}>
              <Form.Label className="col-form-label">Database Type</Form.Label>
              <Form.Group className="mb-3">
                <Form.Check
                  type="radio"
                  label="Item Contain"
                  id="radio-item-contain"
                  value="1"
                  name="database"
                  checked={database === "1"}
                  onChange={(e) =>
                    setFormData({ modal: "items", database: e.target.value })
                  }
                />
                <Form.Check
                  type="radio"
                  label="Item Move"
                  id="radio-item-move"
                  name="database"
                  value="2"
                  checked={database === "2"}
                  onChange={(e) =>
                    setFormData({ modal: "items", database: e.target.value })
                  }
                />
                <Form.Check
                  type="radio"
                  label="Item"
                  id="radio-item"
                  name="database"
                  value="3"
                  checked={database === "3"}
                  onChange={(e) =>
                    setFormData({ modal: "items", database: e.target.value })
                  }
                />
                <Form.Check
                  type="radio"
                  label="Monster Making Item"
                  id="radio-monmakingitem"
                  name="database"
                  value="4"
                  checked={database === "4"}
                  onChange={(e) =>
                    setFormData({ modal: "monsters", database: e.target.value })
                  }
                />
                <Form.Check
                  type="radio"
                  label="Monster Parameter"
                  id="radio-monster-parameter"
                  name="database"
                  value="5"
                  checked={database === "5"}
                  onChange={(e) =>
                    setFormData({ modal: "monsters", database: e.target.value })
                  }
                />
                <Form.Check
                  type="radio"
                  label="Monster Define (mobdef.sc)"
                  id="radio-monster-def"
                  name="database"
                  value="7"
                  checked={database === "7"}
                  onChange={(e) =>
                    setFormData({ modal: "monsters", database: e.target.value })
                  }
                />
                <Form.Check
                  type="radio"
                  label="MvP Drops (MVP.txt)"
                  id="radio-mvp"
                  name="database"
                  value="8"
                  checked={database === "8"}
                  onChange={(e) =>
                    setFormData({ modal: "monsters", database: e.target.value })
                  }
                />
                <Form.Check
                  type="radio"
                  label="Quest"
                  id="radio-quest-parameter"
                  name="database"
                  value="6"
                  checked={database === "6"}
                  onChange={(e) =>
                    setFormData({ modal: "quests", database: e.target.value })
                  }
                />
              </Form.Group>
            </Col>
            <Col className="position-relative">
              {database === "3" ? (
                <Form.Group className="mb-3">
                  <FloatingLabel controlId="floatingSelect" label="Item Type">
                    <Form.Select
                      aria-label="Choose a type"
                      value={val1}
                      onChange={(e) => setFormData({ val1: e.target.value })}
                    >
                      <option value="">Choose a type</option>
                      <option value="heal">Heal</option>
                      <option value="armor">Armor</option>
                      <option value="armorTB">ArmorTB</option>
                      <option value="armorTM">ArmorTM</option>
                      <option value="armorTMB">ArmorTMB</option>
                      <option value="armorMB">ArmorMB</option>
                      <option value="weapon">Weapon</option>
                      <option value="bothhand">Both-hand</option>
                      <option value="bow">Bow</option>
                      <option value="gun">Gun</option>
                      <option value="card">Card</option>
                      <option value="special">Special</option>
                      <option value="event">Event</option>
                      <option value="CashPointItem">Cash Point Item</option>
                    </Form.Select>
                  </FloatingLabel>
                </Form.Group>
              ) : null}

              <Form.Group className="mb-3">
                <div className="mb-3">
                  <Form.Label className="col-form-label">Code</Form.Label>
                  <TextArea
                    id="code-content"
                    placeholder="Insert code here..."
                    rows="10"
                    value={code}
                    onChange={(e) => setFormData({ code: e.target.value })}
                  />
                </div>

                <div className="mb-3">
                  <Button type="submit">Submit</Button>
                </div>

                {exampleCode !== "" ? (
                  <>
                    <Form.Label className="col-form-label">
                      Format of <code>{modal}</code>:
                    </Form.Label>
                    <Editor
                      className="editor-code"
                      readOnly={true}
                      value={exampleCode}
                      highlight={() =>
                        highlight(exampleCode, languages.lua, "lua")
                      }
                      padding={10}
                      style={{
                        fontFamily: '"Fira code", "Fira Mono", monospace',
                        fontSize: 14,
                      }}
                    />
                  </>
                ) : null}
              </Form.Group>

              <SpinnerComponent
                loading={importLoading}
                position="centered"
                message="Importing..."
              ></SpinnerComponent>
            </Col>
          </Row>
        </Form>
      </Container>
    </div>
  );
};

export default Import;
