import React, { useCallback, useEffect } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import InputGroup from "react-bootstrap/InputGroup";
import FormControl from "react-bootstrap/FormControl";

import ItemPreviewContainer from "../item/ItemPreviewContainer";
import ItemStats from "./ItemStats";
import ItemMoveInfo from "./ItemMoveInfo";
import ItemType from "./ItemType";

import { useGeneral } from "../../contexts/generalContext";
import { useItem } from "../../contexts/itemContext";
import { useUser } from "../../contexts/userContext";

import { copyText } from "../../utils/textUtils";
import Tags from "../common/Tags";

const ItemDetail = ({ item, closeTab }) => {
  const { views, tabActive } = useGeneral();

  const {
    itemFormRef,
    // jobOptions,
    // handleJobs,
    handleSubmitItem,
    handleChangeValue,
    itemDispatch,
    fetchItemMonstersDrops,
    isItemChanged,
    isEquipment,
    itemLocationToName,
  } = useItem();

  const { userProfile } = useUser();
  const { itemId, typeId, latestPackageItem, date, tags, location } = item;
  const activeItem = views.items[tabActive.items];
  const { itemId: activeItemId } = activeItem;

  const handleChangeTags = useCallback(
    (tags) => {
      handleChangeValue("tags", tags);
    },
    [handleChangeValue]
  );

  const onKeyDown = useCallback(
    (event) => {
      if (event.ctrlKey && event.key === "Enter" && itemFormRef.current) {
        if (!isItemChanged(itemId)) return;
        itemFormRef.current.dispatchEvent(
          new Event("submit", { cancelable: true, bubbles: true })
        );
      }
    },
    [itemFormRef, isItemChanged, itemId]
  );

  useEffect(() => {
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [itemFormRef, onKeyDown]);

  if (itemId !== activeItemId) return null;

  return (
    <Form className="w-row-item" ref={itemFormRef} onSubmit={handleSubmitItem}>
      <Button
        onClick={() => closeTab(item)}
        variant="outline-danger"
        className="btn-close-tab"
        title="Close Tab"
      >
        <span role="img" aria-label="Close">
          ❌
        </span>
      </Button>

      <strong>
        Last Update:{" "}
        {date ? new Date(date).toLocaleDateString() : "Updating..."}
      </strong>

      <Button
        size="sm"
        className="ms-3"
        variant="outline-primary"
        onClick={() =>
          itemDispatch({ type: "HANDLE_ITEM_CONTAIN", payload: true })
        }
      >
        Package Item ({latestPackageItem ? latestPackageItem.length : 0})
      </Button>

      <Button
        size="sm"
        className="ms-3"
        variant="outline-primary"
        onClick={fetchItemMonstersDrops}
      >
        Monster Drops
      </Button>

      <Tags
        tags={tags}
        modalTagName={item.dbName}
        handleChangeTags={handleChangeTags}
      />

      <hr />
      <Row>
        <Col xs={12} sm={8}>
          <Row className="g-2">
            <Col xs={6} md={6} lg={2}>
              <Form.Label className="col-form-label">ID</Form.Label>

              <InputGroup>
                <FormControl
                  readOnly
                  type="text"
                  size="sm"
                  placeholder="Item ID"
                  aria-label="Item ID"
                  onChange={(e) => handleChangeValue("id", e.target.value)}
                  value={item && itemId}
                />
                <Button
                  size="sm"
                  title="Copy"
                  onClick={() => copyText(itemId)}
                  variant="outline-secondary"
                >
                  <span role="img" aria-label="Copy">
                    📃
                  </span>
                </Button>
              </InputGroup>
            </Col>
            <Col xs={6} md={6} lg={3}>
              {item ? <ItemType typeId={item.typeId} /> : null}
            </Col>
            <Col xs={12} md={12} lg={7}>
              {item ? (
                isEquipment(item.typeId) ? (
                  <ItemStats
                    bStr={item.bStr}
                    bAgi={item.bAgi}
                    bVit={item.bVit}
                    bInt={item.bInt}
                    bDex={item.bDex}
                    bLuk={item.bDex}
                  />
                ) : null
              ) : null}
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              {item ? <ItemMoveInfo itemMoveInfo={item.itemMoveInfo} /> : null}
            </Col>
          </Row>
        </Col>
        <Col xs={12} sm={4} className="mb-3 mb-sm-0">
          <Form.Label className="col-form-label">Other info</Form.Label>
          <Row>
            <Col xs={12}>
              <Button size="sm" className="me-1 mb-1" variant="outline-dark">
                Indestructible <span>{item.indestructible ? "✔️" : "❌"}</span>
              </Button>
              <Button size="sm" className="me-2 mb-1" variant="outline-dark">
                Refineable <span>{item.refineable ? "✔️" : "❌"}</span>
              </Button>
            </Col>
            <Col xs={12}>
              <Form.Check
                type="switch"
                id="switch-exclude-from-export"
                label="Exclude from export"
                disabled={!userProfile.isAdmin}
                checked={item.excludeFromExport}
                onChange={() =>
                  handleChangeValue(
                    "excludeFromExport",
                    !item.excludeFromExport
                  )
                }
              />
              <Form.Label className="col-form-label">
                Location:{" "}
                <strong>{itemLocationToName(location, typeId)}</strong>
              </Form.Label>
            </Col>
          </Row>
        </Col>
      </Row>

      {/* <Form.Group>
        <Row>
          <Col>
            <Form.Label className="col-form-label">Attribute</Form.Label>
            <Form.Control
              as="select"
              size="sm"
              onChange={(e) =>
                handleChangeValue("attribute", e.target.value)
              }
              value={(item && item.attribute) || 0}
            >
              <option value="0">Neutral</option>
              <option value="1">Water</option>
              <option value="2">Earth</option>
              <option value="3">Fire</option>
              <option value="4">Wind</option>
              <option value="5">Poison</option>
              <option value="6">Holy</option>
              <option value="7">Dark</option>
              <option value="8">Ghost</option>
              <option value="9">Undead</option>
            </Form.Control>
          </Col>
        </Row>
      </Form.Group> */}

      <ItemPreviewContainer itemId={itemId} />
    </Form>
  );
};

export default ItemDetail;
