import React from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";

const ItemMoveInfo = ({ itemMoveInfo }) => {
  const variables = [
    "drop",
    "trade",
    "storage",
    "cart",
    "sell",
    "mail",
    "auction",
    "guildStore",
  ];

  return (
    <>
      <Form.Label className="col-form-label">Move Info</Form.Label>
      <div>
        {variables.map((variable) => {
          if (!itemMoveInfo) {
            return (
              <Button
                key={`ItemMoveInfo-${variable}`}
                size="sm"
                className="me-1 mb-1"
                variant="outline-dark"
              >
                {variable}{" "}
                <span role="img" aria-label={variable}>
                  ✔️
                </span>
              </Button>
            );
          }

          return (
            <Button
              key={`ItemMoveInfo-${variable}`}
              size="sm"
              className="me-1 mb-1"
              variant="outline-dark"
            >
              {variable}{" "}
              <span role="img" aria-label={variable}>
                {itemMoveInfo[variable] ? "✔️" : "❌"}
              </span>
            </Button>
          );
        })}
      </div>
    </>
  );
};

export default ItemMoveInfo;
