import _ from "lodash";

function trimLines(lines) {
  return lines.map((line) => {
    return line.trimLeft().trimRight();
  });
}

const getLines = (text) => {
  return text.split(/\r\n|\r|\n/);
};

const getTextQuoted = (text) => {
  const regex = /"(.*\n?)/gm;
  const m = regex.exec(text);

  if (m) {
    let result = m[0];

    // remove break line
    result = result.replace("\n", "");

    // remove double quote
    result = result.substring(1);
    return result.slice(0, -1);
  }

  return text;
};

const getLineType = (line) => {
  if (line.match(/dialog "\[.*]"/g)) {
    return { type: "dialog_NPC", line };
  } else if (line.match(/dialog ".*?"/g)) {
    return { type: "dialog", line };
  } else if (line.startsWith("broadcastinmap2")) {
    return { type: "broadcast", line };
  } else if (
    line.startsWith("Talk2me") ||
    line.startsWith("MonsterTalkShow") ||
    line.startsWith("TalkShow")
  ) {
    return { type: "dialog_talk", line };
  } else if (
    line.startsWith("npc") ||
    line.startsWith("CashTrader") ||
    line.startsWith("hiddenwarp") ||
    line.startsWith("MD_Hiddenwarp") ||
    line.startsWith("MD_Npc") ||
    line.startsWith("MD_Warp")
  ) {
    return { type: "npc", line };
  } else if (
    line.match(/\/\/.*/g) ||
    line.match(/\/\*.*\*\//g) ||
    line.match(/\/\*.*/g) ||
    line.match(/.*\/*\*\//g)
  ) {
    return { type: "comment", line };
  } else if (
    line.startsWith("OnInit:") ||
    line.startsWith("OnClick:") ||
    line.startsWith("OnTouch:") ||
    line.startsWith("OnTouch2:") ||
    line.startsWith("OnCommand:") ||
    line.startsWith("OnMyMobDead:") ||
    line.startsWith("OnMyMobDead_NPC:") ||
    line.startsWith("OnTimer:") ||
    line.startsWith("InitTimer") ||
    line.startsWith("SuccessRefItem") ||
    line.startsWith("FailedRefItem") ||
    line.startsWith("EventDelSkill") ||
    line.startsWith("EventAddSkill") ||
    line.startsWith("GetNonSlotItemSock2") ||
    line.startsWith("ShowCinematicNpc") ||
    line.startsWith("SetCinematicNpc") ||
    line.startsWith("DelayTime") ||
    line.startsWith("SetGlobalVar") ||
    line.startsWith("ChangeCellType") ||
    line.startsWith("SetMonsterStat") ||
    line.startsWith("Emotion") ||
    line.startsWith("ShowEffect") ||
    line.startsWith("EnableItemMove") ||
    line.startsWith("DisableItemMove") ||
    line.startsWith("Navigation_Active") ||
    line.startsWith("SetQuestConditionBegin") ||
    line.startsWith("SetQuestConditionItem") ||
    line.startsWith("SetQuestConditionQuest") ||
    line.startsWith("SetQuestConditionLevel") ||
    line.startsWith("SetQuestConditionEnd") ||
    line.startsWith("AddQuestIDCondition") ||
    line.startsWith("ConsumeSpecialItem") ||
    line.startsWith("GetHEALTHSTATE") ||
    line.startsWith("SavePPL") ||
    line.startsWith("ChangeSpr") ||
    line.startsWith("Getexp_ratio") ||
    line.startsWith("OpenUI") ||
    line.startsWith("SetMapStr") ||
    line.startsWith("PickToNpc") ||
    line.startsWith("SetMonsterHP2") ||
    line.startsWith("SkillOrder") ||
    line.startsWith("stoptimer") ||
    line.startsWith("resetmymob") ||
    line.startsWith("callmonster") ||
    line.startsWith("compass") ||
    line.startsWith("wait") ||
    line.startsWith("close") ||
    line.startsWith("return") ||
    line.startsWith("if") ||
    line.startsWith("else") ||
    line.startsWith("endif") ||
    line.startsWith("break") ||
    line.startsWith("endchoose") ||
    line.startsWith("dropitem") ||
    line.startsWith("getitem") ||
    line.startsWith("setitem") ||
    line.startsWith("setquest") ||
    line.startsWith("erasequest") ||
    line.startsWith("completequest") ||
    line.startsWith("recall_completequest") ||
    line.startsWith("getgold") ||
    line.startsWith("dropgold") ||
    line.startsWith("dlgwrite") ||
    line.startsWith("getexp") ||
    line.startsWith("getJexp") ||
    line.startsWith("dropequipitem") ||
    line.startsWith("exitwhile") ||
    line.startsWith("endwhile") ||
    line.startsWith("enablenpc") ||
    line.startsWith("disablenpc") ||
    line.startsWith("cmdothernpc") ||
    line.startsWith("cmdothernpc") ||
    line.startsWith("putmob") ||
    line.startsWith("putboss") ||
    line.startsWith("MD_Putmob") ||
    line.startsWith("showimage") ||
    line.startsWith("moveto") ||
    line.startsWith("warp")
  ) {
    return { type: "command", line };
  } else if (line.match(/case\s*[a-zA-Z0-9*]*[0-9]*/g)) {
    return { type: "command", line };
  } else if (line.match(/while\([a-zA-Z]*[0-9]*\)/g)) {
    return { type: "command", line };
  } else if (line.startsWith("var")) {
    return { type: "variable", line };
  } else if (line.match(/[a-zA-Z]*[0-9]*\s*=\s*[a-zA-Z]*[0-9]*/g)) {
    return { type: "variable", line };
  } else if (line.match(/[a-zA-Z]*[0-9]*\s*=\s*".*?"/g)) {
    return { type: "string_to_variable", line };
  } else if (line.match(/choose\s*menu\s".*?"/g)) {
    return { type: "string_menu", line };
  } else if (line.match(/choose\s*menu\s*[a-zA-Z]*[0-9]*/g)) {
    return { type: "variable_menu", line };
  } else if (line.match(/\w*\s=\s\w*/g)) {
    return { type: "command", line };
  }

  return { type: "other", line };
};

const printDialog = (lines) => {
  let result = "";
  _.forEach(lines, ({ type, line }) => {
    if (type !== "dialog") {
      result += `${line}\n`;
    } else {
      result += `dialog "${line}"\n`;
    }
  });
  return result;
};

function dialogJoin(lines) {
  let newLines = [];
  let joinedLines = [];

  // trim
  lines = trimLines(lines);

  // fill out array
  _.forEach(lines, (line) => {
    newLines = [...newLines, getLineType(line)];
  });

  // join multi dialogs to one dialog
  _.forEach(newLines, ({ type, line }) => {
    if (type === "dialog") {
      if (
        typeof joinedLines[joinedLines.length - 1] !== "undefined" &&
        joinedLines[joinedLines.length - 1].type === "dialog"
      ) {
        const string =
          getTextQuoted(joinedLines[joinedLines.length - 1].line) +
          " " +
          getTextQuoted(line);
        joinedLines[joinedLines.length - 1].line = string;
        return;
      }
    }
    joinedLines = [...joinedLines, { type, line }];
  });

  return joinedLines;
}

function dialogSplit(lines, addDot = true) {
  let objects = [];
  let newObjects = [];
  let textItems = [];

  // trim
  lines = trimLines(lines);

  // fill out array
  _.forEach(lines, (line) => {
    objects = [...objects, getLineType(line)];
  });

  _.forEach(objects, ({ type, line }) => {
    if (type === "dialog") {
      let items = [];

      const text = getTextQuoted(line);
      const regex = /[^.!?]+[.!?\w]+/gm;
      let m;

      // Split
      while ((m = regex.exec(text)) !== null) {
        // This is necessary to avoid infinite loops with zero-width matches
        if (m.index === regex.lastIndex) {
          regex.lastIndex++;
        }

        // The result can be accessed through the `m`-variable.
        m.forEach((match) => {
          items.push(match);
        });
      }

      // trim
      textItems = _.map(items, _.trim);

      // add dot to end
      if (addDot) {
        _.forEach(textItems, (text, index) => {
          const lastCharacter = text.charAt(text.length - 1);

          if (
            lastCharacter !== "," &&
            lastCharacter !== "." &&
            lastCharacter !== "?" &&
            lastCharacter !== "!"
          ) {
            textItems[index] = `${text}.`;
          }
        });
      }

      // add to new array
      _.forEach(textItems, (line) => {
        newObjects = [...newObjects, { type, line }];
      });
    } else {
      newObjects = [...newObjects, { type, line }];
    }
  });

  return newObjects;
}

export {
  dialogSplit,
  dialogJoin,
  trimLines,
  getLines,
  printDialog,
  getLineType,
  getTextQuoted,
};
