import React from "react";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { useGeneral } from "../../contexts/generalContext";

const ItemNavigation = ({
  id,
  name,
  isHide,
  setHide,
  list,
  select,
  field = "id",
}) => {
  const { controlView } = useGeneral();

  function getButtonName() {
    let label = id;
    if (typeof name !== "undefined") label += ` - ${name}`;
    return label;
  }

  return (typeof id === "string" && id !== "") ||
    (typeof id === "number" && id !== -1) ? (
    <div className="item-navigation">
      <ButtonGroup size="sm" aria-label="Item Navigation">
        <Button
          onClick={() => controlView("previous", id, list, select, field)}
          variant="outline-secondary"
        >
          ←
        </Button>
        <Button onClick={() => setHide(!isHide)} variant="outline-secondary">
          {getButtonName()}
        </Button>
        <Button
          onClick={() => controlView("next", id, list, select, field)}
          variant="outline-secondary"
        >
          →
        </Button>
      </ButtonGroup>
    </div>
  ) : null;
};

export default ItemNavigation;
