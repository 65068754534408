import React from "react";
import Table from "react-bootstrap/Table";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import ListGroup from "react-bootstrap/ListGroup";
import Badge from "react-bootstrap/Badge";

import {
  numberWithCommas,
  monsterRaceNumToText,
  elementNumToText,
  monsterSizeToText,
} from "../../utils/textUtils";

const MonsterDetail = ({ monster }) => {
  return (
    <div className="monster-info">
      <Row className="g-0 row-mob-info">
        <Col
          md={3}
          className="d-flex flex-column justify-content-center align-items-center position-relative"
        >
          <div className="mob-img mb-3">
            <img
              src={`${process.env.REACT_APP_WP_IMG_URL}monsters/${monster.id}.gif`}
              alt={`${monster.name}`}
            />
          </div>

          <div className="monster-title">
            <div>
              <strong>{monster.name}</strong>
            </div>
            <div>
              <small>{monster.dbName}</small>
            </div>
          </div>

          <Row>
            <Col>
              <span className="label">Level:</span> <code>{monster.level}</code>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="label">HP:</span>{" "}
              <code>{numberWithCommas(monster.hp)}</code>
            </Col>
          </Row>
          <Row>
            <Col>
              <span className="label">Speed:</span> <code>{monster.speed}</code>
            </Col>
          </Row>
        </Col>

        <Col md={9}>
          <ListGroup>
            <ListGroup.Item>
              <Row>
                <Col>
                  <span className="label">ATK:</span>{" "}
                  <code>{monster.atk1}</code>
                </Col>
                <Col>
                  <span className="label">MATK:</span>{" "}
                  <code>{monster.atk2}</code>
                </Col>
              </Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Row>
                <Col>
                  <span className="label">DEF:</span> <code>{monster.def}</code>
                </Col>
                <Col>
                  <span className="label">MDEF:</span>{" "}
                  <code>{monster.mdef}</code>
                </Col>
              </Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Row>
                <Col>
                  <span className="label">Base EXP:</span>{" "}
                  <code>{numberWithCommas(monster.baseExp)}</code>
                </Col>
                <Col>
                  <span className="label">JOB EXP:</span>{" "}
                  <code>{numberWithCommas(monster.jobExp)}</code>
                </Col>
              </Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <Row>
                <Col>
                  <span className="label">Range:</span>{" "}
                  <code>{monster.range}</code>
                </Col>
                <Col>
                  <span className="label">Spell Range:</span>{" "}
                  <code>{monster.spellRange}</code>
                </Col>
              </Row>
            </ListGroup.Item>

            <ListGroup.Item>
              <span className="label">Kích cỡ (Size):</span>{" "}
              <code>{monsterSizeToText(monster.size)}</code>
            </ListGroup.Item>

            <ListGroup.Item>
              <span className="label">Chủng loài (Race):</span>{" "}
              <code>{monsterRaceNumToText(monster.race)}</code>
            </ListGroup.Item>

            <ListGroup.Item>
              <span className="label">Thuộc tính (Element):</span>{" "}
              <code>
                {elementNumToText(monster.element, monster.elementLv)}
              </code>
            </ListGroup.Item>

            <ListGroup.Item className="p-0 m-0">
              <Row className="g-0 row-stats">
                <Col className="col-stat">
                  <span>
                    <code>{monster.mStr}</code>
                  </span>
                  <span>STR</span>
                </Col>

                <Col className="col-stat">
                  <span>
                    <code>{monster.mAgi}</code>
                  </span>
                  <span>AGI</span>
                </Col>

                <Col className="col-stat">
                  <span>
                    <code>{monster.mVit}</code>
                  </span>
                  <span>VIT</span>
                </Col>

                <Col className="col-stat">
                  <span>
                    <code>{monster.mInt}</code>
                  </span>
                  <span>INT</span>
                </Col>

                <Col className="col-stat">
                  <span>
                    <code>{monster.mDex}</code>
                  </span>
                  <span>DEX</span>
                </Col>

                <Col className="col-stat">
                  <span>
                    <code>{monster.mLuk}</code>
                  </span>
                  <span>LUK</span>
                </Col>
              </Row>
            </ListGroup.Item>
          </ListGroup>
        </Col>
      </Row>

      <Row className="g-0 w-table-monster-drops">
        <div className="table-monster-drops-band">Danh sách rơi đồ</div>
        {monster.drops.length || monster.mvpDrops.length ? (
          <Table bordered hover className="mb-0">
            <thead>
              <tr>
                <th style={{ width: "54px" }}>ID</th>
                <th>Tên vật phẩm</th>
                <th style={{ width: "100px" }}>Tỉ lệ rơi</th>
              </tr>
            </thead>
            <tbody>
              {monster.drops.map((drop, index) => {
                return (
                  <tr className="will-show-mob-info" key={index}>
                    <td className="align-middle">{drop.item.id}</td>
                    <td className="align-middle">
                      <a
                        className="item-name"
                        href={`/item/${drop.item.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={`${drop.item.itemIconImage.toLowerCase()}`}
                          alt={`${drop.item.identifiedDisplayName}`}
                        />
                        <span>{drop.item.identifiedDisplayName}</span>
                      </a>
                    </td>
                    <td className="align-middle">{`${drop.chance / 100} %`}</td>
                  </tr>
                );
              })}

              {monster.mvpDrops.map((drop, index) => {
                return (
                  <tr className="will-show-mob-info" key={index}>
                    <td className="align-middle">{drop.item.id}</td>
                    <td className="align-middle">
                      <a
                        className="item-name"
                        href={`/item/${drop.item.id}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <Badge bg="danger">MVP!</Badge>
                        <img
                          src={`${drop.item.itemIconImage.toLowerCase()}`}
                          alt={`${drop.item.identifiedDisplayName}`}
                        />
                        <span>{drop.item.identifiedDisplayName}</span>
                      </a>
                    </td>
                    <td className="align-middle">{`${drop.chance / 100} %`}</td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        ) : (
          <div className="alert alert-warning mb-0">
            Quái vật này không rơi đồ.
          </div>
        )}
      </Row>
    </div>
  );
};

export default MonsterDetail;
