import React from "react";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import Badge from "react-bootstrap/Badge";
import ModalTag from "../common/ModalTag";
import { useGeneral } from "../../contexts/generalContext";

const Tags = ({ tags, modalTagName, handleChangeTags }) => {
  const { showModalTag, generalDispatch } = useGeneral();

  return (
    <div>
      <strong>Tags: </strong>
      {tags.length === 0 ? (
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id={`tooltip-new-tag`}>Click to add tag</Tooltip>}
        >
          <Badge
            className="ro-tag"
            bg="secondary"
            onClick={() =>
              generalDispatch({
                type: "SET_SHOW_MODAL_TAGS",
                payload: true,
              })
            }
          >
            None
          </Badge>
        </OverlayTrigger>
      ) : null}
      {tags.map((tag) => {
        return (
          <Badge
            key={tag.id}
            className="ro-tag me-1"
            bg="primary"
            onClick={() =>
              generalDispatch({ type: "SET_SHOW_MODAL_TAGS", payload: true })
            }
          >
            {tag.name}
          </Badge>
        );
      })}
      <ModalTag
        name={modalTagName}
        tags={tags}
        changeTags={handleChangeTags}
        show={showModalTag}
        onHide={() =>
          generalDispatch({ type: "SET_SHOW_MODAL_TAGS", payload: false })
        }
      />
    </div>
  );
};

export default Tags;
