import React from "react";
import { renderROHTML } from "../../utils/textUtils";
import { SpinnerComponent } from "react-element-spinner";

const ItemBox = ({
  loading,
  identifiedDisplayName,
  itemCollectionImage,
  slots,
  identifiedDescriptionName,
}) => {
  function addSlotToItemName(itemName, numSlots) {
    const slotRegex = /\[(\d+)\]/g;
    const matches = itemName.match(slotRegex);
    if (numSlots < 1 || matches) return itemName;
    return `${itemName} [${numSlots}]`;
  }

  function lowerCaseImgUrl(url) {
    if (url.includes("http")) return url.toLowerCase();
    return url;
  }

  return (
    <div className="ro-item-container ro-item-container-flex">
      <div className="inner">
        <div className="d-flex">
          <div className="ro-item-col-left">
            <img
              src={lowerCaseImgUrl(itemCollectionImage)}
              alt={`${identifiedDisplayName} ingame`}
            />
          </div>

          <div className="ro-item-col-right">
            <em>{addSlotToItemName(identifiedDisplayName, slots)}</em>
            <p>{renderROHTML(identifiedDescriptionName)}</p>
          </div>
        </div>
        <SpinnerComponent
          loading={loading}
          position="centered"
          message="Loading items..."
          backgroundColor="#cccccc"
        ></SpinnerComponent>
        <div className="clearfix"></div>
      </div>
    </div>
  );
};

export default ItemBox;
