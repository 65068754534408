import React from "react";
import axios from "axios";
import _ from "lodash";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Swal from "sweetalert2";
import ScriptList from "./ScriptList";
import ScriptPreview from "./ScriptPreview";
import removeAccents from "../../utils/removeAccents";
import { useUser } from "../../contexts/userContext";
import { useScript } from "../../contexts/scriptContext";

const Script = () => {
  const { userToken, setUserToken } = useUser();
  const {
    scripts,
    setScripts,
    setScriptResults,
    setViewScript,
    scriptLoading,
    setScriptLoading,
    hideScriptList,
    scriptKeywordDebounce,
  } = useScript();

  // Search
  React.useEffect(() => {
    let results = [];

    if (scriptKeywordDebounce !== "") {
      const normalizeKeyword = removeAccents(scriptKeywordDebounce.trim());

      // Search by id
      let scriptSearchByQuestId = [];
      _.forEach(scripts, (script) => {
        const index = script.fileInfo.quests.indexOf(scriptKeywordDebounce);
        if (index !== -1) {
          scriptSearchByQuestId = [...scriptSearchByQuestId, script];
        }
      });

      // Search by name
      const scriptsSearchByName = scripts.filter((script) => {
        const normalizeThisScriptName = removeAccents(
          script.fileName
        ).toLowerCase();
        return normalizeThisScriptName.includes(normalizeKeyword.toLowerCase());
      });

      // Combine results
      _.forEach(scriptsSearchByName, (script) => {
        if (!_.some(results, ["id", script.id])) {
          results = [...results, script];
        }
      });
      _.forEach(scriptSearchByQuestId, (script) => {
        if (!_.some(results, ["id", script.id])) {
          results = [...results, script];
        }
      });
    } else {
      results = [...scripts];
    }

    setScriptResults(results);
  }, [scripts, setScriptResults, scriptKeywordDebounce]);

  // Fetch
  React.useEffect(() => {
    if (scripts.length === 0 && !scriptLoading) {
      setScriptLoading(true);
      setViewScript();

      // List of quests
      axios({
        url: `${process.env.REACT_APP_API}/scripts`,
        method: "GET",
        headers: { "x-auth-token": userToken },
      })
        .then((res) => {
          setScriptLoading(false);
          setScripts(res.data);
          setScriptResults(res.data);
        })
        .catch((error) => {
          if (
            typeof error.response !== "undefined" &&
            error.response.status === 401
          ) {
            Swal.fire({
              icon: "error",
              title: error.response.statusText,
              text: error.response.data,
              confirmButtonText: `Login`,
            }).then(() => {
              setUserToken("");
            });
          } else {
            Swal.fire({
              icon: "error",
              title: error.name,
              text: error.message,
            });
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col
        md={3}
        lg={2}
        className={`col-table-list-item mb-3 mb-md-0 ${
          hideScriptList ? "d-none" : ""
        }`}
      >
        <ScriptList />
      </Col>
      <Col md={hideScriptList ? 12 : 9} lg={hideScriptList ? 12 : 10}>
        <ScriptPreview />
      </Col>
    </Row>
  );
};

export default Script;
